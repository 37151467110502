(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('TaxDetailController', TaxDetailController);

    TaxDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Tax', 'PointOfSale'];

    function TaxDetailController($scope, $rootScope, $stateParams, entity, Tax, PointOfSale) {
        var vm = this;
        vm.tax = entity;
        
        var unsubscribe = $rootScope.$on('wamaApp:taxUpdate', function(event, result) {
            vm.tax = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
