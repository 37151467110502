(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('StockTransferEntryDetailController', StockTransferEntryDetailController);

    StockTransferEntryDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'StockTransferEntry', 'Product', 'StockTransferEntryStatus', 'UnitOfMeasure', 'StockTransfer'];

    function StockTransferEntryDetailController($scope, $rootScope, $stateParams, entity, StockTransferEntry, Product, StockTransferEntryStatus, UnitOfMeasure, StockTransfer) {
        var vm = this;
        vm.stockTransferEntry = entity;
        
        var unsubscribe = $rootScope.$on('wamaApp:stockTransferEntryUpdate', function(event, result) {
            vm.stockTransferEntry = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
