(function () {
    'use strict';

    angular
        .module('wamaApp')
        .controller('PointOfSaleDeleteController', PointOfSaleDeleteController);

    PointOfSaleDeleteController.$inject = ['$uibModalInstance', 'entity', 'PointOfSale'];

    function PointOfSaleDeleteController($uibModalInstance, entity, PointOfSale) {
        var vm = this;
        vm.pointOfSale = entity;
        vm.preConfirm = preConfirm;

        vm.preconfirm = false;

        function preConfirm() {
            vm.preconfirm = true;
        }

        vm.clear = function () {
            $uibModalInstance.dismiss('cancel');
        };
        vm.deleting = false;
        vm.confirmDelete = function (id) {
            vm.deleting = true;
            PointOfSale.delete({id: id}
                , function () {
                    $uibModalInstance.close(true);
                    vm.deleting = false;
                })
                , function (error) {
                vm.deleting = false;
            }
        };
    }
})();
