(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('SaleOrderDialogController', SaleOrderDialogController);

    SaleOrderDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'SaleOrder'];

    function SaleOrderDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, SaleOrder) {
        var vm = this;
        vm.saleOrder = entity;
        vm.state = $stateParams.status;
        var onSaveSuccess = function (result) {
            $scope.$emit('wamaApp:saleOrderUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.changeStatus = function () {
            vm.isSaving = true;
            if(vm.saleOrder.id !== null){
                var orderStatus = {code: vm.state};
                vm.saleOrder.orderStatus=orderStatus;
                SaleOrder.update(vm.saleOrder, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dateIssue = false;
        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.lastModifiedDate = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
    }
})();
