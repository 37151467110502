(function() {
    'use strict';

    angular
        .module('wamaApp')
        .factory('StockTransferStatusSearch', StockTransferStatusSearch);

    StockTransferStatusSearch.$inject = ['$resource'];

    function StockTransferStatusSearch($resource) {
        var resourceUrl =  'api/_search/stock-transfer-statuses/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
