(function() {
    'use strict';
    angular
        .module('wamaApp')
        .factory('ShopCategory', ShopCategory);

    ShopCategory.$inject = ['$resource', '$filter'];

    function ShopCategory ($resource) {
        var resourceUrl =  'api/shop/:pointOfSaleId/categories';

        return $resource(resourceUrl, {includeRelations: true}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'head': {method:'HEAD'}
        });
    };
})();
