(function () {
    'use strict';

    angular
        .module('wamaApp')
        .config(integrations);

    integrations.$inject = ['$stateProvider'];

    function integrations($stateProvider) {
        $stateProvider.state('integrations', {
            parent: 'account',
            url: '/integrations',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'settings.integrations.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/settings/settings.integrations.html',
                    controller: 'IntegrationsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('settings');
                    $translatePartialLoader.addPart('preferences');
                    $translatePartialLoader.addPart('shopifyShop');
                    $translatePartialLoader.addPart('pointOfSale');
                    return $translate.refresh();
                }]
            }
        }),
        $stateProvider.state('integration-shopify-settings', {
            parent: 'integrations',
            url: '/integration-shopify-settings?shopifyShop&authorizationCode',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'settings.modules.shopify.settings.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/settings/settings.integrations-shopify.settings.html',
                    controller: 'IntegrationShopifyController',
                    controllerAs: 'vm'
                }
            },
            params: {
                shop: null,
                code: null
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('settings');
                    $translatePartialLoader.addPart('preferences');
                    $translatePartialLoader.addPart('shopifyShop');
                    $translatePartialLoader.addPart('pointOfSale');
                    return $translate.refresh();
                }]
            }
        }),
        $stateProvider.state('integration-shopify-settings.sync', {
            parent: 'integration-shopify-settings',
            url: '/integration-shopify-settings/sync?shop&pointOfSaleId',
            data: {
                authorities: ['ROLE_USER'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/shopify-shop/shopify-shop-sync-dialog.html',
                    controller: 'ShopifyShopSyncDialogController',
                    controllerAs: 'vm',
                    size: 'md',
                }).result.then(function () {
                    $state.go('integration-shopify-settings', null, {reload: true});
                }, function () {
                    $state.go('^');
                });
            }]
        }),
        $stateProvider.state('integration-shopify-settings.delete', {
            parent: 'integration-shopify-settings',
            url: '/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/shopify-shop/shopify-shop-delete-dialog.html',
                    controller: 'ShopifyShopDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                }).result.then(function () {
                    $state.go('integration-shopify-settings', null, {reload: true});
                }, function () {
                    $state.go('^');
                });
            }]
        });
    }
})();
