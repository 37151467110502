(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('PosCheckoutDialogController', PosCheckoutDialogController);

    PosCheckoutDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'Customer', 'SaleOrder',
        '$http', '$filter', 'SaleOrderInvoice', 'ToastAlertService', '$translate', 'OrderCalculation', 'CustomerSearch'];

    function PosCheckoutDialogController ($timeout, $scope, $stateParams, $uibModalInstance, Customer, SaleOrder, $http,
                                          $filter, SaleOrderInvoice, ToastAlertService, $translate, OrderCalculation, CustomerSearch) {
        var vm = this;
        vm.saleOrder = $stateParams.order;
        vm.paymentMethod = "CASH";
        vm.emailSent = false;
        vm.isSendingEmail = false;
        vm.orderSuccess = false;
        vm.searchCustomersData = [];
        vm.searchedCustomer = false;


        vm.totalOriginal = 0;
        if(vm.saleOrder != null) {
            vm.totalOriginal = parseFloat(angular.copy(vm.saleOrder.total));
            // need to copy the orderTotal because if using the saleOrder.total causes angular error angular.js:68 Uncaught Error: [$rootScope:infdig] 10 $digest() iterations reached. Aborting!
            // when calculating the total and apply the max limit to the discount field
        }

        var onSaveSuccess = function (order) {
            vm.orderSuccess = true;
            vm.saleOrder = order;
            vm.isSaving = false;

            if(vm.saleOrder.customer!=null && vm.saleOrder.customer.addresses!=null && vm.saleOrder.customer.addresses.size > 0){
                vm.email = vm.saleOrder.customer.addresses[0].email;
            }

            $scope.$emit('wamaApp:orderPlaced', null);
        };

        var onSaveError = function () {
            vm.isSaving = false;
            vm.isSendingEmail = false;
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        if(vm.saleOrder == null){
            vm.clear();
            $uibModalInstance.dismiss('cancel');
        }

        Customer.query({
            page: 0,
            size: 2000,
            sort: "name,Asc"
        }, function (pageOne, headers) {
            var totalItems = headers('X-Total-Count');
            if (totalItems > 2000) {
                Customer.query({
                    page: 1,
                    size: 2000,
                    sort: "name,Asc"
                }, function (pageTwo) {
                    vm.customers = angular.extend(pageOne, pageTwo);
                });
            } else {
                vm.customers = pageOne;
            }

        });

        // ********* PLACE ORDER **************
        vm.save = function (status) {
            vm.isSaving = true;
            vm.saleOrder.orderStatus = getOrderStatus(status);
            vm.saleOrder.paymentMethod = {code: vm.paymentMethod};
            vm.saleOrder.origin = "WEB";
            if( vm.saleOrder.discount !=null && vm.saleOrder.discount >= 0){
                if(vm.saleOrder.discountPercentage){
                    //in case of discountPercentage the value must be saved in discountPercentageValue, otherwise in discount
                    vm.saleOrder.discountPercentageValue = vm.saleOrder.discount;
                    vm.saleOrder.discount = null;
                }
            }

            if (vm.saleOrder.id == null) {
                resetNegativeIdOnManualEntries();
                SaleOrder.save({skipCustomerPriceList: vm.skipCustomerPriceList}, vm.saleOrder, onSaveSuccess, onSaveError);
            } else {
                SaleOrder.update({skipCustomerPriceList: vm.skipCustomerPriceList}, vm.saleOrder, onSaveSuccess, onSaveError);
            }
        };

        function resetNegativeIdOnManualEntries() {
            angular.forEach(vm.saleOrder.orderEntries, function (entry, index) {
                if (entry.product.id < 0) {
                    vm.saleOrder.orderEntries[index].product.id = null;
                }
            });
        }

        function getOrderStatus(status) {
            if(status!=null && status === 'DRAFT'){
                return {code: 'DRAFT'};
            }
            return {code: (vm.paymentMethod == "BANK_TRANSFER") ? 'PENDING' : 'COMPLETED'};
        }

        //********* CREATE CUSTOMER ***************
        vm.saveNewCustomer = function(){
            vm.isSaving = true;
            if(vm.customer == null || (vm.customer.name == null && vm.customer.surname == null)){
                return;
            }
            if(vm.customer.name==null){
                vm.customer.name=""
            }
            if(vm.customer.surname==null){
                vm.customer.surname=""
            }
            Customer.save(vm.customer, onCustomerSuccess, onSaveError);
            function onCustomerSuccess(customer) {
                vm.isSaving = false;
                vm.createNewCustomer = false;
                if(vm.customers == null) {
                    vm.customers = Array();
                }
                vm.customers.unshift(customer);
                vm.saleOrder.customer = customer;
            }
        };

        //********* SEARCH CUSTOMER ***************
        vm.searchCustomers = function(searchQuery) {
            CustomerSearch.query({
                query: searchQuery,
                sort: "name,asc",
                size: 40
            }, function (data) {
                vm.searchCustomersData = data;
            }, function (error) {
                ToastAlertService.error(error.data.message);
            });
        }
        vm.selectCustomer = function(customer){
            vm.saleOrder.customer = customer;
            vm.searchedCustomer = true;
            vm.searchCustomerBox = false;
        }

        vm.getCustomerName = function(customer){
            if(customer== null){
                return ;
            }
            var fullName = customer.name + " " + customer.surname;
            if(customer.priceList != null){
                fullName = fullName + " [" + customer.priceList.name + "]";
            }
            return fullName;
        };

        vm.recalculateOrder = function(){
            if( vm.saleOrder.discount == null || typeof vm.saleOrder.discount === "undefined"){
                vm.saleOrder.discount = 0;
            }
            if( vm.saleOrder.discount >= 0){
                if(vm.saleOrder.discountPercentage){
                    //in case of discountPercentage the value must be saved in discountPercentageValue, otherwise in discount
                    vm.saleOrder.discountPercentageValue = vm.saleOrder.discount;
                    vm.saleOrder.discount = null;
                }
            }
            OrderCalculation.calculateOrderBackEnd(vm.saleOrder, vm.skipCustomerPriceList, function(order){
                vm.saleOrder = order;
            }, function (error) {
                if(!error.data.message.includes('error.loyaltyCard.')) {
                    ToastAlertService.show({type: ToastAlertService.error, msg: 'wamaApp.saleOrder.error.calculation'});
                }
            });
        };

        vm.removeLoyaltyCard = function () {
            vm.saleOrder.loyaltyCard = null;
            vm.saleOrder.loyaltyCardNumber = null;
            vm.recalculateOrder();
        }

        //******* PDF DOWNLOAD ************

        vm.downloadSaleOrderPDF = downloadSaleOrderPDF;

        function downloadSaleOrderPDF () {
            $http.get('/api/sale-orders/'+ vm.saleOrder.id +'/pdf', {responseType: 'arraybuffer'})
                .success(function (data) {
                    var blob = new Blob([data], {
                        type: 'application/pdf'
                    });
                    var id = vm.saleOrder.id;
                    if(vm.saleOrder.code!=null){
                        id = vm.saleOrder.code;
                    }
                    var currentDate = $filter('date')(new Date(), "yyyy-MM-dd_HH:mm:ss");
                    var filename = "WAMA_Order_" + id + "-"  + currentDate + ".pdf";
                    saveAs(blob, filename);
                    vm.isSaving = false;
                }).error(function () {
                vm.isSaving = false;
            });
        }

        vm.sendEmail = function(){
            if(vm.emailSent) {
                if(confirm($translate.instant('wamaApp.pos.messages.resendEmail'))){
                    vm.isSendingEmail = true;
                    SaleOrderInvoice.save({id: vm.saleOrder.id, email: vm.email, mode: "pdf"}, null, onEmailSentSuccess, onSaveError);
                }
            } else {
                vm.isSendingEmail = true;
                SaleOrderInvoice.save({id: vm.saleOrder.id, email: vm.email, mode: "pdf"}, null, onEmailSentSuccess, onSaveError);
            }
        };

        var onEmailSentSuccess = function () {
            vm.emailSent = true;
            vm.isSendingEmail = false;
            $uibModalInstance.close();
        };


        vm.currencyHelpers = currencyHelpers;

    }
})();
