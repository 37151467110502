(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', '$translate', 'Principal', 'LoginService', '$state', 'PointOfSale', 'PointOfSaleStatistics', '$timeout', '$uibModal', 'Country', 'Company',
        'Auth', '$rootScope', 'DateUtils', 'CompanyPreferences'];

    function HomeController ($scope, $translate, Principal, LoginService, $state, PointOfSale, PointOfSaleStatistics, $timeout, $uibModal, Country, Company, Auth, $rootScope, DateUtils, CompanyPreferences) {
        var vm = this;
        vm.pointOfSale = PointOfSale.get({id: 0});

        vm.companyPreferences = CompanyPreferences.getPreferencesFromLocalStorage();

        $scope.$on('$viewContentLoaded', function() {
            vm.redirectToRoleHome($rootScope.AUTHORITIES);
        });

        vm.redirectToRoleHome = function(authorities){
            if(authorities!=null && authorities.indexOf("ROLE_SALES_OPERATOR") >=0){
                $state.go("pos");
            }
            if(authorities!=null && authorities.indexOf("ROLE_OPERATOR") >=0){
                $state.go("product");
            }
        };


        vm.countryModal = function () {
            var modalInstance = $uibModal.open({
                templateUrl: 'app/home/country-welcome-dialog.html',
                controller: function() {
                    var pointOfSale = PointOfSale.get({id: 0});
                    var vm = this;
                    vm.countries = Country.query(null, onCountriesLoaded, null);

                    //detect browser language and preselect country
                    function onCountriesLoaded(countries){
                        var language = window.navigator.language;
                        if(language) {
                            if (language.toLowerCase() == "en-us") {
                                language = "US";
                            } else if (language.toLowerCase() == "en-gb") {
                                language = "GB"
                            } else if (language.toLowerCase() == "en") {
                                language = "GB"
                            } else {
                                language = language.split("-")[0];
                            }
                            angular.forEach(countries, function(country){
                                if(country.code.toUpperCase() == language.toUpperCase()){
                                    vm.country = country;
                                    return;
                                }
                            });
                        }
                    }

                    vm.saveCountry = function () {
                        vm.isSaving=true;
                        if(pointOfSale.address!=null){
                            pointOfSale.address.country=vm.country;
                        }else{
                            var address = {"country": vm.country};
                            pointOfSale.address = address;
                        }
                        PointOfSale.update(pointOfSale, onSaveSuccess, onSaveError);
                    };
                    var onSaveSuccess = function (result) {
                        //setting and saving company country
                        result.company.address.country = result.address.country;
                        Company.update(result.company);

                        $scope.$emit('wamaApp:companyUpdate', result);
                        modalInstance.close(result);
                        vm.isSaving = false;
                    };

                    var onSaveError = function () {
                        vm.isSaving = false;
                    };

                },
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: 'false',
                size: 'lg',
                resolve: {
                    items: function () {
                        return {
                            line1: null,
                            line2: null,
                            line3: null,
                            taxRegistrationNumber: null,
                            phone1: null,
                            phone2: null,
                            email: null,
                            city: null,
                            postcode: null,
                            longitude: null,
                            latitude: null,
                            defaultAddress: null,
                            deliveryAddress: null,
                            paymentAddress: null,
                            historical: null,
                            id: null
                        };
                    }
                }
            });
        };


        $scope.$watch('vm.pointOfSale.id', function() {
            if(null != vm.pointOfSale.id){
                if(vm.pointOfSale.address!=null){
                    if(vm.pointOfSale.address.country==null){
                        vm.countryModal();
                    }
                }else{
                    vm.countryModal();
                }

            }

        });

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                vm.loadInfo();
            });
        }
        function register () {
            $state.go('register');
        }

        vm.loadInfo = loadInfo;

        function loadInfo(){
            //PointOfSale.head({startDate: vm.startDate, endDate: vm.endDate}, onSuccessAggregateStatistics);
            vm.getStatisticsLastSevenDays();

            setupMinMaxDate();

            getProductQuantityInfo();
        };


        function onSuccessStatistics(data, headers) {
            vm.fetchingData = false;
            var stockIn = [];
            var stockOut = [];
            var productsIn = [];
            var productsOut = [];
            var salesOrdersPrice = [];
            var salesOrdersNumber = [];
            vm.sellingPrice = decodeURIComponent(headers('X-Selling-Price')).replace('null', '').split(':');
            vm.purchasePrice = decodeURIComponent(headers('X-Purchase-Price')).replace('null', '').split(':');
            vm.stockEntriesInQty = headers('X-Total-Stock-In-Qty');
            vm.stockEntriesOutQty = headers('X-Total-Stock-Out-Qty');

            vm.totalPrices = decodeURIComponent(headers('X-Total-Price')).replace('null', '').split(':');
            vm.totalItemsQuantity = headers('X-Total-Items');
            vm.totalItems = headers('X-Total-Count');
            vm.totalOOS = headers('X-OOS-Count');

            var lastStockChangeMessage = headers('X-Last-Stock-Change-Message');
            var lastStockChangeTime = headers('X-Last-Stock-Change');
            if (angular.isString(lastStockChangeMessage) && angular.isString(lastStockChangeTime) && lastStockChangeTime!="") {
                vm.lastStockChange = $translate.instant(lastStockChangeMessage, {param: lastStockChangeTime});
            } else{
                vm.lastStockChange = "-";
            }

            $scope.fetching=false;

            angular.forEach(data["stockEntriesTrendIn"], function(value, key){
                var entry = {
                    "label": new Date(key),
                    "value": value
                };
                stockIn.push(entry);
            });

            angular.forEach(data["stockEntriesTrendOut"], function(value, key){
                var entry = {
                    "label": new Date(key),
                    "value": value
                };
                stockOut.push(entry);
            });
            angular.forEach(data["productsIn"], function(value, key){
                var entry = {
                    "label": key,
                    "value": value
                };
                productsIn.push(entry);
            });
            angular.forEach(data["productsOut"], function(value, key){
                var entry = {
                    "label": key,
                    "value": Math.abs(value)
                };
                productsOut.push(entry);
            });
            vm.productsInData = productsIn;

            vm.productsOutData = productsOut;

            vm.stockInData = [{
                key: "Stock In",
                values: stockIn
            }];

            vm.stockOutData = [{
                key: "Stock Out",
                values: stockOut
            }];

            angular.forEach(data["salesOrdersCountGraph"], function(value, key){
                var entry = {
                    "label": new Date(key),
                    "value": value
                };
                salesOrdersNumber.push(entry);
            });
            angular.forEach(data["salesOrdersPriceGraph"], function(value, key){
                var entry = {
                    "label": new Date(key),
                    "value": value
                };
                salesOrdersPrice.push(entry);
            });

            vm.salesOrdersTotalPrice = decodeURIComponent(headers('X-Sales-Orders-Total-Price')).replace('null', '').split(':');
            vm.salesOrdersAvgPrice = decodeURIComponent(headers('X-Sales-Orders-Avg-Price')).replace('null', '').split(':');
            vm.salesOrdersTotalCount = headers('X-Sales-Orders-Total-Count');
            vm.salesOrdersAvgCount = headers('X-Sales-Orders-Avg-Count');

            vm.salesOrdersNumber = [{
                key: "Sales Orders number",
                values: salesOrdersNumber
            }];
            vm.salesOrdersPrice = [{
                key: "Sales Orders total price",
                values: salesOrdersPrice
            }];
            vm.salesOrdersUserStats = data["salesOrdersEmployeesStats"].val;


            var productExpiryDates = data["productsExpiryDates"];
            if(productExpiryDates!=null){
                vm.expiryDates = productExpiryDates["values"];
            }

        }
        function onErrorStatistics(error) {
            vm.fetchingData = false;
        }

        vm.now = new Date().toISOString();

        vm.diffDaysToToday = function(firstDate){
            var first = DateUtils.convertDateTimeFromServer(firstDate);
            var oneDay = 24*60*60*1000; // hours*minutes*seconds*milliseconds
            return Math.round(Math.abs((first.getTime() - new Date().getTime())/(oneDay)));
        };

        $scope.$watch('fetching', function() {
            if(!$scope.fetching) {
                $timeout(function() {
                    window.dispatchEvent(new Event('resize'));
                }, 500);
            }
        });
        vm.options = {
            chart: {
                type: 'discreteBarChart',
                height: 450,
                margin : {
                    top: 20,
                    right: 20,
                    bottom: 50,
                    left: 55
                },
                x: function(d){return d.label;},
                y: function(d){return d.value + (1e-10);},
                showValues: false,
                valueFormat: function(d){
                    return d3.format(',.0f')(d);
                },
                duration: 500,
                xAxis: {
                    //axisLabel: 'X Axis'
                    rotateLabels: -60,
                    tickFormat: function(d) {
                        return d3.time.format('%e %b')(new Date(d));
                    }
                },
                yAxis: {
                    //axisLabel: 'Y Axis',
                    axisLabelDistance: -10
                },
                noData: $translate.instant('home.analytics.noData')
            }
        };

        vm.lineChartOptions = {
            chart: {
                type: 'lineChart',
                height: 450,
                margin : {
                    top: 20,
                    right: 20,
                    bottom: 50,
                    left: 55
                },
                x: function(d){return d.x;},
                y: function(d){return d.y;},
                useInteractiveGuideline: true,
                valueFormat: function(d){
                    return d3.format(',.0f')(d);
                },
                duration: 500,
                xAxis: {
                    //axisLabel: 'X Axis'
                    tickFormat: function(d) {
                        return d3.time.format('%B %e')(new Date(d));
                    }
                },
                yAxis: {
                    //axisLabel: 'Y Axis',
                    axisLabelDistance: -10
                },
                showLegend: false,
                noData: $translate.instant('home.analytics.noData')
            }
        };


        function getProductQuantityInfo(){
            PointOfSaleStatistics.get({query: "products"}, onSuccess, onError);

            function onSuccess(data) {
                var productQuantities = [{"key": "Low stock products",
                    "color": "#d62728",
                    "values": [
                    ]
                }];

                angular.forEach(data["productLowStock"], function(value, key){
                    var entry = {
                        "label": key,
                        "value": value
                    };
                    productQuantities[0]['values'].push(entry);
                });

                $scope.fetching=false;

                vm.pieChartDataProductQuantities =  productQuantities;


                vm.multiBarOptions = {
                    chart: {
                        type: 'multiBarHorizontalChart',
                        height: 500,
                        margin : {
                            left: 210
                        },
                        x: function(d){return d.label;},
                        y: function(d){return d.value;},
                        //yErr: function(d){ return [-Math.abs(d.value * Math.random() * 0.3), Math.abs(d.value * Math.random() * 0.3)] },
                        showControls: false,
                        showValues: true,
                        showLegend: false,
                        duration: 500,
                        xAxis: {
                            showMaxMin: false
                        },
                        yAxis: {
                            axisLabel: $translate.instant('home.analytics.productLowStock.quantity'),
                            tickFormat: function(d){
                                return d3.format('.1')(d);
                            }
                        },
                        tooltip: {
                            contentGenerator: function(d) {
                                return '<div style="margin:5px">'+$translate.instant('home.analytics.productLowStock.quantity')+': <b>'+d.data.value+'</b></div>';       // return d.data.label to show the product name
                            }
                        },
                        noData: $translate.instant('home.analytics.noData')
                    }
                };



                vm.pieChartOptions = {
                    chart: {
                        type: 'pieChart',
                        height: 500,
                        margin : {
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0
                        },
                        x: function(d){
                            return d.label;},
                        y: function(d){return d.value;},
                        showLabels: true,
                        duration: 500,
                        labelThreshold: 0.01,
                        labelSunbeamLayout: true,
                        showLegend: false,
                        noData: $translate.instant('home.analytics.noData')
                    }
                };


            }
            function onError(error) {
            }
        }



        // LOGIN CONTROLLER FUNCTIONS
        var vm = this;

        vm.authenticationError = false;
        vm.authenticationErrorMaxAttempts = false;
        vm.credentials = {};
        vm.login = login;
        vm.password = null;
        vm.register = register;
        vm.rememberMe = true;
        vm.requestResetPassword = requestResetPassword;
        vm.username = null;

        $timeout(function (){angular.element('#username').focus();});

        function login (event) {
            event.preventDefault();
            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe
            }).then(function () {
                vm.authenticationError = false;
                vm.authenticationErrorMaxAttempts = false;
                if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                    $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                    $state.go('home');
                }

                //this is to display the user name in the top right of the navbar
                Principal.identity().then(function(account) {
                    if(account){
                        $rootScope.USERNAME = account.login;
                        $rootScope.FIRSTNAME = account.firstName;
                        $rootScope.LASTNAME = account.lastName;
                        $rootScope.PHOTO = account.photo;
                        $rootScope.AUTHORITIES = account.authorities;

                        //ROLE_OPERATOR DOESN'T HAVE ACCESS TO THE DASHBOARD PAGE
                        vm.redirectToRoleHome(account.authorities);
                    }

                });
                //end

                $rootScope.$broadcast('authenticationSuccess');

                // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                // since login is succesful, go to stored previousState and clear previousState
                if (Auth.getPreviousState()) {
                    var previousState = Auth.getPreviousState();
                    Auth.resetPreviousState();
                    $state.go(previousState.name, previousState.params);
                }
            }).catch(function (error) {
                if(error.data && error.data.error_description=='wamaApp.login.error.maxAttempts'){
                    vm.authenticationErrorMaxAttempts = true;
                    vm.authenticationError = false;
                } else {
                    vm.authenticationError = true;
                    vm.authenticationErrorMaxAttempts = false;
                }
            });
        }

        function register () {
            $state.go('register');
        }

        function requestResetPassword () {
            $state.go('requestReset');
        }


        vm.validateStartDate = function () {
            if(vm.startDate > vm.endDate){
                vm.endDate = vm.startDate;
            }
            vm.dayDiff(vm.startDate,vm.endDate);
            setupMinMaxDate();
        };
        vm.validateEndDate = function () {
            if(vm.endDate < vm.startDate){
                vm.startDate = vm.endDate;
            }
            vm.dayDiff(vm.startDate,vm.endDate);
            setupMinMaxDate();
        };

        vm.dayDiff = function(firstDate,secondDate){
            var timeDiff = Math.abs(secondDate.getTime() - firstDate.getTime());
            vm.dayDifference = Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1;
        };

        function setupMinMaxDate(){
            var minDate = new Date();
            minDate.setMonth(minDate.getMonth() - 6);
            vm.startDate.min = minDate;
            vm.startDate.max = new Date();
            vm.endDate.max = new Date();
            vm.dayDiff(vm.startDate,vm.endDate);
        }

        vm.todayDate = new Date();


        vm.getStatisticsToday = function () {
            vm.startDate = new Date();
            vm.endDate = new Date();
            vm.dayDiff(vm.startDate,vm.endDate);
            vm.getStatisticsForDateRange(vm.startDate, vm.endDate, 'today');
        };
        vm.getStatisticsYesterday = function () {
            var startDate =  new Date();
            startDate.setDate(startDate.getDate() - 1);
            vm.startDate = startDate;
            vm.endDate = startDate;
            vm.dayDiff(vm.startDate,vm.endDate);
            vm.getStatisticsForDateRange(vm.startDate, vm.endDate, 'yesterday');

        };
        vm.getStatisticsLastSevenDays = function () {
            var startDate =  new Date();
            startDate.setDate(startDate.getDate() - 6);
            vm.startDate = startDate;
            vm.endDate = new Date();
            vm.dayDiff(vm.startDate,vm.endDate);
            vm.getStatisticsForDateRange(vm.startDate, vm.endDate, 'sevendays');
        };

        vm.getStatisticsForDateRange = function (startDate, endDate, pressedButton) {
            vm.fetchingData=true;
            PointOfSaleStatistics.get({query: "stock-entries", startDate: startDate, endDate: endDate}, onSuccessStatistics, onErrorStatistics);
            vm.buttonStatsPeriod = pressedButton;
            setupMinMaxDate();
        }
    }
})();
