(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('UserManagementController', UserManagementController);

    UserManagementController.$inject = ['Principal', 'UserAdmin', 'ParseLinks', 'paginationConstants', 'JhiLanguageService', 'UserSearch', 'AlertService'];

    function UserManagementController(Principal, UserAdmin, ParseLinks, paginationConstants, JhiLanguageService, UserSearch, AlertService) {
        var vm = this;

        vm.authorities = ['ROLE_USER', 'ROLE_ADMIN'];
        vm.clear = clear;
        vm.currentAccount = null;
        vm.languages = null;
        vm.links = null;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.page = 1;
        vm.setActive = setActive;
        vm.totalItems = null;
        vm.users = [];
        vm.search = search;
        vm.clear = loadAll;


        vm.loadAll();


        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });

        Principal.identity().then(function(account) {
            vm.currentAccount = account;
        });


        function loadAll () {
            UserAdmin.query({page: vm.page - 1, size: paginationConstants.itemsPerPage}, function (result, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');

                //hide anonymous user from user management: it's a required user for Spring Security
                for(var i in result) {
                    if(result[i]['login'] === 'anonymoususer') {
                        result.splice(i,1);
                    }
                }
                vm.users = result;
            });
        }

        function loadPage (page) {
            vm.page = page;
            vm.loadAll();
        }

        function setActive (user, isActivated) {
            user.activated = isActivated;
            UserAdmin.update(user, function () {
                vm.loadAll();
                vm.clear();
            });
        }

        function clear () {
            vm.user = {
                id: null, login: null, firstName: null, lastName: null, email: null,
                activated: null, langKey: null, createdBy: null, createdDate: null,
                lastModifiedBy: null, lastModifiedDate: null, resetDate: null,
                resetKey: null, authorities: null
            };
            vm.editForm.$setPristine();
            vm.editForm.$setUntouched();
        }


        // SEARCH
        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            UserSearch.query({
                query: searchQuery,
                page: 0,
                size: paginationConstants.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
        }
        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }
        function onSuccess(data, headers) {
            // vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.users = data;
        }
        function onError(error) {
            AlertService.error(error.data.message);
        }
    }
})();
