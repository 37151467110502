(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('ProductDetailLocationDeleteController',ProductDetailLocationDeleteController);

    ProductDetailLocationDeleteController.$inject = ['$uibModalInstance', 'entity', 'Location', '$stateParams'];

    function ProductDetailLocationDeleteController($uibModalInstance, entity, Location, $stateParams) {
        var vm = this;
        vm.location = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function () {
            removeByAttr(vm.location.products, 'id', $stateParams.id);
            Location.update(vm.location, function () {
                $uibModalInstance.close(true);
                localStorage.setItem("selectedIndex", 2);
            });
        };
    }

    function removeByAttr(arr, attr, value){
        var i = arr.length;
        while(i--){
            if( arr[i] && arr[i].hasOwnProperty(attr) && (arguments.length > 2 && arr[i][attr] == value ) ){
                arr.splice(i,1);
            }
        }
        return arr;
    }

})();
