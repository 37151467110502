(function() {
    'use strict';

    angular
        .module('wamaApp')
        .factory('SaleOrderSearch', SaleOrderSearch);

    SaleOrderSearch.$inject = ['$resource'];

    function SaleOrderSearch($resource) {
        var resourceUrl =  'api/_search/sale-orders/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
