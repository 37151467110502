/**
 * Created by roberto on 10/24/16.
 */
(function() {
    'use strict';

    angular
        .module('wamaApp')
        .directive('selectPickerWatcher', function ($timeout) {
            return {

                // link: function($scope, element, attr) {
                //     $scope.$watch('vm.selectDataComplete', function() {
                //         // all the code here...
                //         element.selectpicker();
                //         console.log("refresh");
                //     }, true);
                // }
                scope: {
                    dataInfo: '=dataloaded'
                },
                link:
                    function ($scope, element, attr) {
                    $timeout(function () {
                        // if(attr.last){
                        $scope.$watch('$scope.dataInfo', function() {
                            console.log("refresh: " + $scope.dataInfo);
                            element.selectpicker();
                        });
                        // }else{
                        //     console.log("no refresh");
                        // }
                    });
                }
            };
        });
})();
