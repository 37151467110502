(function () {
    'use strict';

    angular
        .module('wamaApp')
        .controller('ShopController', ShopController);

    ShopController.$inject = ['$scope', '$stateParams', '$translate', 'Principal', 'LoginService', '$state', 'Shop', 'ShopSearch',
        'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'Preferences', 'ShopSettings', 'ShopCategory'];

    function ShopController($scope, $stateParams, $translate, Principal, LoginService, $state, Shop, ShopSearch, ParseLinks,
                            AlertService, pagingParams, paginationConstants, Preferences, ShopSettings, ShopCategory) {
        var vm = this;
        vm.loadAll = loadAll;
        vm.search = search;
        vm.loadAllCategories = loadAllCategories;
        vm.loadByCategory = loadByCategory;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.clear = clear;
        vm.search = search;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.currencyHelpers = currencyHelpers;
        vm.loadAll();
        vm.loadAllCategories();
        vm.showCategoriesBar = false;
        vm.showSearchForm = false;
        vm.isDataReady = false;
        vm.pointOfSaleId = $stateParams.pointOfSaleId;
        loadSettings();

        function loadAll() {
            if (pagingParams.search) {
                ShopSearch.query({
                    pointOfSaleId: $stateParams.pointOfSaleId,
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: paginationConstants.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                var selectedCategory = getSelectedCategory();
                Shop.query({
                    pointOfSaleId: $stateParams.pointOfSaleId,
                    categoryId: selectedCategory != null ? selectedCategory.id : null,
                    page: pagingParams.page - 1,
                    size: paginationConstants.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
        }

        function onSuccess(data, headers) {
            vm.isDataReady = true;
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.products = data;
            vm.page = pagingParams.page;
        }

        function onError(error) {
            vm.isDataReady = false;
            clearSelectedCategory();
            AlertService.error(error.data.message);
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                pointOfSaleId: $stateParams.pointOfSaleId,
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            clearSelectedCategory()
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        //method to load next data
        vm.loadMore = function () {
            vm.page++;
            vm.loadingMore = true;
            loadPage(page);
        };


        function loadSettings() {
            ShopSettings.get({id: $stateParams.pointOfSaleId}, function (result) {
                vm.shopSettings = result;
            });
        }

        function loadAllCategories() {
            ShopCategory.query({
                pointOfSaleId: $stateParams.pointOfSaleId,
                page: 0,
                size: 50,
                sort: 'name,ASC'
            }, function (data) {
                vm.categories = data;
            }, function (error) {
                AlertService.error(error.data.message);
            });
        }

        function loadByCategory(category) {
            var selectedCategory = getSelectedCategory();
            if (selectedCategory != null && category.id === selectedCategory.id) {
                category = null;
            }
            setSelectedCategory(category);
            loadAll();
        }

        function clearSelectedCategory(){
            localStorage.setItem('public-shop-category', null);
            vm.selectedCategory = null;
        }

        function setSelectedCategory(category){
            vm.selectedCategory = category;
            localStorage.setItem('public-shop-category', JSON.stringify(category));
        }

        function getSelectedCategory() {
            var selectedCategory = JSON.parse(localStorage.getItem('public-shop-category'));
            vm.selectedCategory = selectedCategory;
            return selectedCategory;
        }
    }
})();
