(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('SettingsController', SettingsController);

    SettingsController.$inject = ['Principal', 'Auth', 'JhiLanguageService', '$translate', '$rootScope', 'PointOfSale'];

    function SettingsController (Principal, Auth, JhiLanguageService, $translate, $rootScope, PointOfSale) {
        var vm = this;

        vm.error = null;
        vm.save = save;
        vm.settingsAccount = null;
        vm.success = null;
        vm.changePassword = changePassword;
        vm.doNotMatch = null;
        vm.pointOfSale = PointOfSale.get({id:-1});
        /**
         * Store the "settings account" in a separate variable, and not in the shared "account" variable.
         */
        var copyAccount = function (account) {
            return {
                id: account.id,
                activated: account.activated,
                email: account.email,
                firstName: account.firstName,
                langKey: account.langKey,
                lastName: account.lastName,
                login: account.login,
                photo: account.photo
            };
        };

        Principal.identity().then(function(account) {
            vm.settingsAccount = copyAccount(account);
            vm.authorities = $rootScope.AUTHORITIES;
        });

        function save () {
            var updatePhoto=false;
            if(vm.photoRawBase64!=null){
                vm.settingsAccount.photoRaw = vm.photoRawBase64.base64;
                updatePhoto = true;
            }
            if(vm.clearPhoto){
                updatePhoto = true;
            }
            Auth.updateAccount(vm.settingsAccount, updatePhoto).then(function() {
                vm.error = null;
                vm.success = 'OK';
                Principal.identity(true).then(function(account) {
                    vm.settingsAccount = copyAccount(account);
                    $rootScope.USERNAME = account.login;
                    $rootScope.FIRSTNAME = account.firstName;
                    $rootScope.LASTNAME = account.lastName;
                    $rootScope.AUTHORITIES = account.authorities;
                });
                JhiLanguageService.getCurrent().then(function(current) {
                    if (vm.settingsAccount.langKey !== current) {
                        $translate.use(vm.settingsAccount.langKey);
                    }
                });
            }).catch(function() {
                vm.success = null;
                vm.error = 'ERROR';
            });
        }

        //custom
        // vm.uploadFiles = function (file) {
        //     vm.image = file;
        //     if (file) {
        //         Upload.upload({
        //             //url: 'https://angular-file-upload-cors-srv.appspot.com/upload',
        //             url: 'api/user-infos/photo',
        //             data: {
        //                 file: file
        //             }
        //         }).then(function (response) {
        //             $timeout(function () {
        //                 vm.settingsAccount.photo = response.data.photo;
        //                 Principal.identity(true).then(function(account) {
        //                     if(account){
        //                         $rootScope.USERNAME = account.login;
        //                         $rootScope.FIRSTNAME = account.firstName;
        //                         $rootScope.LASTNAME = account.lastName;
        //                         $rootScope.PHOTO = response.data.photo;
        //                     }
        //                 });
        //             });
        //         }, function (response) {
        //             if (response.status > 0) {
        //                 vm.errorMsg = response.status + ': ' + response.data;
        //             }
        //         }, function (evt) {
        //             vm.progress =
        //                 Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
        //         });
        //     }
        // };
        vm.photoRawBase64=null;

        vm.clearPhotoRaw = function (event) {
            vm.settingsAccount.photoRaw = null;
            vm.settingsAccount.photo = null;
            vm.photoRawBase64 = null;
            vm.clearPhoto = true;
            event.preventDefault();
        };

        function changePassword () {
            if (vm.password !== vm.confirmPassword) {
                vm.errorPassword = null;
                vm.successPassword = null;
                vm.doNotMatch = 'ERROR';
            } else {
                vm.doNotMatch = null;
                Auth.changePassword(vm.password).then(function () {
                    vm.errorPassword = null;
                    vm.successPassword = 'OK';
                }).catch(function () {
                    vm.successPassword = null;
                    vm.errorPassword = 'ERROR';
                });
            }
        }

        vm.hasAccessRights = Auth.hasAccessRights;
    }
})();
