(function() {
    'use strict';

    var jhiAlertError = {
        template: '<div class="alerts" ng-cloak="">' +
                        '<div ng-repeat="alert in $ctrl.alerts" ng-class="[alert.position, {\'toast\': alert.toast}]">' +
                            '<uib-alert ng-cloak="" type="{{alert.type}}" close="alert.close($ctrl.alerts)"><pre>{{ alert.msg }}</pre></uib-alert>' +
                        '</div>' +
                  '</div>',
        controller: jhiAlertErrorController
    };

    angular
        .module('wamaApp')
        .component('jhiAlertError', jhiAlertError);

    jhiAlertErrorController.$inject = ['$scope', 'ToastAlertService', '$rootScope', '$translate'];

    function jhiAlertErrorController ($scope, ToastAlertService, $rootScope, $translate) {
        var vm = this;

        vm.alerts = [];

        function addErrorAlert (message, key, data) {
            if (data == null){
                 data = {};
            }
            key = key && key !== null ? key : message;
            //vm.alerts.push(
                // AlertService.add(
                //     {
                //         type: 'danger',
                //         msg: key,
                //         params: data,
                //         timeout: 5000,
                //         toast: AlertService.isToast(),
                //         scoped: true
                //     },
                //     vm.alerts
                // )
            //);

            //handle java bean annotation validation constraints
            if(data.hasOwnProperty('fieldName')){
                data = data.fieldName;
            }

            //handle custom parametrised exception
            if(data.hasOwnProperty('params')){
                data = data.params;
                if(Array.isArray(data) && data.length > 0){
                    data = data[0];
                }
            }

            ToastAlertService.show(
                {
                    type: ToastAlertService.error,
                    trustAsHtml: true,
                    msg: key,
                    params: data
                }
            );
            //toastr.error($translate.instant(key), "TEST");
        }

        var cleanHttpErrorListener = $rootScope.$on('wamaApp.httpError', function (event, httpResponse) {
            var i;
            event.stopPropagation();
            switch (httpResponse.status) {
            // connection refused, server not reachable
            case 0:
                addErrorAlert('Server not reachable','error.server.not.reachable');
                break;

            case 400:
                var errorHeader = httpResponse.headers('X-wamaApp-error');
                var entityKey = httpResponse.headers('X-wamaApp-params');
                if (errorHeader) {
                    var entityName = $translate.instant('global.menu.entities.' + entityKey);
                    addErrorAlert(errorHeader, errorHeader, {entityName: entityName});
                } else if (httpResponse.data && httpResponse.data.fieldErrors) {
                    for (i = 0; i < httpResponse.data.fieldErrors.length; i++) {
                        var fieldError = httpResponse.data.fieldErrors[i];
                        // convert 'something[14].other[4].id' to 'something[].other[].id' so translations can be written to it
                        var convertedField = fieldError.field.replace(/\[\d*\]/g, '[]');
                        var fieldName = $translate.instant('wamaApp.' + fieldError.objectName + '.' + convertedField);
                        addErrorAlert('Field ' + fieldName + ' cannot be empty', 'error.' + fieldError.message, {fieldName: fieldName});
                    }
                } else if (httpResponse.data && httpResponse.data.message) {
                    addErrorAlert(httpResponse.data.message, httpResponse.data.message, httpResponse.data);
                } else {
                    addErrorAlert(httpResponse.data);
                }
                break;

            default:
                if (httpResponse.data && httpResponse.data.message) {
                    addErrorAlert(httpResponse.data.message);
                } else {
                    addErrorAlert(angular.toJson(httpResponse));
                }
            }
        });

        $scope.$on('$destroy', function () {
            if(angular.isDefined(cleanHttpErrorListener) && cleanHttpErrorListener !== null){
                cleanHttpErrorListener();
                vm.alerts = [];
            }
        });
    }
})();
