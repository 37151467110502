(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('PreferencesDeleteController',PreferencesDeleteController);

    PreferencesDeleteController.$inject = ['$uibModalInstance', 'entity', 'Preferences'];

    function PreferencesDeleteController($uibModalInstance, entity, Preferences) {
        var vm = this;
        vm.preferences = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            Preferences.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
