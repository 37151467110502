(function() {
    'use strict';

    angular
        .module('wamaApp')
        .factory('LotNumberSearch', LotNumberSearch);

    LotNumberSearch.$inject = ['$resource'];

    function LotNumberSearch($resource) {
        var resourceUrl =  'api/_search/lot-numbers/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
