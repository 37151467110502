(function() {
    'use strict';

    angular
        .module('wamaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('settings', {
            parent: 'account',
            url: '/settings',
            data: {
                authorities: ['ROLE_USER','ROLE_OPERATOR','ROLE_SALES_OPERATOR','ROLE_WAREHOUSE_ASSISTANT'],
                pageTitle: 'global.menu.account.settings'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/settings/settings.html',
                    controller: 'SettingsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('settings');
                    $translatePartialLoader.addPart('password');
                    $translatePartialLoader.addPart('pointOfSale');
                    $translatePartialLoader.addPart('user-management');
                    return $translate.refresh();
                }]
            }
        })
        .state('settings.delete', {
            parent: 'settings',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER', 'ROLE_OPERATOR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/account/password/user-delete-dialog.html',
                    controller: 'UserDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['UserId', function(UserId) {
                            return UserId.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('home', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('settings.disableAccount', {
            parent: 'settings',
            url: '/{id}/disableAccount',
            data: {
                authorities: ['ROLE_USER','ROLE_OPERATOR','ROLE_SALES_OPERATOR','ROLE_WAREHOUSE_ASSISTANT'],
                pageTitle: 'password.disableAccount.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/account/password/user-disable-dialog.html',
                    controller: 'UserDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['UserId', function(UserId) {
                            return UserId.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function(result) {
                    $state.go('home');
                }, function() {
                    $state.go('^');
                })
            }]
        });
    }
})();
