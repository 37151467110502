(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('ActivationController', ActivationController);

    ActivationController.$inject = ['$stateParams', 'Auth', 'LoginService'];

    function ActivationController ($stateParams, Auth, LoginService) {
        var vm = this;
        vm.register = function () {
            if (vm.password !== vm.confirmPassword) {
                vm.doNotMatch = 'ERROR';
            }else{
                var userDTO ={
                    password: vm.password,
                    login: "user@activation.com"
                };
                Auth.activateAccount({key: $stateParams.key}, userDTO).then(function () {
                    vm.error = null;
                    vm.success = 'OK';
                }).catch(function () {
                    vm.success = null;
                    vm.error = 'ERROR';
                });
            }
        };


        vm.login = LoginService.open;
    }
})();
