(function () {
    'use strict';

    angular
        .module('wamaApp')
        .controller('ModuleExpiryDatesController', ModuleExpiryDatesController);

    ModuleExpiryDatesController.$inject = ['$scope', '$state', 'ToastAlertService', 'PointOfSale', 'Auth', '$translate', 'Preferences', '$rootScope'];

    function ModuleExpiryDatesController($scope, $state, ToastAlertService, PointOfSale, Auth, $translate, Preferences, $rootScope) {
        var vm = this;
        vm.loadAll = loadAll;
        vm.save = save;
        vm.loadAll('MODULE_EXPIRY_DATES');

            /******* Add here another property to save it automatically *******/
        vm.expiryDatesAlertThreshold = {};
        var properties = {
            'expiryDatesAlertThreshold': vm.expiryDatesAlertThreshold
        };
        var defaultPropertiesValue = {
            'expiryDatesAlertThreshold': {'propertyType': 'INTEGER', 'section':'MODULE_EXPIRY_DATES', 'nameOnPreferences': 'expiryDatesAlertThreshold'},
        };
        angular.forEach(properties, function(value,key){
            value.property = key;
            value.propertyType = defaultPropertiesValue[key]['propertyType'];
            value.section = defaultPropertiesValue[key]['section'];
            value.nameOnPreferences = defaultPropertiesValue[key]['nameOnPreferences'];
        });

        /******************* END ******************/

        function loadAll(section) {

            Preferences.mapQuery({id: section}, onSuccess, onError);

            function onSuccess(data) {
                angular.forEach(data, function (value, key) {
                    if (properties[key]) {
                        properties[key].id = value.id;
                        properties[key].property = (value.property) ? value.property : key;
                        properties[key].section = (value.section) ? value.section : defaultPropertiesValue[key]['section'];
                        properties[key].propertyType = (value.propertyType) ? value.propertyType : defaultPropertiesValue[key]['propertyType'];
                        if (value.value != null && (value.value.toUpperCase() === "TRUE" || value.value.toUpperCase() === "FALSE")) {
                            properties[key].value = value.value.toUpperCase() === "TRUE";
                        } if(value.value!=null && (value.propertyType === "INTEGER")){
                            properties[key].value = parseInt(value.value);
                        } else {
                            properties[key].value = value.value;
                        }
                    }
                });
            }

            function onError(error) {
                ToastAlertService.error(error.data.message);
            }
        }

        function save(preference) {
            Preferences.update(preference,
                function (data) {
                    properties[data.property].id = data.id;
                },
                function () {
                    ToastAlertService.show({
                        type: ToastAlertService.error,
                        msg: 'wamaApp.preferences.modules.' + preference.nameOnPreferences + '.errorOnSave'
                    });
                });
        }


        vm.hasAccessRights = Auth.hasAccessRights;


    }
})();
