(function() {
    'use strict';
    angular
        .module('wamaApp')
        .factory('LocationImport', LocationImport);

    LocationImport.$inject = ['$resource', 'DateUtils'];

    function LocationImport ($resource, DateUtils) {
        var resourceUrl =  'api/locations/import/:id';

        return $resource(resourceUrl, {includeRelations: true}, {
            'post': { method:'POST',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.timeCreated = DateUtils.convertDateTimeFromServer(data.timeCreated);
                    data.timeModified = DateUtils.convertDateTimeFromServer(data.timeModified);
                    return data;
                }
            },
            'import': { method: 'PUT'}
        });
    };
})();

