(function() {
    'use strict';

    angular
        .module('wamaApp')
        .config(aboutHelp);

    aboutHelp.$inject = ['$stateProvider'];

    function aboutHelp($stateProvider) {
        $stateProvider.state('modules', {
            parent: 'account',
            url: '/modules',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'settings.modules.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/settings/settings.modules.html',
                    controller: 'ModulesController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('settings');
                    $translatePartialLoader.addPart('preferences');
                    return $translate.refresh();
                }]
            }
        }),
        $stateProvider.state('module-sales-order-settings', {
            parent: 'modules',
            url: '/module-sales-order-settings',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'settings.modules.salesOrder.settings.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/settings/settings.modules-sales-order.settings.html',
                    controller: 'SalesOrderController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('settings');
                    $translatePartialLoader.addPart('preferences');
                    return $translate.refresh();
                }]
            }
        }),
        $stateProvider.state('module-public-shop-settings', {
            parent: 'modules',
            url: '/module-public-shop-settings',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'settings.modules.publicShop.settings.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/settings/settings.modules-public-shop-settings.html',
                    controller: 'ModulePublicShopController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('settings');
                    $translatePartialLoader.addPart('preferences');
                    $translatePartialLoader.addPart('pointOfSale');
                    return $translate.refresh();
                }]
            }
        }),
        $stateProvider.state('module-price-list-settings', {
            parent: 'modules',
            url: '/module-price-list-settings',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'settings.modules.priceList.settings.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/settings/settings.modules-price-list-settings.html',
                    controller: 'ModulePublicShopController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('settings');
                    $translatePartialLoader.addPart('preferences');
                    return $translate.refresh();
                }]
            }
        }),
        $stateProvider.state('module-expiry-dates-settings', {
            parent: 'modules',
            url: '/module-expiry-dates-settings',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'settings.modules.expiryDates.settings.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/settings/settings.modules-expiry-dates-settings.html',
                    controller: 'ModuleExpiryDatesController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('settings');
                    $translatePartialLoader.addPart('preferences');
                    return $translate.refresh();
                }]
            }
        }),
        $stateProvider.state('module-lot-numbers-settings', {
            parent: 'modules',
            url: '/module-lot-numbers-settings',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'settings.modules.lotNumbers.settings.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/settings/settings.modules-lot-numbers-settings.html',
                    controller: 'ModuleExpiryDatesController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('settings');
                    $translatePartialLoader.addPart('preferences');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
