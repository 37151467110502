(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('UserInfoDetailController', UserInfoDetailController);

    UserInfoDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'UserInfo', 'User'];

    function UserInfoDetailController($scope, $rootScope, $stateParams, entity, UserInfo, User) {
        var vm = this;
        vm.userInfo = entity;
        
        var unsubscribe = $rootScope.$on('wamaApp:userInfoUpdate', function(event, result) {
            vm.userInfo = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
