(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('PlanDetailController', PlanDetailController);

    PlanDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Plan', 'PricePlan'];

    function PlanDetailController($scope, $rootScope, $stateParams, entity, Plan, PricePlan) {
        var vm = this;
        vm.plan = entity;
        
        var unsubscribe = $rootScope.$on('wamaApp:planUpdate', function(event, result) {
            vm.plan = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
