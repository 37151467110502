(function() {
    'use strict';

    angular
        .module('wamaApp')
        .filter('abs', abs);

    function abs() {
        return absFilter;

        function absFilter (input) {
            if (input !== null) {
                input = Math.abs(input);
            }
            return input;
        }
    }
})();
