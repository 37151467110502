(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('SaleOrderEntryDialogController', SaleOrderEntryDialogController);

    SaleOrderEntryDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'SaleOrderEntry', 'Product', 'SaleOrder', 'User', 'Currency', 'OrderEntryStatus', 'PointOfSale'];

    function SaleOrderEntryDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, SaleOrderEntry, Product, SaleOrder, User, Currency, OrderEntryStatus, PointOfSale) {
        var vm = this;
        vm.saleOrderEntry = entity;
        vm.products = Product.query();
        vm.saleorders = SaleOrder.query();
        vm.users = User.query();
        vm.currencies = Currency.query();
        vm.orderentrystatuses = OrderEntryStatus.query();
        vm.pointofsales = PointOfSale.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('wamaApp:saleOrderEntryUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.saleOrderEntry.id !== null) {
                SaleOrderEntry.update(vm.saleOrderEntry, onSaveSuccess, onSaveError);
            } else {
                SaleOrderEntry.save(vm.saleOrderEntry, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
