(function(){
    'use strict';

    angular
        .module('wamaApp')
        .controller('SubscriptionInfoController', SubscriptionInfoController)
        .filter('StripeNumberFormat', StripeNumberFormat);

    SubscriptionInfoController.$inject = ['$scope','$state','$translate','$http', '$uibModal', 'ToastAlertService','SubscriptionActive',
        'Product','Location','Supplier','Plan','DateUtils','Auth','PointOfSale','User','StripeCard', 'Account', 'PaymentInvoice', 'PaymentCustomer', 'Country', '$rootScope'];

    function SubscriptionInfoController($scope, $state, $translate, $http, $uibModal, ToastAlertService, SubscriptionActive, Product, Location,
                                        Supplier, Plan, DateUtils, Auth, PointOfSale, User, StripeCard, Account, PaymentInvoice, PaymentCustomer, Country, $rootScope){
        var vm = this;
        vm.loadAll = loadAll;
        vm.getOverlayStyle = getOverlayStyle;
        vm.getColorByPercentage = getColorByPercentage;
        vm.calculatePercentage = calculatePercentage;
        vm.reloadPlans = reloadPlans;
        vm.subscribe = subscribePlan;
        vm.unsubscribe = unsubscribe;
        vm.reactivate = reactivate;
        vm.dismissBoxMessage = dismissBoxMessage;
        vm.loadAllCards = loadAllCards;
        vm.loadCurrentUser = loadCurrentUser;
        vm.loadCurrentPaymentCustomer = loadCurrentPaymentCustomer;
        vm.boot = boot;
        vm.addNewCard = addNewCard;
        vm.saveBillingAddress = saveBillingAddress;
        vm.isLoading = false;
        vm.showPlans = true;
        vm.noCustomer = true;
        vm.isLoadingCustomer = true;
        var EUR = "EUR (€)";
        var GBP = "GBP (£)";
        var USD = "USD ($)";
        var supportedCurrencies = [EUR, GBP, USD];
        var invoicesPageSize = 10;
        vm.invoicesPage = 0;
        var isAddingCard = false;
        vm.countries = Country.query({page: 0, size: 99999, sort: "name"});
        // vm.coupons = Coupon.query();

        vm.boot();

        function boot(){
            vm.upgradeSuccess=false;
            vm.loadCurrentUser();
            vm.loadAll();
            vm.loadAllCards();
            vm.loadCurrentPaymentCustomer();
        }

        vm.currencies = supportedCurrencies.map(function (currency) {
            return {
                abbrev: currency
            };
        });

        function loadAll(){
            vm.isLoading = true;
            SubscriptionActive.get({},onSuccess,onError);

            Plan.query({},function(data,headers){
                vm.plans = data;
                reloadDropDown();
            },onError);
            getInvoices(null, null);
            paginationFlags(true, false);


            function onSuccess(data){
                vm.isLoading = false;
                if(data == null || data.currency == null){
                    selectDefaultCurrencyByPointOfSaleCurrency();
                } else {
                    reloadPlans(data.currency.code);
                }
                vm.subscription = data;

            }
            function onError(error){
                vm.isLoading = false;
                var msg = error.data.message || 'Generic error';
                ToastAlertService.show({type: ToastAlertService.error, msg: msg});
            }
        }

        function paginationFlags(nextPage, previousPage){
            vm.nextPage = nextPage;
            vm.previousPage = previousPage;
        }

        function getInvoices(startingAfter, endingBefore){
            PaymentInvoice.query({size:invoicesPageSize, startingAfter: startingAfter, endingBefore: endingBefore},function(data,headers){
                vm.invoicesHasMore=headers('X-Has-More');
                vm.invoices = data;
            }, null);
        }

        vm.invoiceNextPage = function(startingAfter){
            paginationFlags(true, false);
            vm.invoicesPage = vm.invoicesPage + 1;
            getInvoices(startingAfter, null);
        };

        vm.invoicePreviousPage = function(endingBefore){
            paginationFlags(false, true);
            if(vm.invoicesPage>0) {
                vm.invoicesPage = vm.invoicesPage - 1;
            }
            getInvoices(null, endingBefore);
        };

        function reloadDropDown(){
            if(vm.plans){
                for(var i =0;i<vm.plans.length;i++){
                    var cPlan = vm.plans[i];
                    if (cPlan && cPlan.pricePlans && cPlan.pricePlans[0] && cPlan.pricePlans[0].currency) {
                        var defaultCurr = cPlan.pricePlans[0].currency.code;
                        for (var c in vm.currencies) {
                            if (defaultCurr === vm.currencies[c].abbrev) {
                                vm.currencies[c].selected = "selected";
                            }
                        }
                        break;
                    }
                }
            }
        }

        function reloadPlans(currency){
            Plan.query({currency:currency.split(" ")[0]},function(data){
                vm.plans = data;
            });
            reloadDropDown();
            vm.showPlans = true;
            vm.isLoading = false;
        }

        function reloadSubscription(){
            SubscriptionActive.get(function(data){
                vm.subscription = data;
                vm.showPlans = true;
                vm.isLoading = false;
            }, function(){
                vm.showPlans = true;
                vm.isLoading = false;
            });
        }

        function getOverlayStyle() {
            var isSemiOverlayStyle = this.semicircle;
            var transformOverlayStyle = (isSemiOverlayStyle ? '' : 'translateY(-50%) ') + 'translateX(-50%)';

            return {
                'top': isSemiOverlayStyle ? 'auto' : '50%',
                'bottom': isSemiOverlayStyle ? '5%' : 'auto',
                'left': '50%',
                'transform': transformOverlayStyle,
                '-moz-transform': transformOverlayStyle,
                '-webkit-transform': transformOverlayStyle,
                'font-size': this.radius / 3.5 + 'px'
            };
        }

        function calculatePercentage(value,max){
            var percentage = (100*value)/max;
            return ""+percentage;
        }

        function getColorByPercentage(value,max) {
            var x = (100*value)/max;
            var elem = document.getElementById("round-progress");
            if(x>=(100)) {
                return getComputedStyle(elem,null).getPropertyValue('--high-color');
            }else if(x<(200/3)) {
                return getComputedStyle(elem,null).getPropertyValue('--low-color');
            }else{
                return getComputedStyle(elem,null).getPropertyValue('--middle-color');
            }
        }
        vm.clientSecret = null;

        function subscribePlan(plan) {
            // vm.isLoading = true;
            // vm.showPlans = false;
            isAddingCard = false;
            vm.currentPlan = plan;
            $http.get('/api/stripe/cards', {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            }).then(function(response){
                if(angular.equals(response.data, [])){
                    addNewCard(plan);
                }else{
                    upgradePlan(plan)
                }
            },function(response){
                vm.showPlans = true;
                vm.isLoading=false;
            });
        }

        function addNewCard(plan) {
            $uibModal.open({
                keyboard: false,
                templateUrl: 'app/account/settings/subscriptioninfo-card-modal.html',
                controller: ['$uibModalInstance', function ($uibModalInstance) {
                    var vm = this;
                    vm.clear = function () {
                        $uibModalInstance.dismiss('cancel');
                    };
                    var unsubscribe = $rootScope.$on('wamaApp:cardCloseModal', function (event, result) {
                        vm.clear()
                    });
                    $scope.$on('$destroy', unsubscribe);
                    var cardAddedSuccessfully = $rootScope.$on('wamaApp:cardAddedSuccessfully', function (event, result) {
                        loadAllCards(); //reload cards to show the newly added
                        loadCurrentPaymentCustomer(); //reload billing information form
                        if(plan != null) {
                            upgradePlan(plan);
                        }

                    });
                    $scope.$on('$destroy', cardAddedSuccessfully);
                }],
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md'
            }).result.then(function (returnedObject) {
                //user clicked on cancel button
            }, function (returnedObject) {

            });
        }

        function unsubscribe(){
            vm.isLoading = true;
            vm.showPlans = false;
            $http.get('/api/stripe/unsubscribe', {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            }).then(function(response){
                ToastAlertService.show({type: ToastAlertService.success,msg: 'entity.payment.successfulUnsubscribe'});
                reloadSubscription();
            },function(response){
                vm.showPlans = true;
                vm.isLoading = false;
                var msg = response.data.message||"Generic Error";
                ToastAlertService.show({type: ToastAlertService.error, msg: msg, title: 'Unsubscribe error'});
            });
        }

        function reactivate(){
            vm.isLoading = true;
            vm.showPlans = false;
            $http.get('/api/stripe/reactivate', {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            }).then(function(response){
                ToastAlertService.show({type: ToastAlertService.success,msg: 'entity.payment.successfulReactivate'});
                reloadSubscription();
            },function(response){
                vm.showPlans = true;
                vm.isLoading = false;
                var msg = response.data.message||"Generic Error";
                ToastAlertService.show({type: ToastAlertService.error, msg: msg, title: 'Reactivate error'});
            });
        }

        function upgradePlan(plan){
            $http.post("/api/stripe/prorations",plan, {
                headers:{
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }).then(function(response){
                $uibModal.open({
                    keyboard:false,
                    templateUrl: 'app/account/settings/subscriptioninfo-modal.html',
                    controller: ['$uibModalInstance', 'Coupon', 'Country', 'PaymentCustomer', function($uibModalInstance, Coupon, Country, PaymentCustomer){
                        var vm = this;
                        vm.plan = plan;

                        PaymentCustomer.get(null, function (customer) {
                            vm.customer = customer;
                            if(customer!=null && customer.address!=null) {
                                vm.selectedCountry = getCountryByCode(customer.address.country)[0];
                            }
                        });

                        vm.countries = Country.query({page: 0, size: 99999, sort: "name"});
                        vm.paymentTransaction = response.data;
                        // vm.coupons = Coupon.query();
                        angular.forEach(vm.paymentTransaction.payments,function(item){
                            item.date = DateUtils.convertDateTimeFromServer(item.date);
                        });
                        vm.clear = function(){
                            $uibModalInstance.dismiss(null);
                        };
                        vm.close = function(returnObject) {
                            $uibModalInstance.dismiss(returnObject);
                        };
                        vm.hide = function(){
                            $uibModalInstance.disable();
                        };
                        vm.isSaving = false;
                        vm.proceed=function(){
                            vm.isSaving = true;
                            angular.forEach($('#footerButton').find('.btn'), function (item) {
                                item.setAttribute('disabled','disabled');
                            });

                            plan.billingAddress = {};
                            plan.billingAddress.name = vm.customer.name, //company name
                            plan.billingAddress.email = vm.customer.email,
                            plan.billingAddress.phone = vm.customer.phone,
                            plan.billingAddress.businessVatId = vm.customer.taxInfo !=null ? vm.customer.taxInfo.taxId : null,
                            plan.billingAddress.city = vm.customer.address ? vm.customer.address.city : null;
                            plan.billingAddress.postalCode = vm.customer.address ? vm.customer.address.postalCode : null;
                            plan.billingAddress.line1 = vm.customer.address ? vm.customer.address.line1 : null;
                            plan.billingAddress.line2 = vm.customer.address ? vm.customer.address.line2 : null;
                            plan.billingAddress.country = vm.selectedCountry ? vm.selectedCountry.code : null;
                            $http.post("/api/stripe/updateSubscriptions", plan,{
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Accept': 'application/json'
                                }
                            }).then(function (response) {
                                vm.isSaving = false;
                                loadCurrentPaymentCustomer();
                                response.success = true;
                                vm.close(response)
                            }, function (response) {
                                vm.isSaving = false;
                                response.success = false;
                                vm.close(response);
                            });
                        }
                    }],
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md'
                }).result.then(function(returnedObject){
                    //user clicked on cancel button
                },function(returnedObject){
                    if(!returnedObject){
                        vm.showPlans = true;
                        vm.showResponse = false;
                        vm.isLoading = false;
                    }else if(returnedObject.success) {
                        // vm.updateSubscriptionResponse = "entity.payment.successfulPayment";
                        vm.upgradeSuccess = true;
                        vm.responseClass = 'alert alert-success';
                        // vm.showResponse = true;
                        vm.isLoading = false;
                    }else if(!returnedObject.success){
                        vm.updateSubscriptionResponse = returnedObject.data.errorDTO["message"];
                        vm.responseClass = 'alert alert-danger';
                        vm.showResponse = true;
                        vm.isLoading = false;
                    }
                });
            },function(response){
                vm.showPlans = true;
                vm.isLoading=false;
            });
        }

        function dismissBoxMessage(){
            vm.loadAll();
            vm.showResponse = false;
            vm.isLoading = false;
            vm.showPlans = true;
        }

        function loadAllCards(){
            vm.isLoadingCards = true;
            StripeCard.query(null, function (data) {
                vm.cards = data;
                vm.cards.map(function (card) {
                    if (card.brand) {
                        card.brand = card.brand.toLowerCase();
                    }
                });
                vm.isLoadingCards = false;
            }, function () {
                vm.errorCards = true;
                vm.isLoadingCards = false;
            })
        };

        function loadCurrentPaymentCustomer(){
            vm.isLoadingCustomer = true;
            PaymentCustomer.get(null, function (customer) {
                vm.isLoadingCustomer = false;
                if(angular.isUndefined(customer.id)){
                    vm.noCustomer = true;
                }else {
                    vm.noCustomer = false;
                }
                vm.customer = customer;
                if(customer!=null && customer.address!=null) {
                    vm.selectedCountry = getCountryByCode(customer.address.country)[0];
                }
            }, function () {
                vm.isLoadingCustomer = false;
            })
        }

        function getCountryByCode(code) {
            return vm.countries.filter(
                function(data){ return (data.code == code) }
            );
        }

        function saveBillingAddress(){
            vm.isLoadingCustomer = true;
            var billingAddress = {
                name: vm.customer.name, //company name
                email: vm.customer.email,
                line1: vm.customer.address ? vm.customer.address.line1 : null,
                line2: vm.customer.address ? vm.customer.address.line2 : null,
                city: vm.customer.address ? vm.customer.address.city : null,
                country: vm.selectedCountry.code,
                postalCode: vm.customer.address ? vm.customer.address.postalCode : null,
                phone: vm.customer.phone,
                businessVatId: vm.customer.taxInfo && vm.customer.taxInfo.taxId ? vm.customer.taxInfo.taxId : null
            };

            PaymentCustomer.update(billingAddress, function (customer) {
                vm.isLoadingCustomer = false;
                vm.customer = customer;
                ToastAlertService.show({type: ToastAlertService.success, msg: 'wamaApp.subscription.billingInfo.saved'});
            }, function () {
                vm.isLoadingCustomer = false;
            });
        }

        vm.upgradingSubscription = false;
        function loadCurrentUser(){
            Account.get(null, function (user) {
                if (user!=null && user.data!=null && user.data.upgradingSubscription){
                    vm.upgradingSubscription = true;
                }
            });

        }

        function selectDefaultCurrencyByPointOfSaleCurrency() {
            //pre-select default currency
            vm.pointOfSale = PointOfSale.get({id: 0}, function (pointOfSale) {
                if (pointOfSale.currencies != null) {
                    angular.forEach(pointOfSale.currencies, function (currency) {
                        reloadPlans(currency.code);
                    })
                }
            });
        }

        vm.hasAccessRights = Auth.hasAccessRights;
    }

    function StripeNumberFormat(){
        return function(value) {
            return (parseInt(value) / 100).toFixed(2);
        }
    }
})();
;
