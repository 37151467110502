(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('CompanyDetailController', CompanyDetailController);

    CompanyDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Company', 'Address', 'User'];

    function CompanyDetailController($scope, $rootScope, $stateParams, entity, Company, Address, User) {
        var vm = this;
        vm.company = entity;
        
        var unsubscribe = $rootScope.$on('wamaApp:companyUpdate', function(event, result) {
            vm.company = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
