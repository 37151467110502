(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('StockTransferDialogController', StockTransferDialogController);

    StockTransferDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'StockTransfer', 'PointOfSale', 'Customer', 'Currency', 'StockTransferStatus',
        'StockTransferGoodsDescription', 'StockTransferReason', 'StockTransferMethod', 'StockTransferEntry', 'Product', 'StockTransferStatusUpdate', 'ProductSearch', 'AlertService',
        'ParseLinks', 'pagingParams', 'Category', 'Supplier', 'Location'];

    function StockTransferDialogController ($timeout, $scope, $stateParams, $uibModalInstance, StockTransfer, PointOfSale, Customer, Currency, StockTransferStatus, StockTransferGoodsDescription,
                                            StockTransferReason, StockTransferMethod, StockTransferEntry, Product, StockTransferStatusUpdate, ProductSearch, AlertService, ParseLinks,
                                            pagingParams, Category, Supplier, Location) {
        var vm = this;
        vm.searchProduct = searchProduct;
        vm.select = select;
        vm.isSaving = false;

        /**
         *        CHANGE STOCK TRANSFER STATUS
         */
        if($stateParams.id){
            vm.stockTransfer = StockTransfer.get({id: $stateParams.id});
        }
        vm.state = $stateParams.status;

        if($stateParams.stockTransfer){
            if($stateParams.stockTransfer.entries.length){
                vm.entries = $stateParams.stockTransfer.entries;
            } else if ($stateParams.stockTransfer.entries !=null && !angular.isArray($stateParams.stockTransfer.entries)){
                vm.entries = Array();
                vm.entries.push($stateParams.stockTransfer.entries);
            }
            vm.stockTransfer = $stateParams.stockTransfer;
        }

        vm.changeStatus = function (id) {
            vm.isSaving = true;
            StockTransferStatus.query({code: vm.state}, function (status) {
                vm.stockTransfer.status=status[0];
                StockTransferStatusUpdate.update({id: id}, vm.stockTransfer, onStatusUpdateSuccess, onError);
            }, onError);

            function onError() {
                vm.isSaving = false;
            }

        };

        var onStatusUpdateSuccess = function (result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        };

        /**
         *      ADD EDIT ENTRY PRODUCT
         */
        if($stateParams.destinationPointOfSaleId){
            vm.destinationPointOfSaleId = $stateParams.destinationPointOfSaleId; //might be null
        }

        function searchProduct () {
            ProductSearch.query({
                query: vm.productSearchQuery,
                page: 0,
                size: 20,
                pointOfSale: $stateParams.sourcePointOfSaleId,
                sort: "name"
            }, function (data) {
                vm.searchResults = data;
            }, function () { });
        }

        vm.addedProducts = [];
        function select(product){
            var data = {product: product, entryId: $stateParams.entryId};
            $scope.$emit('wamaApp:stockTransferEditProduct', data);
            // $uibModalInstance.close(true);
            vm.addedProducts.push(product.id);
        }

        if($stateParams.addedProducts){
            var ids = $stateParams.addedProducts.split(',');
            angular.forEach(ids, function(productId) {
                vm.addedProducts.push(+(productId));
            });
        }
        /**
         *  ALL PRODUCTS
         */

        vm.loadAll = loadAll;
        vm.numItems = 15; //paginationConstants.itemsPerPage

        vm.categories = Category.query({page: 0, size: 9000, sort: "name"});
        vm.suppliers = Supplier.query({page: 0, size: 9999, sort: 'name'});
        vm.locations = Location.query({page: 0, size: 9999, sort: 'name'});




        function loadAll(category, supplier, location, resetPage) {
            if(category != null){
                vm.supplier = null;
                vm.location = null;
            }
            if(supplier != null){
                vm.category = null;
                vm.location = null;
            }
            if(location != null){
                vm.category = null;
                vm.supplier = null;
            }
            if(resetPage){
                pagingParams.page = 1;
            }
            Product.query({
                page: pagingParams.page - 1,
                size: vm.numItems,
                sort: "name,ASC",
                outOfStock: false,
                category: category!=null ? category.id : "",
                supplier: supplier!=null ? supplier.id : "",
                location: location!=null ? location.id : ""
            }, onSuccess, onError);
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.products = data;
            vm.page = pagingParams.page;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        vm.nextPageAvailable = function(){
            return pagingParams.page < vm.totalItems / vm.numItems;
        };

        vm.prevPageAvailable = function(){
            return pagingParams.page > 1;
        };

        vm.nextPage = function (category, supplier, location) {
            if(vm.nextPageAvailable()){
                pagingParams.page = pagingParams.page + 1;
                loadAll(category, supplier, location);
            }
        };

        vm.prevPage = function (category, supplier, location) {
            if(vm.prevPageAvailable()) {
                pagingParams.page = pagingParams.page - 1;
                loadAll(category, supplier, location);
            }
        };


        /**
         *      COMMON
         */

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.stockTransferStatusColor = stockTransferStatusColor;

    }
})();
