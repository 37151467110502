'use strict';

angular.module('wamaApp')
	.controller('DeliveryNoteEntryDeleteController', function($scope, $uibModalInstance, entity, SaleProduct) {

        $scope.saleProduct = entity;
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (deliveryNoteEntryId) {
            SaleProduct.delete({id: deliveryNoteEntryId, removeStock:true},
                function () {
                    $uibModalInstance.close(true);
                });
        };

    });
