(function() {
    'use strict';
    angular
        .module('wamaApp')
        .factory('StockTransfer', StockTransfer);

    StockTransfer.$inject = ['$resource', 'DateUtils'];

    function StockTransfer ($resource, DateUtils) {
        var resourceUrl =  'api/stock-transfers/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.documentDate = DateUtils.convertDateTimeFromServer(data.documentDate);
                    data.shippingDate = DateUtils.convertDateTimeFromServer(data.shippingDate);
                    data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                    data.lastModifiedDate = DateUtils.convertDateTimeFromServer(data.lastModifiedDate);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();

var stockTransferCalculationHelpers = {
    'subtotal': function(entries) {
        var subtotal = 0;
        var taxIncluded;
        angular.forEach(entries, function(entry, key){
            if(entry.basePrice != null){
                taxIncluded = entry.taxIncluded;
                if(taxIncluded!=null && taxIncluded == true){
                    subtotal += entry.basePrice * entry.quantity / (1 + (entry.taxPercentage / 100));
                } else {
                    subtotal += entry.basePrice * entry.quantity;
                }
            }
        });
        return subtotal;
    },
    'tax': function (entries) {
        var netPrice = 0;
        var grossPrice = 0;
        var taxIncluded;
        angular.forEach(entries, function(entry, key){
            if(entry.basePrice != null && entry.taxPercentage != null){
                taxIncluded = entry.taxIncluded;
                if(taxIncluded!=null && taxIncluded == true){
                    netPrice += entry.basePrice * entry.quantity / (1 + (entry.taxPercentage / 100));
                    grossPrice += entry.basePrice * entry.quantity;
                } else {
                    netPrice += entry.basePrice * entry.quantity;
                    grossPrice += entry.basePrice * entry.quantity * (1 + (entry.taxPercentage / 100));
                }
            }
        });
        return grossPrice - netPrice;
    },
    'total': function (entries) {
        var total = 0;
        var taxIncluded;
        angular.forEach(entries, function(entry, key){
            if(entry.basePrice != null){
                taxIncluded = entry.taxIncluded;
                if(entry.taxPercentage==null || (taxIncluded!=null && taxIncluded == true)){
                    total += entry.basePrice * entry.quantity;
                } else {
                    total += entry.basePrice * entry.quantity * (1 + (entry.taxPercentage / 100));
                }
            }
        });
        return total;
    }
};

var stockTransferStatusColor = function (statusCode) {
    switch(statusCode){
        case 'DRAFT':
            return 'label-info';
            break;
        case 'IN_TRANSIT':
            return 'label-primary';
            break;
        case 'COMPLETED':
            return 'label-success';
            break;
        case 'CHANGE_ORDER':
            return 'label-warning';
            break;
        case 'CANCELLED':
            return 'label-danger';
            break;
        default:
            return 'label-default';
    }
};
