(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('ProductSupplierDialogController', ProductSupplierDialogController);

    ProductSupplierDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ProductSupplier', 'Price', 'Product', 'Tax', 'PointOfSale',
        'ProductFullSearch', 'Category', 'pagingParams', 'ParseLinks', '$q', 'AlertService'];

    function ProductSupplierDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, ProductSupplier, Price, Product, Tax, PointOfSale, ProductFullSearch,
                                              Category, pagingParams, ParseLinks, $q, AlertService) {
        var vm = this;
        vm.productSupplier = entity;
        vm.taxes = Tax.query();
        vm.isCollapsed = true;
        vm.supplier = $stateParams.supplier;
        vm.productSupplier.supplier = $stateParams.supplier;
        vm.pointOfSale = PointOfSale.get({id: 0});
        vm.currencyHelpers = currencyHelpers;
        vm.taxHelpers = taxHelpers;
        vm.priceCalculationHelpers = priceCalculationHelpers;
        vm.categories = Category.query({page: 0, size: 9999, sort: "name"});
        vm.loadAll = loadAll;
        vm.searchProduct = searchProduct;

        //waiting to resolve promise
        $q.all(entity)
            .then(function (productSupplier) {
                vm.editProduct = (vm.editProduct == null && entity.id == null && entity.product == null) ? true : false;
            });


        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('wamaApp:productSupplierUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if(vm.productSupplier.price!=null && (vm.productSupplier.price.price == null || vm.productSupplier.price.price == "")){
                vm.productSupplier.price = null;
            }
            if (vm.productSupplier.id !== null) {
                ProductSupplier.update(vm.productSupplier, onSaveSuccess, onSaveError);
            } else {
                ProductSupplier.save(vm.productSupplier, onSaveSuccess, onSaveError);
            }
        };

        function searchProduct () {
            ProductFullSearch.query({
                query: vm.productSearchQuery,
                page: 0,
                size: 20,
                sort: "name",
                lastPurchasePrice: true
            }, function (data){
                vm.searchResults = data;
            }, onSaveError);
        }

        vm.numItems = 15; //paginationConstants.itemsPerPage

        function loadAll(category, supplier, resetPage) {
            if(category != null){
                vm.supplier = null;
            }
            if(supplier != null){
                vm.category = null;
            }
            if(resetPage){
                pagingParams.page = 1;
            }
            Product.query({
                page: pagingParams.page - 1,
                size: vm.numItems,
                sort: "quantity,ASC",
                outOfStock: false,
                category: category!=null ? category.id : "",
                supplier: supplier!=null ? supplier.id : "",
                lastPurchasePrice: true
            }, onSuccess, onError);
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.products = data;
            vm.page = pagingParams.page;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        vm.select = function (product) {
            vm.productSupplier.product = product;
            vm.editProduct = false;
        };


        vm.nextPageAvailable = function(){
            return pagingParams.page < vm.totalItems / vm.numItems;
        };

        vm.prevPageAvailable = function(){
            return pagingParams.page > 1;
        };

        vm.nextPage = function (category, supplier) {
            if(vm.nextPageAvailable()){
                pagingParams.page = pagingParams.page + 1;
                loadAll(category, supplier);
            }
        };

        vm.prevPage = function (category, supplier) {
            if(vm.prevPageAvailable()) {
                pagingParams.page = pagingParams.page - 1;
                loadAll(category, supplier);
            }
        };

        function defaultCurrency(pointOfSale){
            if(vm.productSupplier.id == null) {
                if(pointOfSale!=null && pointOfSale.currencies !=null && pointOfSale.currencies.length > 0){
                    if(vm.productSupplier.price == null){
                        vm.productSupplier.price = {};
                    }
                    vm.productSupplier.price.currency = pointOfSale.currencies[0];
                }
                if(pointOfSale.defaultTax!=null){
                    vm.productSupplier.price.tax = pointOfSale.defaultTax;
                    vm.productSupplier.price.taxIncluded=true;
                }
            }
        }

        $scope.$watch('vm.pointOfSale', function(){
            if(vm.pointOfSale!=null && vm.taxes != null) {
                defaultCurrency(vm.pointOfSale);
            }
        }, true);

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.timeCreated = false;
        vm.datePickerOpenStatus.timeModified = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
    }
})();
