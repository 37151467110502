(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('CustomerAddressDialogController', CustomerAddressDialogController);

    CustomerAddressDialogController.$inject = ['$rootScope', '$log', '$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'CustomerAddress', 'Country', 'uiGmapGoogleMapApi', 'PointOfSale'];

    function CustomerAddressDialogController ($rootScope, $log, $timeout, $scope, $stateParams, $uibModalInstance, entity, CustomerAddress, Country, uiGmapGoogleMapApi, PointOfSale) {
        var vm = this;
        $scope.mapRendering = true;
        vm.address = entity;
        vm.countries = Country.query();
        vm.pointOfSale = PointOfSale.get({id: 0}, preselectDefaultCountry, null);
        //initialize map as the library is lazy loaded
        $scope.map = { center: { latitude: 52, longitude: 0 }, zoom: 8 };
        $scope.marker = {
            id: 0,
            coords: angular.copy($scope.map.center),
            options: { draggable: false },
            events: {
                dragend: function (marker, eventName, args) {
                    var lat = marker.getPosition().lat();
                    var lon = marker.getPosition().lng();
                    $log.log(lat);
                    $log.log(lon);

                    $scope.marker.options = {
                        draggable: false,
                        labelContent: "lat: " + $scope.marker.coords.latitude + ' ' + 'lon: ' + $scope.marker.coords.longitude,
                        labelAnchor: "100 0",
                        labelClass: "marker-labels"
                    };
                }
            }
        };

        // geocode the given address
        var geocodeAddress = function(address, callback) {
            var geocoder = new google.maps.Geocoder();
            geocoder.geocode( { 'address': address}, function(results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                    callback(results[0].geometry.location);
                } else {
                    console.log("Geocode was not successful for the following reason: " + status);
                }
            });
        };

        function preselectDefaultCountry(pointOfSale){
            if(vm.address != null && vm.address.id>0){
                return;
            }
            //preselect default country
            if(pointOfSale.address!=null && pointOfSale.address.country!=null){
                vm.address.country = pointOfSale.address.country;
            }else if(company.address!=null && company.address.country!=null){
                vm.address.country = company.address.country;
            }
        }


        $scope.$watchGroup(["vm.address.postcode","vm.address.city", "vm.address.line1", "vm.address.country"], function (newAddress, oldVal) {
            if (!angular.isUndefined(newAddress) && newAddress!=null){
                var address = (vm.address.city!=null ? vm.address.city: "") + " " + (vm.address.line1 != null ? vm.address.line1 : "") + " " + (vm.address.postcode != null ? vm.address.postcode : "") + (vm.address.country != null ? vm.address.country.name : "");
                if(newAddress!=null){
                    uiGmapGoogleMapApi.then(function(maps) {
                        geocodeAddress(address, gMapCallback);
                    });
                }
            }
        });

        function gMapCallback(latLng){
            $scope.mapRendering = false;
            $scope.map = { center: { latitude: latLng.lat(), longitude: latLng.lng() }, zoom: 8};
            // $scope.location = latLng;
            $scope.marker = {
                id: 0,
                coords: angular.copy($scope.map.center),
                options: { draggable: false },
                events: {
                    dragend: function (marker, eventName, args) {
                        $log.log('marker dragend');
                        var lat = marker.getPosition().lat();
                        var lon = marker.getPosition().lng();
                        $log.log(lat);
                        $log.log(lon);

                        $scope.marker.options = {
                            draggable: false,
                            labelContent: "lat: " + $scope.marker.coords.latitude + ' ' + 'lon: ' + $scope.marker.coords.longitude,
                            labelAnchor: "100 0",
                            labelClass: "marker-labels"
                        };
                    }
                }
            };
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });


        $rootScope.$on('wamaApp:addressDelete', function(event, addressIdToDelete) {
            $uibModalInstance.close(addressIdToDelete);
        });


        vm.save = function () {
            vm.isSaving = true;
            vm.address.customer=vm.customer;
            $scope.$emit('wamaApp:customerAddAddress', vm.address);
            $uibModalInstance.close(vm.address);
            vm.isSaving = false;

        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
