(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('CompanyDeleteDataController', CompanyDeleteDataController);

    CompanyDeleteDataController.$inject = ['$uibModalInstance', '$resource'];

    function CompanyDeleteDataController ($uibModalInstance, $resource) {

        var vm = this;

        vm.preconfirm=false;
        vm.preConfirm = function (){
            vm.preconfirm=true;
        }

        vm.confirmDelete = function () {
            var CompanyData = $resource('api/companies/data/',{}, {});
            CompanyData.delete(
                function () {
                    $uibModalInstance.close(true);
                });
        };
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();

