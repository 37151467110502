'use strict';

angular.module('wamaApp').controller('DeliveryNoteEntryDialogController',
    ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'SaleProduct', 'Product', 'Sale', 'User', 'Currency', 'OrderEntryStatus', 'DeliveryNote', 'Customer', 'PointOfSale',
        function($scope, $stateParams, $uibModalInstance, entity, SaleProduct, Product, Sale, User, Currency, OrderEntryStatus, DeliveryNote, Customer, PointOfSale) {

        $scope.saleProduct = entity;
        $scope.products = Product.query();
        $scope.sales = Sale.query();
        $scope.users = User.query();
        $scope.currencys = Currency.query();
        $scope.orderentrystatuss = OrderEntryStatus.query();
        $scope.deliverynotes = DeliveryNote.query();
        $scope.customers = Customer.query();
        $scope.pointofsales = PointOfSale.query();
        $scope.load = function(id) {
            SaleProduct.get({id : id}, function(result) {
                $scope.saleProduct = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('wamaApp:saleProductUpdate', result);
            $uibModalInstance.close(result);
            $scope.isSaving = false;
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        $scope.save = function () {
            $scope.isSaving = true;
            if ($scope.saleProduct.id != null) {
                SaleProduct.update({updateStock: true}, $scope.saleProduct, onSaveSuccess, onSaveError);
            } else {
                SaleProduct.save({addStock: 'true'}, $scope.saleProduct, onSaveSuccess, onSaveError);
            }
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.datePickerForTimeCreated = {};

        $scope.datePickerForTimeCreated.status = {
            opened: false
        };

        $scope.datePickerForTimeCreatedOpen = function($event) {
            $scope.datePickerForTimeCreated.status.opened = true;
        };
        $scope.datePickerForTimeModified = {};

        $scope.datePickerForTimeModified.status = {
            opened: false
        };

        $scope.datePickerForTimeModifiedOpen = function($event) {
            $scope.datePickerForTimeModified.status.opened = true;
        };
}]);
