(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('PurchaseOrderEditController', PurchaseOrderEditController);

    PurchaseOrderEditController.$inject = ['$timeout', '$scope', 'entity', 'PurchaseOrder', 'Supplier', 'PointOfSale', 'PurchaseOrderStatus', '$rootScope', 'Tax', '$state', 'Country', 'Product', 'AlertService', 'ToastAlertService'];

    function PurchaseOrderEditController ($timeout, $scope, entity, PurchaseOrder, Supplier, PointOfSale, PurchaseOrderStatus, $rootScope, Tax, $state, Country, Product, AlertService, ToastAlertService) {
        var vm = this;
        vm.purchaseOrder = entity;
        vm.suppliers = Supplier.query({page: 0, size: 99999, sort: "name"});
        vm.pointOfSale = PointOfSale.get({id: 0});
        vm.taxes = Tax.query({page: 0, size: 99999});
        vm.countries = Country.query({page: 0, size: 99999, sort: "name"});

        defaultShippingAddress();

        var newEntryId = 9999999;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('wamaApp:purchaseOrderUpdate', result);
            vm.isSaving = false;
            vm.purchaseOrder = result;
            $state.go('purchase-order-detail', {id:vm.purchaseOrder.id});
        };

        $scope.$watch('vm.purchaseOrder', function(oldElem, newElem) {
            if(oldElem== null || newElem == null || oldElem.$resolved==false || newElem.$resolved==false){
                return;
            }
            if(oldElem!=newElem){
                vm.orderChanged = true;
            } else{
                vm.orderChanged = false;
            }
        }, true);

        var onSaveError = function () {
            vm.isSaving = false;
            var id = newEntryId;
            angular.forEach(vm.purchaseOrder.entries, function(entry, key){
                if(entry.id==null){
                    vm.purchaseOrder.entries[key].id = id++;
                }
            });
        };

        function defaultShippingAddress(){
            $scope.$watch('vm.pointOfSale', function(newval, oldval){
                if(vm.pointOfSale!=null && vm.pointOfSale.company!=null && vm.purchaseOrder!=null && vm.purchaseOrder.shippingAddress == null){
                    if(vm.pointOfSale.company.address && (vm.pointOfSale.company.address.country || vm.pointOfSale.company.address.city
                        || vm.pointOfSale.company.address.postcode || vm.pointOfSale.company.address.line1 || vm.pointOfSale.company.address.line2
                        || vm.pointOfSale.company.address.line3) ){
                        vm.purchaseOrder.shippingAddress = {};
                        vm.purchaseOrder.shippingAddress.id = null;
                        vm.purchaseOrder.shippingAddress.email = null;
                        vm.purchaseOrder.shippingAddress.country = vm.pointOfSale.company.address.country;
                        vm.purchaseOrder.shippingAddress.city = vm.pointOfSale.company.address.city;
                        vm.purchaseOrder.shippingAddress.postcode = vm.pointOfSale.company.address.postcode;
                        vm.purchaseOrder.shippingAddress.line1 = vm.pointOfSale.company.address.line1;
                        vm.purchaseOrder.shippingAddress.line2 = vm.pointOfSale.company.address.line2;
                        vm.purchaseOrder.shippingAddress.line3 = vm.pointOfSale.company.address.line3;
                    }
                }
            }, true);
        }


        vm.save = function () {
            vm.isSaving = true;
            var orderCurrency = null;
            var currencyError = false;
            angular.forEach(vm.purchaseOrder.entries, function(entry, key){
                //currency and tax included can be set only at global level, we do it on the first entry end copy them over the followings
                if(vm.purchaseOrder.entries[key].price != null){
                    var currency = vm.purchaseOrder.entries[key].price.currency;
                    if(currency!=null && orderCurrency == null){
                        orderCurrency = currency;
                    }
                    if(currency!=null && orderCurrency!=null && orderCurrency.id!==currency.id){
                        currencyError = true;
                    }
                    // var taxIncluded = vm.purchaseOrder.entries[0].price.taxIncluded;
                    vm.purchaseOrder.entries[key].price.currency = currency;
                    // vm.purchaseOrder.entries[key].price.taxIncluded = taxIncluded;
                }
                if(entry.id >= newEntryId){
                    vm.purchaseOrder.entries[key].id = null;
                }
            });
            if(currencyError){
                ToastAlertService.show({type: ToastAlertService.error, msg: "wamaApp.purchaseOrder.error.entries.differentCurrencies"});
                return;
            }
            if (vm.purchaseOrder.id !== null) {
                vm.purchaseOrder.shippingAddress.id = null;
                PurchaseOrder.update(vm.purchaseOrder, onSaveSuccess, onSaveError);
            } else {
                PurchaseOrder.save(vm.purchaseOrder, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.timeCreated = false;
        vm.datePickerOpenStatus.timeModified = false;
        vm.datePickerOpenStatus.timeExpectedDelivery = false;
        vm.datePickerOpenStatus.timeRequestedDelivery = false;
        vm.datePickerOpenStatus.timeClosed = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };

        vm.openCalendarOrderLine = function(index){
            if(vm.purchaseOrder.entries[index].datePickerOpenStatus ==null){
                vm.purchaseOrder.entries[index].datePickerOpenStatus = {}
            }
            vm.purchaseOrder.entries[index].datePickerOpenStatus.timeExpected=true;
        };

        vm.insertOrUpdateEntry = function(product){
            var updated = false;
            angular.forEach(vm.purchaseOrder.entries, function (entry) {
                if(entry.product!= null && entry.product.id == product.id){
                    if(entry.quantity>0){
                        entry.quantity = entry.quantity+1;
                    }else{
                        entry.quantity = 1;
                    }
                    updated = true;
                }
            });
            if(!updated){
                vm.newEntry(product, null);
            }
        };

        vm.newEntry = function (product, productSupplier) {
            var newId = newEntryId;
            var entries = vm.purchaseOrder.entries;
            if(entries!=null && entries.length>0){
                var last_entry = entries[vm.purchaseOrder.entries.length - 1];
                if(last_entry.id >= newEntryId){
                    newId = last_entry.id + 1;
                }
            }
            var price = null;
            //price cleanup
            if(productSupplier!=null && productSupplier.price!=null){
                price = productSupplier.price;
                price.id = null;
                price.product = null;
            } else if(product.lastPurchasePrice){
                price = product.lastPurchasePrice;
                price.id = null;
                price.product = null;
            }
            var entry = {
                id: newId,
                price: price!=null ? price : {},
                product: product,
                quantity: 1,
                quantityOutstanding: null,
                supplierCode: productSupplier !=null ? productSupplier.supplierCode : null,
                timeCreated: null,
                timeExpected: null,
                timeModified: null,
                datePickerOpenStatus: {
                    timeExpected: false
                }
            };
            if(vm.purchaseOrder.entries == null){
                vm.purchaseOrder.entries = Array();
            }
            vm.purchaseOrder.entries.push(entry);
            // $state.go('purchase-order-detail-edit.product', ({id:vm.purchaseOrder.id, entryId: newId}))
            if(!product) {
                openProductSelectionDialog(newId);
            }
        };

        vm.openProductSelectionDialog = openProductSelectionDialog;
        function openProductSelectionDialog(newId) {
            $state.go('purchase-order-detail-edit.product', ({
                entryId: newId,
                supplier: vm.purchaseOrder.supplier,
                addedProducts: getAddedProductIds()
            }))
        }

        function getAddedProductIds(){
            var ids = [];
            angular.forEach(vm.purchaseOrder.entries, function(entry){
                if(entry.product){
                    ids.push(entry.product.id);
                }
            });
            return ids;
        }


        vm.addByBarcode = function () {
            Product.query({barcode: vm.barcode}, function (result) {
                if(result!=null && result.length == 1){
                    vm.insertOrUpdateEntry(result[0]);
                    AlertService.success("wamaApp.purchaseOrder.barcode.added");
                } else {
                    AlertService.error("wamaApp.purchaseOrder.barcode.notFound");

                }
            }, function (error) {
                AlertService.error("wamaApp.purchaseOrder.barcode.notFound");
            })
        };

        vm.deleteEntry = function (id) {
            angular.forEach(vm.purchaseOrder.entries, function(entry, key){
                if(entry.id == id){
                    vm.purchaseOrder.entries.splice(key, 1);
                }
            });
        };

        var unsubscribe = $rootScope.$on('wamaApp:purchaseOrderEditProduct', function(event, data) {
            addProduct(data);
        });

        function addProduct(data){
            var entryId = data.entryId;
            var selectedProduct = null;
            if(data.product!=null) {
                selectedProduct = data.product;
            } else if(data.productSupplier!=null){
                selectedProduct = data.productSupplier.product;
            }
            var entryPosition = null;
            var duplicatedProduct=false;
            angular.forEach(vm.purchaseOrder.entries, function(entry, key){
                // if(entry.id == entryId){
                //     vm.purchaseOrder.entries[key].product=selectedProduct;
                // }
                if(entry.product!=null && selectedProduct!=null && entry.product.id === selectedProduct.id){
                    duplicatedProduct = true;
                }
                if(entryId!=null && entry.id === entryId){
                    entryPosition = key;
                }
            });
            if(duplicatedProduct){
                AlertService.error("wamaApp.purchaseOrder.error.product.duplicated");
            } else if(entryPosition !=null){
                vm.purchaseOrder.entries[entryPosition].product=selectedProduct;
                if(selectedProduct!=null && selectedProduct.lastPurchasePrice!=null){
                    var price = selectedProduct.lastPurchasePrice;
                    price.id=null;
                    price.product=null;
                    vm.purchaseOrder.entries[entryPosition].price = price;
                }
            } else{
                if(!entryId){
                    vm.newEntry(selectedProduct, data.productSupplier);
                }
            }
        }

        $scope.$on('$destroy', unsubscribe);

        vm.calculationHelpers = calculationHelpers;
        vm.currencyHelpers = currencyHelpers;
        vm.taxHelpers = taxHelpers;

        vm.purchaseOrderStatusColor = purchaseOrderStatusColor;
    }
})();
