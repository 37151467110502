(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('PointOfSaleDialogController', PointOfSaleDialogController);

    PointOfSaleDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'PointOfSale', 'Company', 'Address', 'PaymentMethod', 'User',
        'Currency', 'Timezone', 'Tax', 'Country'];

    function PointOfSaleDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, PointOfSale, Company, Address, PaymentMethod, User, Currency, Timezone,
                                          Tax, Country) {
        var vm = this;
        vm.pointOfSale = entity;

        //waiting to resolve promise
        $q.all(entity)
            .then(function (pointOfSale) {
                vm.currentPointOfSale = PointOfSale.get({id:-1}, onCurrentPointOfSaleResult, null);
            })
            .catch(function (err) {
                //Handle errors
            });

        //initialize country with the same of the current point of sle
        function onCurrentPointOfSaleResult(result) {
            if(vm.pointOfSale.address==null || vm.pointOfSale.address.country == null){
                if(vm.pointOfSale.address == null){
                    vm.pointOfSale.address = {};
                }
                vm.pointOfSale.address.country = result.address.country;
            }
        }

        // vm.companies = Company.query();
        // vm.addresss = Address.query({filter: 'pointofsale-is-null'});
        // $q.all([vm.pointOfSale.$promise, vm.addresss.$promise]).then(function() {
        //     if (!vm.pointOfSale.address || !vm.pointOfSale.address.id) {
        //         return $q.reject();
        //     }
        //     return Address.get({id : vm.pointOfSale.address.id}).$promise;
        // }).then(function(address) {
        //     vm.addresses.push(address);
        // });
        // vm.paymentmethods = PaymentMethod.query();
        vm.users = User.query();
        vm.currencies = Currency.query({page: 0, size: 99999, sort: "code"});
        // vm.timezones = Timezone.query();
        vm.taxes = Tax.query();
        vm.countries = Country.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('wamaApp:pointOfSaleUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.pointOfSale.id !== null) {
                PointOfSale.update(vm.pointOfSale, onSaveSuccess, onSaveError);
            } else {
                PointOfSale.save(vm.pointOfSale, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.currencyHelpers = currencyHelpers;
    }
})();
