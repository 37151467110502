(function() {
    'use strict';
    angular
        .module('wamaApp')
        .factory('Price', Price);

    Price.$inject = ['$resource', 'DateUtils'];

    function Price ($resource, DateUtils) {
        var resourceUrl =  'api/prices/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.priceStartValidity = DateUtils.convertDateTimeFromServer(data.priceStartValidity);
                    data.priceEndValidity = DateUtils.convertDateTimeFromServer(data.priceEndValidity);
                    data.discountStartValidity = DateUtils.convertDateTimeFromServer(data.discountStartValidity);
                    data.discountEndValidity = DateUtils.convertDateTimeFromServer(data.discountEndValidity);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();


var priceHelpers = {
    'getSellingPrice': function(product) {
        var result = null;
        if(product!=null && product.prices != null){
            angular.forEach(product.prices, function (price) {
                if(price.priceList == null){
                    result = price;
                }
            })
        }
        return result;
    },
    'getSellingPriceFormatted': function (product) {
        var price = priceHelpers.getSellingPrice(product);
        //todo format price
        return price;
    }
};
