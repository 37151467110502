(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('UnitOfMeasureDetailController', UnitOfMeasureDetailController);

    UnitOfMeasureDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'UnitOfMeasure', 'PointOfSale'];

    function UnitOfMeasureDetailController($scope, $rootScope, $stateParams, entity, UnitOfMeasure, PointOfSale) {
        var vm = this;
        vm.unitOfMeasure = entity;
        
        var unsubscribe = $rootScope.$on('wamaApp:unitOfMeasureUpdate', function(event, result) {
            vm.unitOfMeasure = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
