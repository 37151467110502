(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('UserManagementCouponDialogController',UserManagementCouponDialogController);

    UserManagementCouponDialogController.$inject = ['$stateParams', '$uibModalInstance', 'entity', 'UserAdmin', 'Coupon'];

    function UserManagementCouponDialogController ($stateParams, $uibModalInstance, entity, UserAdmin, Coupon) {
        var vm = this;

        vm.clear = clear;
        vm.languages = null;
        vm.save = save;
        vm.coupon = {
            companyId: $stateParams.companyId,
            used: false
        };

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function save () {
            vm.isSaving = true;
            Coupon.save(vm.coupon, onSaveSuccess, onSaveError);
        }

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.expires = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
    }
})();
