(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('ProductQuantityController', ProductQuantityController);

    ProductQuantityController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Product', 'Tax', 'StockEntry', 'Supplier', 'Location', '$rootScope',
        'Auth', 'StockEntryReason', 'StockEntryProduct', 'LotNumber', 'CompanyPreferences'];

    function ProductQuantityController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Product, Tax, StockEntry, Supplier, Location, $rootScope, Auth, StockEntryReason,
                                        StockEntryProduct, LotNumber, CompanyPreferences) {
        var vm = this;
        vm.product = entity;
        vm.taxes = Tax.query();
        var allSuppliers = Supplier.query({page: 0, size: 9999, sort: 'name'});
        var allLocations = Location.query({page: 0, size: 9997, sort: 'name'});
        vm.reasons = StockEntryReason.query({page: 0, size: 9999, sort: 'code'});
        var allLotNumbers = LotNumber.query({closed:false, page: 0, size: 99999, sort: 'name'});
        vm.lotnumbers = allLotNumbers;
        vm.suppliers = allSuppliers;
        vm.locations = allLocations;
        var originalQuantity;

        // $rootScope.$on('$stateChangeSuccess',
        //     function(event, toState, toParams, fromState, fromParams){
        //         vm.locations = Location.query({page: 0, size: 9996, sort: 'name'});
        //         vm.suppliers = Supplier.query({page: 0, size: 9999, sort: 'name'});
        //     });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        vm.companyPreferences = CompanyPreferences.getPreferencesFromLocalStorage();



        function defaultSupplier(){
            $scope.$watch('vm.suppliers', function(newval, oldval){
                var keepGoing = true;
                angular.forEach(vm.suppliers, function (supplier) {
                    if(keepGoing){
                        if(vm.product.supplier!=null && supplier.id==vm.product.supplier.id){
                            vm.selectedSupplier = supplier;
                            keepGoing = false;
                        }else if(supplier.priority == 0){
                            vm.selectedSupplier = supplier;
                        }
                    }
                });
            }, true);
        }

        function setOriginalProductQuantity(){
            $scope.$watch('vm.product', function(newval, oldval){
                if(null != vm.product && null!= vm.product.pointOfSale){
                    if(originalQuantity==null){
                        //disable the save button when the quantity is not changed
                        originalQuantity = entity.quantity;
                    }
                }
            }, true);
        }

        function defaultCurrency(){
            $scope.$watch('vm.product', function(newval, oldval){
                if(null != vm.product && null!= vm.product.pointOfSale && null != vm.product.pointOfSale.currencies && vm.product.pointOfSale.currencies.length > 0){
                    vm.currency = vm.product.pointOfSale.currencies[0];
                }
                if(vm.product.pointOfSale.defaultTax!=null){
                    vm.tax = vm.product.pointOfSale.defaultTax;
                    vm.taxIncluded=true;
                }
            }, true);
        }


        //to preselect default currency
        $scope.$watch('vm.product.id', function() {
            if(null != vm.product.id){
                setOriginalProductQuantity();
                defaultSupplier();
                defaultLocation();
                defaultCurrency();
            }

        });


        function defaultLocation(){
            $scope.$watch('vm.locations', function(newval, oldval){
                var keepGoing = true;
                angular.forEach(vm.locations, function (location) {
                    if(keepGoing) {
                        if (vm.product.location != null && location.id == vm.product.location.id) {
                            vm.selectedLocation = location;
                            keepGoing = false;
                        } else if (location.priority == 0) {
                            vm.selectedLocation = location;
                        }
                    }
                });
            }, true);
        }

        vm.isQuantityNotChanged = function(){
            if(originalQuantity!=vm.product.quantity){
                return false;
            }else{
                return true;
            }
        }

        var onSaveSuccess = function (result) {
            $scope.$emit('wamaApp:productUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;

            if(null != vm.price){
                var priceData = {
                    "currency" : vm.currency,
                    "price" : vm.price,
                    "tax" : vm.tax,
                    "taxIncluded" : vm.taxIncluded
                };
            }else{
                var priceData = null;
            }

            var bestBeforeDate = null;
            if((vm.bestBeforeDateIncrement) || (vm.bestBeforeDateDecrement && vm.bestBeforeDateDecrement.bestBeforeDate)){
                bestBeforeDate = (vm.product.quantity-originalQuantity) > 0 ? vm.bestBeforeDateIncrement : vm.bestBeforeDateDecrement.bestBeforeDate;
            }

            var stockEntryData = {
                "product" : vm.product,
                "quantity" : (vm.product.quantity-originalQuantity),
                "supplier" : vm.selectedSupplier,
                "location" : vm.selectedLocation,
                "note" : vm.note,
                "reason": vm.reason,
                "bestBeforeDate": bestBeforeDate,
                "price" : priceData,
                "lotNumber": vm.lotNumber
            };
            if (!isNaN(stockEntryData.quantity) && stockEntryData.product){
                StockEntry.save(stockEntryData, onSaveSuccess, onSaveError);
            }

        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.timeCreated = false;
        vm.datePickerOpenStatus.timeModified = false;
        vm.datePickerOpenStatus.bestBeforeDate = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.increaseQuantity = function (event) {
            event.preventDefault();
            if(vm.product.quantity>=0){
                vm.product.quantity++;
            }else{
                vm.product.quantity = originalQuantity+1;
            }
            // copying values from last used one
            if(vm.quantityChangeWithSign >= 0){
                if(!vm.price && vm.product.lastPurchasePrice){
                    vm.price = vm.product.lastPurchasePrice.price;
                    vm.tax = vm.product.lastPurchasePrice.tax;
                    vm.taxIncluded = vm.product.lastPurchasePrice.taxIncluded;
                }
            }
        };

        vm.decreaseQuantity = function (event) {
            event.preventDefault();
            if(vm.product.quantity > 0){
                vm.product.quantity--;
            }
            if(vm.quantityChangeWithSign <= 0){
                if(!vm.price && vm.product.prices!=null && vm.product.prices.length > 0){
                    vm.price = vm.product.prices[0].price;
                    vm.tax = vm.product.prices[0].tax;
                    vm.taxIncluded = vm.product.prices[0].taxIncluded;
                }
            }
        };

        $scope.$watch('vm.product.quantity', function(newVal, oldVal) {
            vm.quantityChange = newVal-originalQuantity;
            vm.quantityChangeWithSign = newVal-originalQuantity;
            vm.expiryDates = null;
            vm.lotNumbers = null;
            if(vm.quantityChange>0){
                vm.quantityChangeText = 'wamaApp.product.quantityChange.increase';
                vm.suppliers = allSuppliers;
                vm.locations = allLocations;
                vm.lotnumbers = allLotNumbers;
            }else if(newVal === null || angular.isUndefined(newVal)){
                vm.quantityChangeText = 'wamaApp.product.quantityChange.notvalid';
                vm.quantityChange = null;
            }else if(vm.quantityChange==0 || isNaN(vm.quantityChange)){
                vm.quantityChange = null;
                vm.quantityChangeText = 'wamaApp.product.quantityChange.nochange';
            }else if(vm.quantityChange<0){
                vm.quantityChange = Math.abs(vm.quantityChange);
                vm.quantityChangeText = 'wamaApp.product.quantityChange.decrease';
                vm.suppliers = vm.product.suppliers;
                vm.locations =  vm.product.locations;
                vm.expiryDates = vm.product.expiryDates;
                vm.lotnumbers = vm.product.lotNumbers;
            }


            if(newVal-originalQuantity > 0){
                priceLabelShow(true, false);
                vm.priceplaceholder = "wamaApp.stockEntry.buyPricePlaceholder";
            }else{
                priceLabelShow(false, true);
                vm.priceplaceholder = "wamaApp.stockEntry.defaultSellingPrice";
            }
        });

        function priceLabelShow(buyPrice, sellingPrice){
            vm.labelBuyPriceShow = buyPrice;
            vm.labelSellingPriceShow = sellingPrice;
        }


        //********* CREATE REASON ***************
        vm.saveNewReason = function(){
            vm.isSaving = true;
            var code = vm.reasonName.replace(/\s/g, '').toUpperCase();
            var reason = {id:null, name: vm.reasonName, code: code};
            StockEntryReason.save(reason, onSaveReasonSuccess, onSaveError);
            function onSaveReasonSuccess(reason) {
                vm.createNewReasonVisible = false;
                if(vm.reasons == null) {
                    vm.reasons = Array();
                }
                vm.reasons.unshift(reason);
                vm.reason = reason;
            }
        };
        //********* CREATE LOT NUMBER ***************
        vm.saveNewLotNumber = function(){
            vm.isSaving = true;
            var lotNumber = {id:null, name: vm.lotNumberName};
            LotNumber.save(lotNumber, onSaveReasonSuccess, onSaveError);
            function onSaveReasonSuccess(lotNumber) {
                vm.isSaving = false;
                vm.createLotNumberVisible = false;
                if(vm.lotnumbers == null) {
                    vm.lotnumbers = Array();
                }
                vm.lotnumbers.unshift(lotNumber);
                vm.lotNumber = lotNumber;
            }
        };

        vm.formatProductQuantity = function(item){
            if(item.productQuantity!=null){
                return (item.name + ' (' + item.productQuantity +')')
            }
            return item.name;
        };


        //********* CREATE LOCATION ***************
        vm.saveNewLocation = function(){
            vm.isSaving = true;
            var location = {id:null, name: vm.locationName};
            Location.save(location, onSaveLocationSuccess, onSaveError);
            function onSaveLocationSuccess(location) {
                vm.isSaving = false;
                vm.createNewLocationVisible = false;
                if(vm.locations == null) {
                    vm.locations = Array();
                }
                vm.locations.unshift(location);
                vm.product.location = location;
            }
        };
        //********* CREATE SUPPLIER ***************
        vm.saveNewSupplier = function(){
            vm.isSaving = true;
            var supplier = {id:null, name: vm.supplierName};
            Supplier.save(supplier, onSaveSupplierSuccess, onSaveError);
            function onSaveSupplierSuccess(supplier) {
                vm.isSaving = false;
                vm.createNewSupplierVisible = false;
                if(vm.suppliers == null) {
                    vm.suppliers = Array();
                }
                vm.suppliers.unshift(supplier);
                vm.product.supplier = supplier;
            }
        };

        vm.currencyHelpers = currencyHelpers;
        vm.taxHelpers = taxHelpers;
        vm.priceCalculationHelpers = priceCalculationHelpers;

        vm.hasAccessRights = Auth.hasAccessRights;
    }
})();
