(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('StockTransferController', StockTransferController);

    StockTransferController.$inject = ['$scope', '$state', 'StockTransfer', 'StockTransferSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'Auth',
        '$http', '$filter'];

    function StockTransferController ($scope, $state, StockTransfer, StockTransferSearch, ParseLinks, AlertService, pagingParams, paginationConstants, Auth, $http, $filter) {
        var vm = this;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.clear = clear;
        vm.search = search;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.loadAll();

        function loadAll () {
            if (pagingParams.search) {
                StockTransferSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: paginationConstants.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                StockTransfer.query({
                    page: pagingParams.page - 1,
                    size: paginationConstants.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.stockTransfers = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        vm.stockTransferStatusColor = stockTransferStatusColor;
        vm.hasAccessRights = Auth.hasAccessRights;

        vm.countSelected = function() {
            var count = 0;
            for (var key in vm.selected) {
                if(vm.selected[key] === true) {
                    count++
                }
            }
            return count;
            // return _.size(vm.selected);
        }
        vm.downloadAll = function (){
            angular.forEach(vm.selected, function (value, id){
                if(value) {
                    angular.forEach(vm.stockTransfers, function (stockTransfer) {
                        if (stockTransfer.id == id) {
                            downloadStockTransfer(stockTransfer);
                        }
                    })
                }
            })
        }

        function downloadStockTransfer (stockTransfer) {
            $http.get('/api/stock-transfers/'+ stockTransfer.id +'/pdf', {responseType: 'arraybuffer'})
                .success(function (data) {
                    var blob = new Blob([data], {
                        type: 'application/pdf'
                    });
                    var currentDate = $filter('date')(new Date(), "yyyy-MM-dd_HH:mm:ss");
                    var filename = "DDT_" + (stockTransfer.code ? stockTransfer.code : stockTransfer.id) + "-"  + currentDate + ".pdf";
                    saveAs(blob, filename);
                    vm.isSaving = false;
                }).error(function () {
                vm.isSaving = false;
            });
        }
    }
})();
