(function() {
    'use strict';

    angular
        .module('wamaApp')
        .factory('stateHandler', stateHandler);

    stateHandler.$inject = ['$rootScope', '$state', '$sessionStorage', '$translate', 'JhiLanguageService', 'translationHandler', '$window',
        'Auth', 'Principal', 'VERSION'];

    function stateHandler($rootScope, $state, $sessionStorage, $translate, JhiLanguageService, translationHandler, $window,
        Auth, Principal, VERSION) {
        return {
            initialize: initialize
        };

        function initialize() {
            $rootScope.VERSION = VERSION;

            var stateChangeStart = $rootScope.$on('$stateChangeStart', function (event, toState, toStateParams, fromState) {
                $rootScope.toState = toState;
                $rootScope.toStateParams = toStateParams;
                $rootScope.fromState = fromState;

                // Redirect to a state with an external URL (http://stackoverflow.com/a/30221248/1098564)
                if (toState.external) {
                    event.preventDefault();
                    $window.open(toState.url, '_self');
                }

                if (Principal.isIdentityResolved()) {
                    Auth.authorize();
                }


                // Update the language
                JhiLanguageService.getCurrent().then(function (language) {
                    $translate.use(language);
                });

            });

            var stateChangeSuccess = $rootScope.$on('$stateChangeSuccess',  function(event, toState, toParams, fromState, fromParams) {
                // var titleKey = 'global.title' ;
                var host = location.hostname.replace("www","").replace(/^./, '').split(".")[0];
                var titleKey = 'global.' + host + '.title'
                // Set the page title key to the one configured in state or use default one
                if (toState.data.pageTitle) {
                    titleKey = toState.data.pageTitle;
                }
                translationHandler.updateTitle(titleKey);
                $rootScope.pageType = toState.data.pageType;

                //this is to display the user name in the top right of the navbar
                Principal.identity().then(function(account) {
                    if(account){
                        $rootScope.USERNAME = account.login;
                        $rootScope.FIRSTNAME = account.firstName;
                        $rootScope.USER_ID = account.id;
                        $rootScope.LASTNAME = account.lastName;
                        $rootScope.PHOTO = account.photo;
                        $rootScope.AUTHORITIES = account.authorities;
                    }
                });
                //end

            });

            $rootScope.$on('$destroy', function () {
                if(angular.isDefined(stateChangeStart) && stateChangeStart !== null){
                    stateChangeStart();
                }
                if(angular.isDefined(stateChangeSuccess) && stateChangeSuccess !== null){
                    stateChangeSuccess();
                }
            });
        }
    }
})();
