'use strict';

angular.module('wamaApp')
    .controller('StockEntryProductController', function ($scope, $rootScope, $stateParams, entity, StockEntryProduct, ParseLinks, Product, Tax) {
        //$scope.stockEntrys = entity;  //Fondamentale!!! Non dimenticare questo serve a caricare i dati dallo state definito in product.js
        //$scope.product = Product.get({id: $stateParams.id});
        $scope.page = 0;
        $scope.loadAll = function(id) {
            var page;
            if($scope.page==0){
                page=$stateParams.page;
            }else{
                page=$scope.page;
            }
            //Product.query({page: $scope.page - 1, size: 20, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']}, function(result, headers) {
            StockEntryProduct.query({id : $stateParams.id, page: page - 1 , size: 20, sort: [($scope.predicate ? $scope.predicate : 'timeCreated') + ',' + ($scope.reverse ? 'asc' : 'desc')]}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalItems = headers('X-Total-Count');
                $scope.stockEntrys = result;
            });
        };
        $scope.loadAll($stateParams.id);

        $rootScope.$on('wamaApp:stockEntryUpdate', function(event, result) {
            $scope.stockEntry = result;
        });


    });
