(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('ProductSupplierDetailController', ProductSupplierDetailController);

    ProductSupplierDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'ProductSupplier', 'Price', 'Product', 'Supplier'];

    function ProductSupplierDetailController($scope, $rootScope, $stateParams, entity, ProductSupplier, Price, Product, Supplier) {
        var vm = this;
        vm.productSupplier = entity;
        
        var unsubscribe = $rootScope.$on('wamaApp:productSupplierUpdate', function(event, result) {
            vm.productSupplier = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
