(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('ShopifyShopDeleteController',ShopifyShopDeleteController);

    ShopifyShopDeleteController.$inject = ['$uibModalInstance'];

    function ShopifyShopDeleteController($uibModalInstance) {
        var vm = this;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
