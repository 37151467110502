(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('PointOfSaleDetailController', PointOfSaleDetailController);

    PointOfSaleDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'PointOfSale', 'Company', 'Address', 'PaymentMethod', 'User', 'Currency', 'Timezone'];

    function PointOfSaleDetailController($scope, $rootScope, $stateParams, entity, PointOfSale, Company, Address, PaymentMethod, User, Currency, Timezone) {
        var vm = this;
        vm.pointOfSale = entity;
        
        var unsubscribe = $rootScope.$on('wamaApp:pointOfSaleUpdate', function(event, result) {
            vm.pointOfSale = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
