(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('SubscriptionDialogController', SubscriptionDialogController);

    SubscriptionDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Subscription', 'Company', 'Currency', 'Plan', 'LimitSubscription'];

    function SubscriptionDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Subscription, Company, Currency, Plan, LimitSubscription) {
        var vm = this;
        vm.subscription = entity;
        vm.companies = Company.query();
        vm.currencies = Currency.query();
        vm.plans = Plan.query();
        vm.limitsubscriptions = LimitSubscription.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('wamaApp:subscriptionUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.subscription.id !== null) {
                Subscription.update(vm.subscription, onSaveSuccess, onSaveError);
            } else {
                Subscription.save(vm.subscription, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.timePayment = false;
        vm.datePickerOpenStatus.validityStartTime = false;
        vm.datePickerOpenStatus.validityExpireTime = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
    }
})();
