(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('StockTransferReasonDetailController', StockTransferReasonDetailController);

    StockTransferReasonDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'StockTransferReason', 'PointOfSale'];

    function StockTransferReasonDetailController($scope, $rootScope, $stateParams, entity, StockTransferReason, PointOfSale) {
        var vm = this;
        vm.stockTransferReason = entity;
        
        var unsubscribe = $rootScope.$on('wamaApp:stockTransferReasonUpdate', function(event, result) {
            vm.stockTransferReason = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
