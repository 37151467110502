(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('PlanDialogController', PlanDialogController);

    PlanDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Plan', 'PricePlan','Tax', 'Currency', 'PointOfSale'];

    function PlanDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Plan, PricePlan, Tax, Currency, PointOfSale) {
        var vm = this;
        vm.plan = entity;
        vm.plan.pricePlans=[];
        vm.taxes = Tax.query();
        vm.currencies = Currency.query();
        vm.pointOfSale = PointOfSale.get({id: 0});

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('wamaApp:planUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.plan.id !== null) {
                Plan.update(vm.plan, onSaveSuccess, onSaveError);
            } else {
                Plan.save(vm.plan, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.taxHelpers = taxHelpers;
        vm.currencyHelpers = currencyHelpers;
    }
})();
