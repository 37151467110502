(function() {
    'use strict';

    angular
        .module('wamaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('sale-order', {
            parent: 'entity',
            url: '/sale-order?page&sort&search',
            data: {
                authorities: ['ROLE_USER', 'ROLE_OPERATOR', 'ROLE_SALES_OPERATOR'],
                pageTitle: 'wamaApp.saleOrder.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/sale-order/sale-orders.html',
                    controller: 'SaleOrderController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('saleOrder');
                    $translatePartialLoader.addPart('paymentMethod');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('sale-order-detail', {
            parent: 'entity',
            url: '/sale-order/{id}',
            data: {
                authorities: ['ROLE_USER', 'ROLE_OPERATOR', 'ROLE_SALES_OPERATOR'],
                pageTitle: 'wamaApp.saleOrder.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/sale-order/sale-order-detail.html',
                    controller: 'SaleOrderDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('pos');
                    $translatePartialLoader.addPart('saleOrder');
                    $translatePartialLoader.addPart('saleOrderEntry');
                    $translatePartialLoader.addPart('priceList');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'SaleOrder', function($stateParams, SaleOrder) {
                    return SaleOrder.get({id : $stateParams.id});
                }]
            }
        })
        .state('sale-order.new', {
            parent: 'sale-order',
            url: '/new',
            data: {
                authorities: ['ROLE_USER', 'ROLE_OPERATOR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/sale-order/sale-order-dialog.html',
                    controller: 'SaleOrderDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                code: null,
                                subtotal: null,
                                discount: null,
                                discountPercentage: null,
                                total: null,
                                tips: null,
                                dateIssue: null,
                                companyTaxNumber: null,
                                refunded: null,
                                refundMessage: null,
                                notes: null,
                                taxValue: null,
                                createdBy: null,
                                createdDate: null,
                                lastModifiedBy: null,
                                lastModifiedDate: null,
                                discountPercentageValue: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('sale-order', null, { reload: true });
                }, function() {
                    $state.go('sale-order');
                });
            }]
        })
        .state('sale-order.edit', {
            parent: 'sale-order',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/sale-order/sale-order-dialog.html',
                    controller: 'SaleOrderDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['SaleOrder', function(SaleOrder) {
                            return SaleOrder.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('sale-order', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('sale-order-detail.delete', {
            parent: 'sale-order-detail',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/sale-order/sale-order-delete-dialog.html',
                    controller: 'SaleOrderDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['SaleOrder', function(SaleOrder) {
                            return SaleOrder.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('sale-order', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('sale-order-detail.status', {
            parent: 'sale-order-detail',
            url: '/status/{status}',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_USER','ROLE_OPERATOR', 'ROLE_SALES_OPERATOR'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/sale-order/sale-order-dialog-status.html',
                    controller: 'SaleOrderDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: ['SaleOrder', function(SaleOrder) {
                            return SaleOrder.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('sale-order-detail', {id: $stateParams.id}, { reload: true, notify: true });
                }, function() {
                    $state.go('sale-order-detail');
                });
            }]
        })
        .state('sale-order-detail.email', {
            parent: 'sale-order-detail',
            url: '/email',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_USER','ROLE_OPERATOR', 'ROLE_SALES_OPERATOR'],
            },
            params: {
                order: null
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/sale-order/sale-order-dialog-email.html',
                    controller: 'PosCheckoutDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md'
                }).result.then(function() {
                    $state.go('sale-order-detail', {id: $stateParams.id}, { reload: true, notify: true });
                }, function() {
                    $state.go('sale-order-detail');
                });
            }]
        });
    }

})();
