(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('BarcodeController', BarcodeController);

    BarcodeController.$inject = ['$scope', '$state', '$filter', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'StockEntry', '$http', 'Supplier', 'Location',
        'Tax', 'Currency', 'PointOfSale', 'ProductSearch', 'ToastAlertService', '$translate', 'LotNumber', 'CompanyPreferences'];

    function BarcodeController ($scope, $state, $filter, ParseLinks, AlertService, pagingParams, paginationConstants, StockEntry, $http, Supplier, Location, Tax, Currency,
                                PointOfSale, ProductSearch, ToastAlertService, $translate, LotNumber, CompanyPreferences) {
        var vm = this;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.clear = clear;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        //custom
        vm.loadStockByBarcode = loadStockByBarcode;
        vm.exportData = exportData;
        vm.exportAllData = exportAllData;
        vm.currencyHelpers = currencyHelpers;
        var allSuppliers = Supplier.query(function() {
            vm.selectSuppliersComplete = true;    //la directive select-picker e' in ascolto per lo stesso evento, bisogna mandarne uno solo
        });
        vm.suppliers = allSuppliers;
        var allLocations = Location.query(function() {
            vm.selectLocationsComplete = true;
        });
        vm.locations = allLocations;

        vm.taxes = Tax.query();
        vm.currencies = Currency.query();
        vm.pointOfSale = PointOfSale.get({id: 0});
        vm.companyPreferences = CompanyPreferences.getPreferencesFromLocalStorage();

        var allLotNumbers = LotNumber.query({closed:false, page: 0, size: 99999, sort: 'name'});
        vm.lotnumbers = allLotNumbers;

        // defaultSupplier();
        // defaultLocation();
        defaultCurrency();

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }



        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        //****************** wama custom functions **************

        function defaultSupplier(){
            $scope.$watch('vm.suppliers', function(newval, oldval){
                angular.forEach(vm.suppliers, function (supplier) {
                    if(supplier.priority == 0){
                        vm.selectedSupplier = supplier;
                    }
                });
            }, true);
        }

        function defaultCurrency(){
            $scope.$watch('vm.pointOfSale', function(newval, oldval){
                if(null != vm.pointOfSale && null != vm.pointOfSale.currencies && vm.pointOfSale.currencies.length > 0){
                    vm.currency = vm.pointOfSale.currencies[0];
                }
                if(null!= vm.pointOfSale){
                    vm.tax = vm.pointOfSale.defaultTax;
                    if(vm.pointOfSale.defaultTax!=null){
                        vm.taxIncluded=true;
                    }
                }
            }, true);
        }

        function defaultLocation(){
            $scope.$watch('vm.locations', function(newval, oldval){
                angular.forEach(vm.locations, function (location) {
                    if(location.priority == 0){
                        vm.selectedLocation = location;
                    }
                });
            }, true);
        }


        function loadStockByBarcode(){
            vm.isSaving = true;
            vm.errornegativequantity = null;
            vm.supplierNotAllowed = null;
            vm.locationNotAllowed = null;
            vm.error = null;
            var pointOfSale = {
                "id": 0,
                "name": "notSupportedSetOfPOS"
            };

            if(null != vm.price){
                var priceData = {
                    "currency" : vm.currency,
                    "price" : vm.price,
                    "tax" : vm.tax,
                    "taxIncluded" : vm.taxIncluded
                };
            }else{
                var priceData = null;
            }

            var productData = {
                "barcode" : vm.loadByBarcode.barcode,
                "quantity" : vm.loadByBarcode.quantity,
                "pointOfSale": pointOfSale
            };
            var stockEntryData = {
                "product" : productData,
                "quantity" : vm.loadByBarcode.quantity,
                "supplier" : vm.selectedSupplier,
                "location" : vm.selectedLocation,
                "lotNumber": vm.lotNumber,
                "price" : priceData
            };
            if (!isNaN(productData.quantity) && productData.barcode && vm.loadChecked){
                StockEntry.save(stockEntryData, onLoadByBarcodeSuccess, onLoadByBarcodeError);
            }
            if(!vm.loadChecked){
                search(productData.barcode);
            }
            vm.loadByBarcode.barcode=null;

            function onLoadByBarcodeSuccess (result) {
                if(!vm.startedLoadByBarcode){
                    vm.products=[]
                }
                vm.startedLoadByBarcode=true;

                if(vm.products.length==0){
                    vm.products.unshift(result.product);
                }else{
                    angular.forEach(vm.products, function(u, i) {
                        if (u.id === result.product.id ) {
                            vm.products.splice(i, 1);
                        }
                    });
                        vm.products.unshift(result.product);
                }
                vm.modifiedProductId = result.product.id;
                vm.isSaving = false;
            };

            function onLoadByBarcodeError (error){
                vm.isSaving = false;
                if (error.status === 400) {
                    if(error.data.message === 'error.negative.quantity.product'){
                        vm.errornegativequantity = 'error.negative.quantity.product';
                    }else if(error.data.params[0] !=='undefined' && error.data.params[0] !==null && error.data.params[0] === 'error.stockEntry.location.notAllowed'){
                        vm.locationNotAllowed = 'error.stockEntry.location.notAllowed';
                    }else if(error.data.params[0] !=='undefined' && error.data.params[0] !==null && error.data.params[0] === 'error.stockEntry.supplier.notAllowed'){
                        vm.supplierNotAllowed = 'error.stockEntry.supplier.notAllowed';
                    }
                }else{
                    vm.error = 'error.stockEntry.generic';
                }
                ToastAlertService.show({type: ToastAlertService.error, msg: $translate.instant(error.data.message)});
            }
        }

        function exportData() {
            var blob = new Blob([document.getElementById('exportable').innerHTML], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
            });
            saveAs(blob, "Export-scanned-data.xls");
        };

        function exportAllData(){
            $http.get('/api/products/export', {responseType: 'arraybuffer'})
                .success(function (data) {
                    var blob = new Blob([data], {
                        type: '"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"'
                    });
                    var currentDate = $filter('date')(new Date(), "yyyy-MM-dd_HH:mm:ss");
                    var filename = "Export_" + currentDate + ".xlsx";
                    saveAs(blob, filename);
                });
        }
        vm.loadByBarcode = {};
        $scope.$watch('vm.loadByBarcode.quantity', function(newVal, oldVal) {
            if(typeof newVal === 'undefined'){
                vm.loadByBarcode.quantity = -1;
            }
            if(newVal== 0 && oldVal==1) {
                vm.loadByBarcode.quantity = -1;
            }else if(newVal == 0 && oldVal==-1){
                vm.loadByBarcode.quantity = +1;
            }else if(newVal == 0){
                vm.loadByBarcode.quantity = oldVal;
            }

            if(newVal > 0){
                priceLabelShow(true, false);
                vm.priceplaceholder = "wamaApp.stockEntry.buyPricePlaceholder";
            }else{
                priceLabelShow(false, true);
                vm.priceplaceholder = "wamaApp.stockEntry.defaultSellingPrice";
            }
        });


        function priceLabelShow(buyPrice, sellingPrice){
            vm.labelBuyPriceShow = buyPrice;
            vm.labelSellingPriceShow = sellingPrice;
        }

        vm.taxHelpers = taxHelpers;
        vm.currencyHelpers = currencyHelpers;

        function search(searchQuery){
            if (searchQuery) {
                ProductSearch.query({
                    query: searchQuery,
                    page: 0,
                    size: paginationConstants.itemsPerPage
                }, onSuccess, onError);
            }
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.products = data;
            vm.page = pagingParams.page;
            vm.startedLoadByBarcode = false;
        }
        function onError(error) {
            AlertService.error(error.data.message);
        }


    }
})();
