(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('LimitSubscriptionDeleteController',LimitSubscriptionDeleteController);

    LimitSubscriptionDeleteController.$inject = ['$uibModalInstance', 'entity', 'LimitSubscription'];

    function LimitSubscriptionDeleteController($uibModalInstance, entity, LimitSubscription) {
        var vm = this;
        vm.limitSubscription = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            LimitSubscription.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
