(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('StockTransferReasonDialogController', StockTransferReasonDialogController);

    StockTransferReasonDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'StockTransferReason', 'PointOfSale'];

    function StockTransferReasonDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, StockTransferReason, PointOfSale) {
        var vm = this;
        vm.stockTransferReason = entity;
        vm.pointofsales = PointOfSale.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('wamaApp:stockTransferReasonUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.stockTransferReason.id !== null) {
                StockTransferReason.update(vm.stockTransferReason, onSaveSuccess, onSaveError);
            } else {
                StockTransferReason.save(vm.stockTransferReason, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
