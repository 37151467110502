(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('ProductDetailController', ProductDetailController)
        .config(function($mdDateLocaleProvider,$injectorProvider) {
            $mdDateLocaleProvider.formatDate = function (date) {
                var $filter = $injectorProvider.$get().get('$filter');
                return $filter('date')(date, 'dd MMM yyyy');
            };
        });

    ProductDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Product', 'PointOfSale', 'Category', 'Price', 'pagingParams', 'paginationConstants',
        'Location', 'ParseLinks', 'StockEntryProduct', 'Tax', 'PointOfSaleStatistics', 'Image', 'AlertService', 'Auth', 'previousState', 'CompanyPreferences','ToastAlertService',
        '$translate', '$localStorage'];

    function ProductDetailController($scope, $rootScope, $stateParams, entity, Product, PointOfSale, Category, Price, pagingParams, paginationConstants, Location, ParseLinks,
                                     StockEntryProduct, Tax, PointOfSaleStatistics, Image, AlertService, Auth, previousState, CompanyPreferences, ToastAlertService, $translate,
                                     $localStorage) {
        var vm = this;
        vm.product = entity;
        vm.now = new Date().toISOString();
        vm.previousState = previousState.name;
        vm.suppliers = vm.product.suppliers;
        vm.locations = vm.product.locations;
        vm.expiryDates = vm.product.expiryDates;
        vm.lotNumbers = vm.product.lotNumbers;
        replaceDisallowedPreviousStates();

        var unsubscribe = $rootScope.$on('wamaApp:productUpdate', function(event, result) {
            vm.product = result;
        });
        $scope.$on('$destroy', unsubscribe);
        vm.companyPreferences = CompanyPreferences.getPreferencesFromLocalStorage();

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.descending;
        vm.getStockEntries = getStockEntries;
        vm.getStockEntriesStats = getStockEntriesStats;
        getStockEntries();
        getStockEntriesStats();


        function replaceDisallowedPreviousStates() {
            if(previousState.name == "product-detail.edit"){
                vm.previousState = "product";
            }
        }

        function getStockEntriesStats() {
            var productId = $stateParams.id;
            if(!vm.endDate){
                vm.endDate = new Date();
            }
            if(!vm.startDate){
                vm.startDate = new Date();
                vm.startDate.setMonth(vm.endDate.getMonth() - 3);
            }
            if(productId>0){
                PointOfSaleStatistics.get({
                    query: "stock-entries",
                    product: productId,
                    startDate: vm.startDate,
                    endDate: vm.endDate},
                    onSuccessStockEntriesStats, onError);
            }
        }

        function onSuccessStockEntriesStats(data, headers) {
            vm.startDate = new Date(headers('startDate'));
            vm.endDate = new Date(headers('endDate'));

            var stockWarehouse = [];
            angular.forEach(data["stockEntriesTrendWarehouse"], function(value, key){
                var entry = {
                    x: new Date(key),
                    y: value
                };
                stockWarehouse.push(entry);
            });
            $scope.fetching=false;

            vm.stockWarehouseData = [{
                key: entity.name,
                values: stockWarehouse
            }];
            vm.totalStockIn = decodeURIComponent(headers('totalStockIn'));
            vm.totalStockOut = decodeURIComponent(headers('totalStockOut'));
            vm.totalPriceIn = decodeURIComponent(headers('totalPriceIn'));
            vm.totalPriceOut = decodeURIComponent(headers('totalPriceOut'));
            vm.totalPriceInAvg = decodeURIComponent(headers('totalPriceInAvg'));
            vm.totalPriceOutAvg = decodeURIComponent(headers('totalPriceOutAvg'));
            vm.highestBuyPrice = decodeURIComponent(headers('highestBuyPrice'));
            vm.lowestBuyPrice = decodeURIComponent(headers('lowestBuyPrice'));
            vm.highestSellPrice = decodeURIComponent(headers('highestSellPrice'));
            vm.lowestSellPrice = decodeURIComponent(headers('lowestSellPrice'));
        }

        function getStockEntries(){
            StockEntryProduct.query({
                id: $stateParams.id,
                page: vm.stockEntriesPage - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort()
            }, onSuccessStockEntries, onError);
        };

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        };

        function onSuccessStockEntries(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.stockEntriesTotalItems = headers('X-Total-Count');
            vm.stockEntriesQueryCount = vm.stockEntriesTotalItems;
            vm.stockEntries = data;
            if(!vm.stockEntriesPage){
                vm.stockEntriesPage = pagingParams.page;
            }
            vm.startedLoadByBarcode = false;
        };

        function onError(error) {
            ToastAlertService.show({type: ToastAlertService.error, msg: $translate.instant(error.data.message)});
        };

        vm.lineChartOptions = {
            chart: {
                type: 'lineChart',
                height: 250,
                width: 500,
                margin : {
                    top: 10,
                    right: 20,
                    bottom: 20,
                    left: 20
                },
                x: function(d){return d.x;},
                y: function(d){return d.y;},
                useInteractiveGuideline: true,
                valueFormat: function(d){
                    return d3.format(',.0f')(d);
                },
                duration: 500,
                xAxis: {
                    //axisLabel: 'X Axis'
                    tickFormat: function(d) {
                        return d3.time.format('%B %e')(new Date(d));
                    }
                },
                yAxis: {
                    //axisLabel: 'Y Axis',
                    axisLabelDistance: -10
                },
                showLegend: false
            }
        };

        vm.openModalImage = Image.openModalImage;

        vm.seColumnVisibility = {
            "quantity": true,
            "price": true,
            "totalPrice": true,
            "location": true,
            "supplier": true,
            "reason":true,
            "saleOrder":true,
            "stockTransfer":true,
            "purchaseOrder":true,
            "bestBeforeDate":true,
            "lotNumbers":true,
            "note": false,
            "createdDate": true,
            "createdBy": true,
            "lastModifiedDate": false,
            "lastModifiedBy": false
        };

        function initLocalStoragePreferences () {
            if ($localStorage.seColumnVisibility != null) {
                vm.seColumnVisibility = $localStorage.seColumnVisibility;
            } else {
                $localStorage.seColumnVisibility = vm.seColumnVisibility;
            }
        }

        initLocalStoragePreferences();

        vm.selectedIndex = localStorage.getItem("selectedIndex");
        localStorage.removeItem("selectedIndex");

        vm.taxHelpers = taxHelpers;
        vm.currencyHelpers = currencyHelpers;
        vm.hasAccessRights = Auth.hasAccessRights;
    }
})();
