(function () {
    'use strict';

    angular
        .module('wamaApp')
        .controller('ProductOtherPosDialogController', ProductOtherPosDialogController);

    ProductOtherPosDialogController.$inject = ['$stateParams', 'entity', '$uibModalInstance'];

    function ProductOtherPosDialogController($stateParams, entity, $uibModalInstance) {
        var vm = this;
        vm.product = entity;


        vm.clear = function () {
            $uibModalInstance.dismiss('cancel');
        };

        vm.totalQuantity = function () {
            if (vm.product && vm.product.otherPointOfSalesProducts) {
                // console.log(vm.product.otherPointOfSalesProducts)
                var total = 0;
                angular.forEach(vm.product.otherPointOfSalesProducts, function (product) {
                    total += product.quantity;
                });
                return total;
            } else {
                return 0;
            }
        }

    }
})();
