(function() {
    'use strict';

    angular
        .module('wamaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('stock-transfer', {
            parent: 'entity',
            url: '/stock-transfer?page&sort&search',
            data: {
                authorities: ['ROLE_USER','ROLE_OPERATOR'],
                pageTitle: 'wamaApp.stockTransfer.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/stock-transfer/stock-transfers.html',
                    controller: 'StockTransferController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('stockTransfer');
                    $translatePartialLoader.addPart('priority');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('stock-transfer-detail', {
            parent: 'entity',
            url: '/stock-transfer/{id}',
            data: {
                authorities: ['ROLE_USER','ROLE_OPERATOR'],
                pageTitle: 'wamaApp.stockTransfer.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/stock-transfer/stock-transfer-detail.html',
                    controller: 'StockTransferDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('stockTransfer');
                    $translatePartialLoader.addPart('priority');
                    $translatePartialLoader.addPart('stockTransferEntry');
                    $translatePartialLoader.addPart('address');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'StockTransfer', function($stateParams, StockTransfer) {
                    return StockTransfer.get({id : $stateParams.id});
                }]
            }
        })
        .state('stock-transfer-detail.status', {
            parent: 'stock-transfer-detail',
            url: '/status/{status}',
            data: {
                authorities: ['ROLE_USER','ROLE_OPERATOR'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/stock-transfer/stock-transfer-dialog-status-change.html',
                    controller: 'StockTransferDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) { }]
                    }

                }).result.then(function() {
                    $state.go('stock-transfer-detail', {id: $stateParams.id}, { reload: true, notify: true });
                }, function() {
                    $state.go('stock-transfer-detail');
                });
            }]
        })
        .state('stock-transfer-detail.delete', {
            parent: 'stock-transfer-detail',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/stock-transfer/stock-transfer-delete-dialog.html',
                    controller: 'StockTransferDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['StockTransfer', function(StockTransfer) {
                            return StockTransfer.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('stock-transfer', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('stock-transfer.new', {
            parent: 'stock-transfer',
            url: '/new',
            data: {
                authorities: ['ROLE_USER','ROLE_OPERATOR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/stock-transfer/stock-transfer-dialog.html',
                    controller: 'StockTransferDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                code: null,
                                documentDate: null,
                                shippingDate: null,
                                note: null,
                                priority: null,
                                weight: null,
                                volume: null,
                                numberOfParcels: null,
                                carrier: null,
                                carrierTrackingReference: null,
                                subtotal: null,
                                discount: null,
                                discountPercentage: null,
                                taxValue: null,
                                total: null,
                                createdBy: null,
                                createdDate: null,
                                lastModifiedBy: null,
                                lastModifiedDate: null,
                                id: null
                            };
                        },
                        pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) { }]
                    }
                }).result.then(function() {
                    $state.go('stock-transfer', null, { reload: true });
                }, function() {
                    $state.go('stock-transfer');
                });
            }]
        })
        .state('stock-transfer-edit', {
            parent: 'entity',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER','ROLE_OPERATOR']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/stock-transfer/stock-transfer-edit.html',
                    controller: 'StockTransferEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('stockTransfer');
                    $translatePartialLoader.addPart('priority');
                    $translatePartialLoader.addPart('stockTransferEntry');
                    $translatePartialLoader.addPart('address');
                    $translatePartialLoader.addPart('product');
                    $translatePartialLoader.addPart('stockTransferGoodsDescription');
                    $translatePartialLoader.addPart('stockTransferReason');
                    $translatePartialLoader.addPart('stockTransferMethod');
                    $translatePartialLoader.addPart('price');
                    $translatePartialLoader.addPart('customer');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'StockTransfer', function($stateParams, StockTransfer) {
                    if($stateParams.id){
                        return StockTransfer.get({id : $stateParams.id});
                    }
                    //this will be returned when creating a stockTransferc (id not available)
                    return {
                        code: null,
                        documentDate: null,
                        shippingDate: null,
                        note: null,
                        priority: null,
                        weight: null,
                        volume: null,
                        numberOfParcels: null,
                        carrier: null,
                        carrierTrackingReference: null,
                        createdBy: null,
                        createdDate: null,
                        lastModifiedBy: null,
                        lastModifiedDate: null,
                        id: null
                    };
                }]
            }
        })
        .state('stock-transfer-new', {
            parent: 'entity',
            url: '/new/{saleOrder}',
            data: {
                authorities: ['ROLE_USER','ROLE_OPERATOR']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/stock-transfer/stock-transfer-edit.html',
                    controller: 'StockTransferEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('stockTransfer');
                    $translatePartialLoader.addPart('priority');
                    $translatePartialLoader.addPart('stockTransferEntry');
                    $translatePartialLoader.addPart('address');
                    $translatePartialLoader.addPart('product');
                    $translatePartialLoader.addPart('stockTransferGoodsDescription');
                    $translatePartialLoader.addPart('stockTransferReason');
                    $translatePartialLoader.addPart('stockTransferMethod');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'StockTransfer', 'SaleOrder', function($stateParams, StockTransfer, SaleOrder) {
                    if($stateParams.saleOrder > 0){
                        var stockTransfer = {
                            code: null,
                            documentDate: null,
                            shippingDate: null,
                            note: null,
                            priority: null,
                            weight: null,
                            volume: null,
                            numberOfParcels: null,
                            carrier: null,
                            carrierTrackingReference: null,
                            createdBy: null,
                            createdDate: null,
                            lastModifiedBy: null,
                            lastModifiedDate: null,
                            id: null,
                            saleOrder: null,
                            entries: []
                        };
                        SaleOrder.get({id : $stateParams.saleOrder}, function (saleOrder) {
                            var newEntryId = 9999999;
                            angular.forEach(saleOrder.orderEntries, function (saleEntry) {
                                var entry = {
                                    product: saleEntry.product,
                                    productName: saleEntry.productName,
                                    productCode: saleEntry.code,
                                    basePrice: saleEntry.basePrice,
                                    totalPrice: saleEntry.totalPrice,
                                    taxValue: saleEntry.taxValue,
                                    taxPercentage: saleEntry.taxPercentage,
                                    taxIncluded: saleEntry.taxIncluded,
                                    discountValue: null,
                                    discountPercentage: null,
                                    quantity: saleEntry.quantity,
                                    quantityProcessed: null,
                                    notes: null,
                                    createdBy: null,
                                    createdDate: null,
                                    lastModifiedBy: null,
                                    lastModifiedDate: null,
                                    id: newEntryId + 1
                                };
                                stockTransfer.entries.push(entry);
                            });
                            stockTransfer.saleOrder = saleOrder;
                            return stockTransfer;
                        });
                        return stockTransfer;
                    }
                }]
            }
        })
        .state('stock-transfer-edit.product', {
            parent: 'stock-transfer-edit',
            url: '/source/{sourcePointOfSaleId}/destination/{destinationPointOfSaleId}/addedProducts/{addedProducts}',
            data: {
                authorities: ['ROLE_USER','ROLE_OPERATOR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/stock-transfer/stock-transfer-edit-product.html',
                    controller: 'StockTransferDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                            return {
                                page: 1,
                                sort: 'id,desc',
                                predicate: PaginationUtil.parsePredicate($stateParams.sort),
                                ascending: PaginationUtil.parseAscending($stateParams.sort),
                                search: $stateParams.search
                            };
                        }]
                    }
                }).result.then(function() {
                    $state.go('stock-transfer-edit', null, { reload: false, notify: true });
                }, function() {
                    $state.go('stock-transfer-edit');
                });
            }]
        })
        .state('stock-transfer-edit.goods-description-new', {
            parent: 'stock-transfer-edit',
            url: '/newGoodsDescription',
            data: {
                authorities: ['ROLE_USER','ROLE_OPERATOR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/stock-transfer-goods-description/stock-transfer-goods-description-dialog.html',
                    controller: 'StockTransferGoodsDescriptionDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                code: null,
                                name: null,
                                description: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('stock-transfer-edit', null, { reload: true });
                }, function() {
                    $state.go('stock-transfer-edit');
                });
            }]
        })
        .state('stock-transfer-edit.method-new', {
            parent: 'stock-transfer-edit',
            url: '/newMethod',
            data: {
                authorities: ['ROLE_USER','ROLE_OPERATOR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/stock-transfer-method/stock-transfer-method-dialog.html',
                    controller: 'StockTransferMethodDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                code: null,
                                name: null,
                                description: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('stock-transfer-edit', null, { reload: true });
                }, function() {
                    $state.go('stock-transfer-edit');
                });
            }]
        })
        .state('stock-transfer-edit.reason-new', {
            parent: 'stock-transfer-edit',
            url: '/newReason',
            data: {
                authorities: ['ROLE_USER','ROLE_OPERATOR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/stock-transfer-reason/stock-transfer-reason-dialog.html',
                    controller: 'StockTransferReasonDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                code: null,
                                name: null,
                                description: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('stock-transfer-edit', null, { reload: true });
                }, function() {
                    $state.go('stock-transfer-edit');
                });
            }]
        })
        .state('stock-transfer-edit.customer-new', {
            parent: 'stock-transfer-edit',
            url: '/newCustomer',
            data: {
                authorities: ['ROLE_USER','ROLE_OPERATOR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/customer/customer-dialog.html',
                    controller: 'CustomerDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                name: null,
                                surname: null,
                                timeCreated: null,
                                timeModified: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('stock-transfer-edit', null, { reload: true });
                }, function() {
                    $state.go('stock-transfer-edit');
                });
            }]
        })
        ;
    }

})();
