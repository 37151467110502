(function () {
    'use strict';

    angular
        .module('wamaApp')
        .controller('ProductDetailSupplierDialogController', ProductDetailSupplierDialogController);

    ProductDetailSupplierDialogController.$inject = ['$stateParams', 'entity', '$uibModalInstance', '$q', 'ProductSupplier', 'ParseLinks', 'pagingParams'];

    function ProductDetailSupplierDialogController($stateParams, entity, $uibModalInstance, $q, ProductSupplier, ParseLinks, pagingParams) {
        var vm = this;
        vm.product = entity;
        vm.currencyHelpers = currencyHelpers;

        entity.$promise.then(function (product) {
            loadProductSuppliers(product);
        });

        function loadProductSuppliers(product){
            ProductSupplier.query({productId: product.id, page: pagingParams.page - 1, size: vm.numItems, sort: 'timeModified,desc'},
                function (data, headers) {
                    vm.productSuppliers = data;
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                    vm.saleOrders = data;
                    vm.page = pagingParams.page;
                });
        }

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.calculateMargin = function(){
            if(vm.productSupplier) {
                return vm.productSupplier.product.prices[0].price - vm.productSupplier.price.price;
            }
        }

    }
})();
