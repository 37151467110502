(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('PriceDetailController', PriceDetailController);

    PriceDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Price', 'Product', 'PointOfSale', 'Tax', 'Currency'];

    function PriceDetailController($scope, $rootScope, $stateParams, entity, Price, Product, PointOfSale, Tax, Currency) {
        var vm = this;
        vm.price = entity;
        
        var unsubscribe = $rootScope.$on('wamaApp:priceUpdate', function(event, result) {
            vm.price = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
