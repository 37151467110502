(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('WarehouseController', WarehouseController)
        .filter('filterFromList', filterFromList);

    WarehouseController.$inject = ['$scope', '$state', 'PointOfSale', 'ParseLinks', 'AlertService', 'paginationConstants', 'Currency', 'Tax', 'Auth', 'Preferences',
        'ToastAlertService', 'CompanyPreferences'];

    function WarehouseController ($scope, $state, PointOfSale, ParseLinks, AlertService, paginationConstants, Currency, Tax, Auth, Preferences, ToastAlertService, CompanyPreferences) {
        var vm = this;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.loadAll();
        vm.currencies = Currency.query({page:0, size:9999});
        vm.taxHelpers = taxHelpers;
        vm.save = save;
        vm.locationsAllowNegativeQuantities = {};
        var properties = {
            'allowNegativeQuantities': vm.locationsAllowNegativeQuantities
        };
        var defaultPropertiesValue = {
            'allowNegativeQuantities': {'propertyType': 'BOOLEAN', 'section':'WAREHOUSE', 'nameOnPreferences': 'allowNegativeQuantities' }
        };

        angular.forEach(properties, function(value,key){
            value.property = key;
            value.propertyType = defaultPropertiesValue[key]['propertyType'];
            value.section = defaultPropertiesValue[key]['section'];
            value.nameOnPreferences = defaultPropertiesValue[key]['nameOnPreferences'];
        })

        Preferences.mapQuery({sections: ['WAREHOUSE']}, onSuccessPreferences, onErrorPreferences);

        function onSuccessPreferences(data) {
            angular.forEach(data, function (value, key) {
                if (properties[key]) {
                    //found property
                    properties[key].id = value.id;
                    properties[key].property = (value.property) ? value.property : key;
                    properties[key].section = (value.section) ? value.section : defaultPropertiesValue[key]['section'];
                    properties[key].propertyType = (value.propertyType) ? value.propertyType : defaultPropertiesValue[key]['propertyType'];
                    if (value.value != null && (value.value.toUpperCase() === "TRUE" ||
                        value.value.toUpperCase() === "FALSE")) {
                        properties[key].value = value.value.toUpperCase() === "TRUE";
                    } else {
                        properties[key].value = value.value;
                    }
                }
            });
        }

        function onErrorPreferences(error) {
            ToastAlertService.error(error.data.message);
        }

        function save(preference) {
            Preferences.update(preference,
                function (data) {
                    CompanyPreferences.savePreferenceToLocalStorage(data);
                    properties[data.property].id = data.id;
                },
                function () {
                    ToastAlertService.show({
                        type: ToastAlertService.error,
                        msg: 'wamaApp.preferences.modules.' + preference.nameOnPreferences + '.errorOnSave'
                    });
                });
        }

        function loadAll () {
            // PointOfSale.query({
            //     size: paginationConstants.itemsPerPage,
            // }, onSuccessPointOfSale, onError);

            vm.pointOfSale = PointOfSale.get({id: 0});

            Tax.query({
                size: 9999
            }, onSuccessTax, onError);

            // function onSuccessPointOfSale(data, headers) {
            //     vm.links = ParseLinks.parse(headers('link'));
            //     vm.totalItems = headers('X-Total-Count');
            //     vm.queryCount = vm.totalItems;
            //     vm.pointOfSales = data;
            // }

            function onSuccessTax(data, headers) {
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.taxes = data;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
        }


        vm.saveCurrency = function(pointOfSale,currencies){
            if (pointOfSale && currencies){
                angular.forEach(currencies, function(currency){
                    pointOfSale.currencies.push(currency);
                });
                PointOfSale.update(pointOfSale, onSaveSuccess, onSaveError);
            }else{
                AlertService.error("wamaApp.pointOfSale.error.update.currency");
            }
            vm.isSaving = false;

        };

        vm.saveDefaultTax = function (pointOfSale) {
            PointOfSale.update(pointOfSale, onSaveSuccess, onSaveError);
        };

        function onSaveSuccess (result) {
            vm.isSaving = false;
        };

        function onSaveError (result) {
            vm.isSaving = false;
        };

        vm.isLengthZero = function(elems){
            if (!elems || elems.length==0){
                return true;
            }
            return false;
        };


        var PointOfSaleHelpers = {
            'currencyWithCodeDisplay': function(currency) {
                var formatted="";
                if(currency){
                    if (currency.code){
                        formatted += currency.code;
                        if(currency.name){
                            formatted +=" - ";
                        }
                    }
                    if(currency.name){
                        formatted += currency.name;
                    }
                    if(currency.symbol){
                        formatted += " "+currency.symbol;
                    }
                }
                return formatted;
            }
        };

        vm.helpers = PointOfSaleHelpers;

        vm.hasAccessRights = Auth.hasAccessRights;

    }

    //filters
    function filterFromList () { //removes from 'items' the elements that are in 'alreadyAddedCurrencies'
        return function (items, alreadyAddedCurrencies) {
            var filtered = [];
            var present = false;


            angular.forEach(items, function (item) {
                angular.forEach(alreadyAddedCurrencies, function(addedCurrency){
                    if(addedCurrency.id==item.id){
                        present=true;
                    }
                });
                //if (alreadyAddedCurrencies.indexOf(item) == -1) {
                if (!present) {
                    filtered.push(item);
                }
                present=false;
            });
            return filtered;
        }
    }
})();
