(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('CompanyInfoController', CompanyController);

    CompanyController.$inject = ['$scope', '$state', 'Company', 'ParseLinks', 'AlertService', 'PointOfSale', 'Country', 'Auth', 'CompanyPreferences'];

    function CompanyController ($scope, $state, Company, ParseLinks, AlertService, PointOfSale, Country, Auth, CompanyPreferences) {
        var vm = this;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.transition = transition;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll();
        vm.countries = Country.query({page: 0, size: 99999, sort: "name"});

        function loadAll () {

            PointOfSale.get({id: 0, logo: false}, onSuccess, onError);

            function onSuccess(data) {
                vm.pointOfSale = data;
                vm.company = data.company;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        var onSaveSuccess = function (result) {
            $scope.$emit('wamaApp:companyUpdate', result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if(vm.photoRawBase64!=null){
                vm.company.logoRaw = vm.photoRawBase64.base64;
            }

            if (vm.company.id !== null) {
                Company.update(vm.company, onSaveSuccess, onSaveError);
            } else {
                Company.save(vm.company, onSaveSuccess, onSaveError);
            }
        };

        vm.photoRawBase64=null;

        vm.clearPhotoRaw = function (event) {
            vm.company.logoRaw = null;
            vm.company.logo = null;
            vm.photoRawBase64 = null;
            vm.clearPhoto = true;
            event.preventDefault();
        };

        vm.hasAccessRights = Auth.hasAccessRights;

    }
})();
