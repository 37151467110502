(function () {
    'use strict';

    angular
        .module('wamaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('pos', {
            parent: 'entity',
            url: '/pos/{orderId}',
            data: {
                authorities: ['ROLE_USER','ROLE_OPERATOR', 'ROLE_ADMIN', 'ROLE_SALES_OPERATOR'],
                pageTitle: 'wamaApp.cash.home.title',
                pageType: 'pos'
            },
            views: {
                'contentPos@': {
                    templateUrl: 'app/entities/cash/pos.html',
                    controller: 'PosController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'name,asc',
                    squash: true
                },
                search: null,
                outOfStock: false,
                category: null,
                saleOrder: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search,
                        outOfStock: $stateParams.outOfStock,
                        category: $stateParams.category
                    };
                }],
                entity: ['$stateParams', 'SaleOrder', function($stateParams, SaleOrder) {
                    if($stateParams.orderId!=null && $stateParams.orderId > 0){
                        return SaleOrder.get({id : $stateParams.orderId});
                    }
                    return null;
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('pos');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('product');
                    $translatePartialLoader.addPart('saleOrder');
                    $translatePartialLoader.addPart('customer');
                    $translatePartialLoader.addPart('address');
                    return $translate.refresh();
                }]
            }
        })
        .state('pos.edit-product', {
            parent: 'pos',
            url: '/edit-product/{id}/quantity/{quantity}',
            data: {
                authorities: ['ROLE_USER','ROLE_OPERATOR', 'ROLE_ADMIN', 'ROLE_SALES_OPERATOR'],
            },
            params: {
                entry: null
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/cash/pos-edit-product.html',
                    controller: 'PosDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: null
                }).result.then(function() {
                    $state.go('pos', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('pos.checkout', {
            parent: 'pos',
            url: '/checkout',
            data: {
                authorities: ['ROLE_USER','ROLE_OPERATOR', 'ROLE_ADMIN', 'ROLE_SALES_OPERATOR']
            },
            params: {
                order: null
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/cash/pos-checkout-dialog.html',
                    controller: 'PosCheckoutDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg'
                }).result.then(function() {
                    $state.go('pos', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();























