(function() {
    'use strict';

    angular
        .module('wamaApp')
        .config(companyInfo);
    companyInfo.$inject = ['$stateProvider'];

    function companyInfo($stateProvider) {
        $stateProvider
            .state('companyinfo', {
                parent: 'account',
                url: '/company-information',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'settings.companyinfo.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/account/settings/companyinfo.html',
                        controller: 'CompanyInfoController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('pointOfSale');
                        $translatePartialLoader.addPart('company');
                        $translatePartialLoader.addPart('address');
                        return $translate.refresh();
                    }]
                }
            });
    }
})();
