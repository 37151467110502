(function() {
    'use strict';
    angular
        .module('wamaApp')
        .factory('PaymentCustomer', PaymentCustomer);

    PaymentCustomer.$inject = ['$resource'];

    function PaymentCustomer ($resource) {
        var resourceUrl =  'api/stripe/billingCustomer/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if(!data){
                        return null;
                    }
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
