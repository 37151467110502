(function() {
    'use strict';
    angular
        .module('wamaApp')
        .factory('Image', Image);

    Image.$inject = ['$uibModal'];

    function Image ($uibModal) {

        return  {
            'openModalImage': openModalImage
        };



        function openModalImage(imageSrc, imageDescription) {
            $uibModal.open({
                templateUrl: "app/entities/product/modalImage.html",
                resolve: {
                    imageSrcToUse: function () {
                        if(imageSrc!=null){
                            imageSrc = imageSrc.replace("thumbnail","full");
                        }
                        return imageSrc;
                    },
                    imageDescriptionToUse: function () {
                        return imageDescription;
                    }
                },
                controller: [
                    "$scope", "imageSrcToUse", "imageDescriptionToUse",
                    function (vm, imageSrcToUse, imageDescriptionToUse) {
                        vm.imageSrc = imageSrcToUse;
                        return vm.imageDescription = imageDescriptionToUse;
                    }
                ]
            });
        };


    };
})();
