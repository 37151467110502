(function() {
    'use strict';
    angular
        .module('wamaApp')
        .factory('PointOfSaleAuthTenant', PointOfSaleAuthTenant);

    PointOfSaleAuthTenant.$inject = ['$resource'];

    function PointOfSaleAuthTenant ($resource) {
        var resourceUrl =  'api/point-of-sales/auth-tenant/:id';

        return $resource(resourceUrl, {}, {

        });

    }
})();
