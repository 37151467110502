(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('PurchaseOrderDeleteController',PurchaseOrderDeleteController);

    PurchaseOrderDeleteController.$inject = ['$uibModalInstance', 'entity', 'PurchaseOrder'];

    function PurchaseOrderDeleteController($uibModalInstance, entity, PurchaseOrder) {
        var vm = this;
        vm.purchaseOrder = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            vm.isDeleting = true;
            PurchaseOrder.delete({id: id},
                function () {
                    vm.isDeleting = false;
                    $uibModalInstance.close(true);
                }, function () {
                    vm.isDeleting = false;
                });
        };
    }
})();
