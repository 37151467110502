/**
 * Created by antonio on 07/06/17.
 */
(function(){
    'user-strict';

    angular
        .module('wamaApp')
        .provider('ToastAlertService',ToastAlertService);

    function ToastAlertService() {
        this.$get = getService;

        getService.$inject = ['$sce','$translate','toastr'];

        function getService($sce,$translate,toastr) {
            return {
                factory:factory,
                show:showAlert,
                error: error,
                info: info,
                success: success,
                errorTranslated: errorTranslated
            }

            function errorTranslated(key){
                toastr.error($translate.instant(key), null, {allowHtml:true});
            }

            function error(params) {
                toastr.error(params.msg, params.title,{allowHtml:true});
            }

            function success(params) {
                toastr.success(params.msg, params.title,{allowHtml:params.trustAsHtml});
            }

            function info(params) {
                toastr.info(params.msg, params.title,{allowHtml:params.trustAsHtml});
            }

            function factory(alertOptions) {
                if ('type' in alertOptions && typeof alertOptions.type === "function" ) {
                    alertOptions.type(alertOptions);
                }else{
                    error("Type of function not defined!", "Error");
                }
            }

            function showAlert(alertOptions) {
                alertOptions.msg = $translate.instant(alertOptions.msg, {param: alertOptions.params});
                alertOptions.title = ('title' in alertOptions) ? alertOptions.title : '';
                this.factory(alertOptions);
            }
        }
    }

})()
