(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('ProductController', ProductController);

    ProductController.$inject = ['$scope', '$state', '$filter', 'Product', 'ProductSearch', 'ParseLinks', 'ToastAlertService', 'AlertService', 'pagingParams', 'paginationConstants', '$http', 'StockEntry',
        'Upload', 'PointOfSale','$httpParamSerializer', '$translate', '$localStorage', 'Auth', 'Export','CompanyPreferences', 'CategoryUtil', 'Image', ];

    function ProductController ($scope, $state, $filter, Product, ProductSearch, ParseLinks, ToastAlertService, AlertService, pagingParams, paginationConstants, $http, StockEntry, Upload, PointOfSale,
                                $httpParamSerializer, $translate, $localStorage, Auth, Export, CompanyPreferences, CategoryUtil, Image) {
        var vm = this;
        initCustomPageSize();
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.clear = clear;
        vm.search = search;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        //custom
        vm.loadUnload = loadUnload;
        vm.currencyHelpers = currencyHelpers;
        vm.errorMsg = false;
        vm.loadByCategory = loadByCategory;
        vm.pointOfSale = PointOfSale.get({id: 0});
        vm.maxNumCategories = 2;
        vm.exportData = Export.exportData;
        vm.exportAllData = Export.exportAllData;
        vm.companyPreferences = CompanyPreferences.getPreferencesFromLocalStorage();

        vm.columnVisibility = {
            "photo": true,
            "code": true,
            "barcode": true,
            "description": false,
            "price": true,
            "netValue":false,
            "taxValue":false,
            "grossValue":false,
            "purchasePrice":false,
            "markup":false,
            "quickQuantity":false,
            "showClone": true
        };

        vm.filter = {
            lowStock: false,
            outOfStock: false,
            inStock: false
        };

        vm.initLocalStoragePreferences = function () {
            if($localStorage.columnVisibility != null){
                vm.columnVisibility = $localStorage.columnVisibility;
            }else{
                $localStorage.columnVisibility = vm.columnVisibility;
            }
            if($localStorage.filter != null){
                vm.filter = $localStorage.filter;
            }else{
                $localStorage.filter = vm.filter;
            }
            if($localStorage.newNotification != null){
                vm.newNotification = $localStorage.newNotification;
            }else{
                $localStorage.newNotification = vm.newNotification;
            }
        };

        vm.initLocalStoragePreferences();

        var selectedCategory;
        if(pagingParams.category){
            selectedCategory = pagingParams.category;
        }else{
            selectedCategory = JSON.parse(localStorage.getItem('product-listing-category')); //pagingParams.category is set in product.state to null when the page refreshes
        }
        if(selectedCategory == null || selectedCategory == 'all' ||  pagingParams.search){
            vm.loadAll();
            localStorage.removeItem("product-listing-category");
        }else{
            vm.loadByCategory(selectedCategory.id);
            vm.category = selectedCategory;
            localStorage.setItem('product-listing-category', JSON.stringify(selectedCategory)); //the product category listing page will continue to show the category products also after the page refresh F5
        }

        vm.applyFilters = function (filter) {
            for (var key in vm.filter) {
                if(key !== filter){
                    vm.filter[key] = false;
                }
            }
            $localStorage.filter = vm.filter;
            loadAll();
        };

        function initCustomPageSize() {
            vm.itemsPerPage = $localStorage.itemsPerPage != null ? $localStorage.itemsPerPage : paginationConstants.itemsPerPage;
        }

        vm.changePageSize = function () {
            pagingParams.page = 1; //reset to page 1 when changing items per page
            $localStorage.itemsPerPage = vm.itemsPerPage;
            if(selectedCategory){
                loadByCategory(selectedCategory)
            } else {
                loadAll();
            }
        };

        function loadAll () {
            if (pagingParams.search) {
                ProductSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Product.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                    lastPurchasePrice: vm.columnVisibility.purchasePrice,
                    outOfStock: $localStorage.filter != null ? $localStorage.filter.outOfStock : false,
                    lowStock: $localStorage.filter != null ? $localStorage.filter.lowStock : false,
                    inStock: $localStorage.filter != null ? $localStorage.filter.inStock : false
                }, onSuccess, onError);
            }
        }

        function loadByCategory (category) {
            Product.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                outOfStock: pagingParams.outOfStock,
                category: category,
                lastPurchasePrice: vm.columnVisibility.purchasePrice
            }, onSuccess, onError);
        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }
        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.products = data;
            vm.page = pagingParams.page;
            vm.startedLoadByBarcode = false;
        }
        function onError(error) {
            AlertService.error(error.data.message);
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        //****************** wama custom functions **************

        function loadUnload(product, quantity) {
            vm.isSaving = true;
            var stockEntry = {
                "product" : product,
                "quantity" : quantity,
                "timeCreated": new Date //todo: move the logic to the server
            };
            if (stockEntry.product.id != null) {
                StockEntry.update(stockEntry, onLoadUnloadSuccess, onSaveError);
            } else {
                onSaveError();
            }

            function onLoadUnloadSuccess (data, headers) {
                //substitute in the products list the modified product with the new one
                angular.forEach(vm.products, function(u, i) {
                    if (u.id === data.product.id ) {
                        vm.products[i] = data.product;
                    }
                });
                vm.isSaving = false;
                vm.modifiedProductId = data.product.id;
            }

            function onSaveError (error) {
                //var header = error.headers("stockEntry");
                var errorKey;
                if(error.headers("Failure")){
                    errorKey = error.headers("Failure");
                }else{
                    errorKey = error.data.message;
                }
                AlertService.error(errorKey);
                vm.isSaving = false;
            }
        }

        vm.viewModeChange = function () {
            if(vm.viewMode === true){
                $state.go("product-category");
            }
        };

        vm.stockLevelTooltip = function (product) {
            var status="good";
            if(product.quantity === 0){
                status="empty";
            } else if(product.quantity <= product.reorderThreshold){
                status="low";
            }
            return $translate.instant("wamaApp.product.messages.stock."+status);
        };

        vm.getNotVisibleCategoryNames = CategoryUtil.getNotVisibleCategoryNames;

        vm.calculateMarkup = function(purchasePrice, sellingPrice){
            if(purchasePrice!=null && sellingPrice!=null && purchasePrice.currency != null && sellingPrice.currency !=null && purchasePrice.currency.id === sellingPrice.currency.id){
                return sellingPrice.price - purchasePrice.price;
            }
            return "";
        };
        vm.calculateMarkupPercentage = function(purchasePrice, sellingPrice){
            var markup = vm.calculateMarkup(purchasePrice, sellingPrice);
            if(markup!=="" && purchasePrice.price !== 0){
                return "(" + (markup / purchasePrice.price * 100).toFixed(0) + "%)";
            }
            return "";
        };

        vm.refresh = function (){
            loadAll();
            ToastAlertService.show({type: ToastAlertService.success, msg: 'global.dataRefreshed'})
        }


        vm.openModalImage = Image.openModalImage

        vm.viewMode = "product";

        vm.hasAccessRights = Auth.hasAccessRights;
    }
})();
