(function() {
    'use strict';
    angular
        .module('wamaApp')
        .factory('PointOfSaleUser', PointOfSaleUser);

    PointOfSaleUser.$inject = ['$resource'];

    function PointOfSaleUser ($resource) {
        var resourceUrl =  'api/point-of-sales/:pointOfSaleId/user/:userId';

        return $resource(resourceUrl, {}, {
            'update': { method:'PUT' }
        });

    }
})();
