(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('EmployeesDialogController',EmployeesDialogController);

    EmployeesDialogController.$inject = ['$uibModalInstance', 'entity', 'UserId', 'PointOfSale'];

    function EmployeesDialogController ($uibModalInstance, entity, UserId, PointOfSale) {
        var vm = this;

        vm.clear = clear;
        vm.languages = null;
        vm.save = save;
        vm.user = entity;
        vm.pointofsales = PointOfSale.query({page: 0, size: 99999, sort: "name"});

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function save () {
            vm.isSaving = true;
            if (vm.user.id !== null) {
                UserId.update(vm.user, onSaveSuccess, onSaveError);
            }
        }
    }
})();
