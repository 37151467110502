(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('LoyaltyCardTypeDialogController', LoyaltyCardTypeDialogController);

    LoyaltyCardTypeDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'LoyaltyCardType', 'PointOfSale'];

    function LoyaltyCardTypeDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, LoyaltyCardType, PointOfSale) {
        var vm = this;
        vm.loyaltyCardType = entity;
        vm.pointofsale = PointOfSale.get({id: 0});

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
            vm.loyaltyCardType.pointOfSaleId = vm.pointofsale.id;
        });

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
            if (vm.loyaltyCard && !vm.loyaltyCard.loyaltyCardTypeId && vm.loyaltycardtypes.length) {
                vm.loyaltyCard.loyaltyCardTypeId = vm.loyaltycardtypes[0].id;
            }
        });


        var onSaveSuccess = function (result) {
            $scope.$emit('wamaApp:loyaltyCardTypeUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.loyaltyCardType.id !== null) {
                LoyaltyCardType.update(vm.loyaltyCardType, onSaveSuccess, onSaveError);
            } else {
                LoyaltyCardType.save(vm.loyaltyCardType, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
