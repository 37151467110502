(function () {
    'use strict';

    angular
        .module('wamaApp')
        .controller('CategoryDialogController', CategoryDialogController);

    CategoryDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Category', 'PointOfSale'];

    function CategoryDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, Category, PointOfSale) {
        var vm = this;
        vm.category = entity;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('wamaApp:categoryUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if(vm.photoRawBase64 != null){
                vm.category.photoRaw = vm.photoRawBase64.base64;
            }
            if (vm.category.id !== null) {
                Category.update(vm.category, onSaveSuccess, onSaveError);
            } else {
                Category.save(vm.category, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function () {
            $uibModalInstance.dismiss('cancel');
        };

        vm.colors = ["#adb4cc", "#4ed1c8", "#e9275e", "#017467", "#fc5f09", "#000000", "#be1e2d", "#ffde17", "#fdd9e9", "#21409a", "#f0c86a", "#6d7371", "#ff9d3c", "#800909", "#b9bab6",
            "#002852", "#69b418", "#94cf98", "#c7ebf0", "#d4aad4", "#575a7a", "#5a7a57", "#c89008", "#d9534f", "#ee3425"];
        vm.paletteOpened = false;
        vm.setColor = function (color) {
            vm.category.color = color;
            vm.paletteOpened = false;
        };
        vm.openClosePalette = function (status) {
            vm.paletteOpened = status;
        };
        vm.clearPhotoAndColor = function () {
            vm.category.color = null;
            vm.category.photo = null;
            vm.photoRawBase64 = null;
        }
    }
})();
