(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('WamaErrorDeleteController',WamaErrorDeleteController);

    WamaErrorDeleteController.$inject = ['$uibModalInstance', 'entity', 'WamaError'];

    function WamaErrorDeleteController($uibModalInstance, entity, WamaError) {
        var vm = this;
        vm.wamaError = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            WamaError.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
