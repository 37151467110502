(function() {
    'use strict';
    angular
        .module('wamaApp')
        .factory('SaleOrder', SaleOrder);

    SaleOrder.$inject = ['$resource', 'DateUtils'];

    function SaleOrder ($resource, DateUtils) {
        var resourceUrl =  'api/sale-orders/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.dateIssue = DateUtils.convertDateTimeFromServer(data.dateIssue);
                    data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                    data.lastModifiedDate = DateUtils.convertDateTimeFromServer(data.lastModifiedDate);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();


var salesOrderStatusColor = function (statusCode) {
    switch(statusCode){
        case 'PENDING':
            return 'label-info';
        case 'DRAFT':
            return 'label-warning';
        case 'COMPLETED':
            return 'label-success';
        case 'CANCELLED':
            return 'label-danger';
        default:
            return 'label-default';
    }
};

var salesOrderPaymentStatusColor = function (statusCode) {
    switch(statusCode){
        case 'PAID':
            return 'label-success';
        case 'PENDING':
            return 'label-warning';
        default:
            return 'label-default';
    }
};
