(function() {
    'use strict';
    angular
        .module('wamaApp')
        .factory('Currency', Currency);

    Currency.$inject = ['$resource'];

    function Currency ($resource) {
        var resourceUrl =  'api/currencies/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();

var currencyHelpers = {
    'currencyDisplay': function(currency) {
        if(currency){
            if (currency.symbol) {
                return currency.symbol;
            }
            return currency.code + " ";
        }else {
            return "";
        }
    },
    'currencyDisplayFull': function(currency) {
        if(currency){
            return currency.code + " " + currency.name + " " + currency.symbol;
        }else {
            return "";
        }
    },
    'round': function round(value, currency) {
        return (Math.round(value*Math.pow(10,currency.numDecimals))/Math.pow(10,currency.numDecimals)).toFixed(currency.numDecimals);
        // return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
    }
};
