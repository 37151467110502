(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('ExportProductCatalogueController', ExportProductCatalogueController);

    ExportProductCatalogueController.$inject = ['$state', '$rootScope', '$scope', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'Product', 'PointOfSale', 'Auth', 'Category',
        'Export', 'PriceList'];

    function ExportProductCatalogueController ($state, $rootScope, $scope, ParseLinks, AlertService, pagingParams, paginationConstants, Product, PointOfSale, Auth, Category,
                                     Export, PriceList) {
        var vm = this;

        //custom
        vm.exportProductCatalog = Export.exportProductCatalog;

        vm.currencyHelpers = currencyHelpers;
        // vm.priceLists = PriceList.query({page: 0, size: 9999, sort: "name"});
        vm.category = null;
        vm.isLoading = false;
        vm.page = 0;
        vm.pageSize = "160";
        vm.hasNextPage = false;
        vm.pageSizeOptions = [10, 20, 50, 100, 300];
        vm.loadAllProducts = loadAllProducts;
        loadAllProducts();
        loadAllCategories();

        function loadAllCategories () {
            Category.query({
                page: 0,
                size: 1000,
                sort: ['name,asc']
            }, function (data) {
                vm.categories = data;
            }, function (error){
                AlertService.error(error.data.message);
                });
        }


        function loadAllProducts () {
            vm.isLoading = true;
            Product.query({
                page: vm.page,
                size: vm.pageSize,
                sort: sort(),
                category: vm.category ? vm.category.id : null,
                formatPhoto: "300"
            }, onSuccess, onError);

            function sort() {
                var result = [name + ',' + 'asc'];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.products = data;
                // vm.page = pagingParams.page;
                vm.isLoading = false;
                vm.hasNextPage = vm.totalItems > data.length * (vm.page + 1);
                vm.numberOfPages = Math.ceil(vm.totalItems / vm.pageSize);
            }
            function onError(error) {
                vm.isLoading = false;
                AlertService.error(error.data.message);
            }
        }

        vm.fields = {
            photo: true,
            barcode: false,
            quantity: true,
            code: true,
            price:true,
            location: false,
            supplier: false,
            description: true,
            barcodeImage: false,
            categories: true
        }
        vm.colors = {
            title: '#0299E0',
            titleText: '#ffffff',
            detailsBackgroundColor: '#eeeeee',
            detailsText: '#333333'
        }

        vm.bc = {
            format: 'CODE128',
            lineColor: '#000000',
            width: 2,
            height: 100,
            displayValue: true,
            fontOptions: 'normal',
            font: 'monospace',
            textAlign: 'center',
            textPosition: 'bottom',
            textMargin: 2,
            fontSize: 20,
            background: '#EEEEEE',
            flat:true,
            margin: 0,
            marginTop: undefined,
            marginBottom: undefined,
            marginLeft: undefined,
            marginRight: undefined,
            valid: function (valid) {
            }
        };

        vm.hasAccessRights = Auth.hasAccessRights;
    }
})();
