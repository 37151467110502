(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('DealerDeleteController',DealerDeleteController);

    DealerDeleteController.$inject = ['$uibModalInstance', 'entity', 'Dealer'];

    function DealerDeleteController($uibModalInstance, entity, Dealer) {
        var vm = this;
        vm.dealer = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            Dealer.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
