(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('LotNumberDeleteController',LotNumberDeleteController);

    LotNumberDeleteController.$inject = ['$uibModalInstance', 'entity', 'LotNumber'];

    function LotNumberDeleteController($uibModalInstance, entity, LotNumber) {
        var vm = this;

        vm.lotNumber = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            LotNumber.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
