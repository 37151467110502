(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('StockTransferEditController', StockTransferEditController);

    StockTransferEditController.$inject = ['$timeout', '$scope', '$stateParams', 'entity', 'StockTransfer', 'PointOfSale', 'Customer', 'Currency', 'StockTransferGoodsDescription', 'StockTransferReason', 'StockTransferMethod', 'Tax', '$state', '$rootScope', 'Product', 'AlertService', 'Country', '$filter'];

    function StockTransferEditController ($timeout, $scope, $stateParams, entity, StockTransfer, PointOfSale, Customer, Currency, StockTransferGoodsDescription, StockTransferReason, StockTransferMethod, Tax, $state, $rootScope, Product, AlertService, Country, $filter) {
        var vm = this;
        vm.stockTransfer = entity;
        vm.pointofsales = PointOfSale.query();
        vm.customers = Customer.query({page: 0, size: 9999, sort: 'name'});
        vm.currentPointOfSale = PointOfSale.get({id : -1});
        vm.stockTransfer.sourcePointOfSale = vm.currentPointOfSale;
        vm.taxes = Tax.query();
        vm.stocktransfergoodsdescriptions = StockTransferGoodsDescription.query();
        vm.stocktransferreasons = StockTransferReason.query();
        vm.stocktransfermethods = StockTransferMethod.query();
        vm.countries = Country.query();
        vm.priorities = ['LOW','NORMAL','HIGH'];
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('wamaApp:stockTransferUpdate', result);
            vm.isSaving = false;
            vm.stockTransfer = result;
            $state.go('stock-transfer-detail', {id:vm.stockTransfer.id});
        };

        var onSaveError = function () {
            vm.isSaving = false;
            var id = newEntryId;
            angular.forEach(vm.stockTransfer.entries, function(entry, key){
                if(entry.id==null){
                    vm.stockTransfer.entries[key].id = id++;
                }
            });
        };

        vm.save = function () {
            vm.isSaving = true;
            if(vm.destinationOption=="pointOfSale"){
                vm.stockTransfer.destinationCustomer = null;
            }else if(vm.destinationOption == "customer") {
                vm.stockTransfer.destinationPointOfSale = null;
            }

            angular.forEach(vm.stockTransfer.entries, function(entry, key){
                if(entry.id >= newEntryId){
                    vm.stockTransfer.entries[key].id = null;
                }
            });
            if (vm.stockTransfer.id !== null) {
                StockTransfer.update(vm.stockTransfer, onSaveSuccess, onSaveError);
            } else {
                StockTransfer.save(vm.stockTransfer, onSaveSuccess, onSaveError);
            }
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.documentDate = false;
        vm.datePickerOpenStatus.shippingDate = false;
        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.lastModifiedDate = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };

        var newEntryId = 9999999;

        vm.insertOrUpdateEntry = function(product){
            var updated = false;
            angular.forEach(vm.stockTransfer.entries, function (entry) {
                if(entry.product!= null && entry.product.id == product.id){
                    if(entry.quantity>0){
                        entry.quantity = entry.quantity+1;
                        AlertService.success("wamaApp.stockTransfer.barcode.updatedQuantity", {name: entry.product.name, quantity: entry.quantity});
                    }else{
                        entry.quantity = 1;
                    }
                    updated = true;
                }
            });
            if(!updated){
                vm.newEntry(product);
                AlertService.success("wamaApp.stockTransfer.barcode.added");
            }
        };

        vm.newEntry = function (product) {
            var newId = newEntryId;
            var entries = vm.stockTransfer.entries;
            if(entries!=null && entries.length>0){
                var last_entry = entries[vm.stockTransfer.entries.length - 1];
                if(last_entry.id >= newEntryId){
                    newId = last_entry.id + 1;
                }
            }
            var entry = {
                product: product,
                productName: null,
                productCode: null,
                basePrice: product.prices && product.prices.length ? product.prices[0].price : null,
                totalPrice: null,
                taxPercentage: product.prices && product.prices.length && product.prices[0].tax ? product.prices[0].tax.value : null,
                taxIncluded: product.prices && product.prices.length ? product.prices[0].taxIncluded : null,
                discountValue: null,
                discountPercentage: null,
                quantity: 1,
                quantityProcessed: null,
                notes: null,
                createdBy: null,
                createdDate: null,
                lastModifiedBy: null,
                lastModifiedDate: null,
                id: newId
            };
            if(vm.stockTransfer.entries == null){
                vm.stockTransfer.entries = Array();
            }
            var destinationPointOfSaleId = vm.stockTransfer.destinationPointOfSale!= null ? vm.stockTransfer.destinationPointOfSale.id : null;
            vm.stockTransfer.entries.push(entry);
            if(!product) {
                openProductSelectionDialog(newId, destinationPointOfSaleId);
            }
        };

        vm.openProductSelectionDialog = openProductSelectionDialog;
        function openProductSelectionDialog(newId, destinationPointOfSaleId) {

            $state.go('stock-transfer-edit.product', ({
                sourcePointOfSaleId: vm.stockTransfer.sourcePointOfSale.id,
                entryId: newId,
                destinationPointOfSaleId: destinationPointOfSaleId,
                addedProducts: getStockTransferProductIds()
            }))

        }

        function getStockTransferProductIds(){
            var ids = [];
            angular.forEach(vm.stockTransfer.entries, function(entry){
                if(entry.product){
                    ids.push(entry.product.id);
                }
            });
            return ids;
        }

        vm.deleteEntry = function (id) {
            angular.forEach(vm.stockTransfer.entries, function(entry, key){
                if(entry.id == id){
                    vm.stockTransfer.entries.splice(key, 1);
                }
            });
        };

        vm.addByBarcode = function () {
            Product.query({barcode: vm.barcode}, function (result) {
                if(result!=null && result.length == 1){
                    vm.insertOrUpdateEntry(result[0]);
                } else {
                    AlertService.error("wamaApp.stockTransfer.barcode.notFound");

                }
            }, function (error) {
                AlertService.error("wamaApp.stockTransfer.barcode.notFound");
            })
        };

        var unsubscribe = $rootScope.$on('wamaApp:stockTransferEditProduct', function(event, data) {
            var entryId = data.entryId;
            var selectedProduct = data.product;
            var entryPosition;
            var duplicatedProduct=false;

            angular.forEach(vm.stockTransfer.entries, function(entry, key){
                if(entry.product!=null && entry.product.id == selectedProduct.id){
                    duplicatedProduct = true;
                }
                if(entryId!=null && entry.id == entryId){
                    entryPosition = key;
                }
            });
            if(entryPosition!=null && !duplicatedProduct){
                vm.stockTransfer.entries[entryPosition].product=selectedProduct;
                if(selectedProduct.prices!=null && selectedProduct.prices.length > 0){
                    vm.stockTransfer.entries[entryPosition].basePrice = selectedProduct.prices[0].price;
                    vm.stockTransfer.entries[entryPosition].taxIncluded = selectedProduct.prices[0].taxIncluded;
                }
            }
            if(duplicatedProduct){
                AlertService.error("wamaApp.stockTransfer.error.product.duplicated");
            } else{
                if(!entryId){
                    vm.newEntry(selectedProduct);
                }
            }
        });
        $scope.$on('$destroy', unsubscribe);

        $scope.$watch('vm.stockTransfer', function () {
            if(null != vm.stockTransfer){
                if(vm.stockTransfer.destinationCustomer!=null){
                    vm.destinationOption = 'customer';
                }else if(vm.stockTransfer.destinationPointOfSale !=null) {
                    vm.destinationOption = 'pointOfSale';
                }
                vm.stockTransfer.subtotal = vm.stockTransferCalculationHelpers.subtotal(vm.stockTransfer.entries);
                vm.stockTransfer.taxValue = vm.stockTransferCalculationHelpers.tax(vm.stockTransfer.entries);
                vm.stockTransfer.total = vm.stockTransferCalculationHelpers.total(vm.stockTransfer.entries);
                vm.stockTransfer.subtotalDisplay =  $filter('currency')(vm.stockTransfer.subtotal, vm.currencyHelpers.currencyDisplay(vm.stockTransfer.currency), 2);
                vm.stockTransfer.taxValueDisplay =  $filter('currency')(vm.stockTransfer.taxValue, vm.currencyHelpers.currencyDisplay(vm.stockTransfer.currency), 2);
                vm.stockTransfer.totalDisplay =  $filter('currency')(vm.stockTransfer.total, vm.currencyHelpers.currencyDisplay(vm.stockTransfer.currency), 2);

                $scope.$watch('vm.currentPointOfSale', function () {
                    if (vm.stockTransfer.currency == null && null != vm.currentPointOfSale.currencies) {
                        vm.stockTransfer.currency = vm.currentPointOfSale.currencies[0];
                    }

                    if(vm.stockTransfer && vm.stockTransfer.sourcePointOfSale == null){
                        vm.stockTransfer.sourcePointOfSale = vm.currentPointOfSale;
                    }
                }, true);
                $scope.$watch('vm.destinationOption', function () {
                    if (vm.destinationOption!=null) {
                        if(vm.destinationOption == 'pointOfSale'){
                            vm.stockTransfer.destinationCustomer = null;
                        }
                        if(vm.destinationOption == 'customer'){
                            vm.stockTransfer.destinationPointOfSale = null;
                        }
                    }
                }, true);
                $scope.$watch('vm.stockTransfer.destinationPointOfSale', function () {
                    if (vm.stockTransfer.destinationPointOfSale != null) {
                        if(vm.stockTransfer.deliveryAddress == null){
                            vm.stockTransfer.deliveryAddress = vm.stockTransfer.destinationPointOfSale.address;
                            vm.stockTransfer.deliveryAddress.id = null;
                        } else {
                            var id = vm.stockTransfer.deliveryAddress.id;
                            vm.stockTransfer.deliveryAddress = vm.stockTransfer.destinationPointOfSale.address;
                            vm.stockTransfer.deliveryAddress.id = id;
                        }
                    }
                }, true);
                $scope.$watch('vm.stockTransfer.destinationCustomer', function () {
                    if (vm.stockTransfer.destinationCustomer != null) {
                        if(vm.stockTransfer.destinationCustomer.addresses.length){
                            if(vm.stockTransfer.deliveryAddress == null || vm.stockTransfer.id == null){
                                vm.stockTransfer.deliveryAddress = vm.stockTransfer.destinationCustomer.addresses[0];
                                vm.stockTransfer.deliveryAddress.id = null;
                            }
                        }

                    }
                }, true);
            }
        }, true);

        vm.editDeliveryAddress = false;
        vm.stockTransferCalculationHelpers = stockTransferCalculationHelpers;
        vm.currencyHelpers = currencyHelpers;
        vm.taxHelpers = taxHelpers;
        vm.stockTransferStatusColor = stockTransferStatusColor;
    }
})();
