(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('LimitSubscriptionDetailController', LimitSubscriptionDetailController);

    LimitSubscriptionDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'LimitSubscription', 'Subscription'];

    function LimitSubscriptionDetailController($scope, $rootScope, $stateParams, entity, LimitSubscription, Subscription) {
        var vm = this;
        vm.limitSubscription = entity;
        
        var unsubscribe = $rootScope.$on('wamaApp:limitSubscriptionUpdate', function(event, result) {
            vm.limitSubscription = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
