(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('PurchaseOrderEntryDeleteController',PurchaseOrderEntryDeleteController);

    PurchaseOrderEntryDeleteController.$inject = ['$uibModalInstance', 'entity', 'PurchaseOrderEntry'];

    function PurchaseOrderEntryDeleteController($uibModalInstance, entity, PurchaseOrderEntry) {
        var vm = this;
        vm.purchaseOrderEntry = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            PurchaseOrderEntry.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
