(function() {
    'use strict';
    angular
        .module('wamaApp')
        .factory('Product', Product);

    Product.$inject = ['$resource', 'DateUtils', '$filter'];

    function Product ($resource, DateUtils) {
        var resourceUrl =  'api/products/:id';

        return $resource(resourceUrl, {includeRelations: true}, {
            'query': { method: 'GET', params:{outOfStock:'@outOfStock'}, isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.timeCreated = DateUtils.convertDateTimeFromServer(data.timeCreated);
                    data.timeModified = DateUtils.convertDateTimeFromServer(data.timeModified);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'head': {method:'HEAD'}
        });
    };
})();
var priceCalculationHelpers = {
    'grossPrice': function(price) {
        var grossPrice = 0;
            if(price != null && price.price != null && price.tax != null){
                if(price.taxIncluded){
                    grossPrice += price.price ;
                } else {
                    grossPrice += price.price  * (1 + (price.tax.value / 100));
                }
            }
        return grossPrice;
    },
    'taxValue': function (price) {
        var grossPrice = priceCalculationHelpers.grossPrice(price);
        var netPrice = priceCalculationHelpers.netPrice(price);
        return grossPrice - netPrice;
    },
    'netPrice': function (price) {
        var netPrice = 0;
        if(price != null  && price.price != null && price.tax != null){
            if(price.taxIncluded){
                netPrice += price.price / (1 + (price.tax.value / 100));
            } else {
                netPrice += price.price ;
            }
        }
        return netPrice;
    },
    'total': function (entries) {
        var total = 0;
        var taxIncluded;
        angular.forEach(entries, function(entry, key){
            if(entry.price != null && entry.price.tax != null){
                if(taxIncluded == null){
                    taxIncluded = entry.price.taxIncluded;
                }
                if(taxIncluded){
                    total += entry.price.price * entry.quantity;
                } else {
                    total += entry.price.price * entry.quantity * (1 + (entry.price.tax.value / 100));
                }
            }
        });
        return total;
    },
    'markup': Markup,
    'discount': function (price) {
        if(price && price.loyaltyCardType && price.price > 0 && price.discountedPrice > 0 && price.price > price.discountedPrice){
            var discount = (price.price - price.discountedPrice) / price.price * 100;
            var rounded =  Math.round( discount * 10 ) / 10;
            return rounded.toFixed(0);
        }
        return -1;
    }
};

// https://it.wikipedia.org/wiki/Margine_commerciale [(SP-PP)/(PP)]x100
function Markup (purchasePriceValue, purchasePriceTax, purchasePriceTaxIncluded, sellingPriceValue, sellingTax, sellingTaxIncluded) {
    var purchasePriceObj = {
        price: purchasePriceValue,
        tax: purchasePriceTax,
        taxIncluded: purchasePriceTaxIncluded
    }
    var sellingPriceObj = {
        price: sellingPriceValue,
        tax: sellingTax,
        taxIncluded: sellingTaxIncluded
    }
    var grossPurchasePrice = priceCalculationHelpers.grossPrice(purchasePriceObj);
    var grossSellingPrice = priceCalculationHelpers.grossPrice(sellingPriceObj);

    var markup = 0;
    if(grossPurchasePrice != null  && grossSellingPrice != null && grossPurchasePrice > 0){
        markup = (grossSellingPrice - grossPurchasePrice) / grossPurchasePrice * 100;
    }
    var rounded=  Math.round( markup * 10 ) / 10;
    return rounded.toFixed(1)+'%';
}

