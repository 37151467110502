(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('ProductDetailsLocationDialogController', ProductDetailsLocationDialogController);

    ProductDetailsLocationDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Location', 'Product', 'uiGmapGoogleMapApi'];

    function ProductDetailsLocationDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Location, Product, uiGmapGoogleMapApi) {
        var vm = this;
        vm.location = entity;
        vm.product = Product.get({id:$stateParams.id});
        $scope.mapRendering = true;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('wamaApp:locationUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
            localStorage.setItem("selectedIndex", 2);
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.location.id == null) {
                vm.location.products = Array();
                vm.location.products.push(vm.product);
                Location.save(vm.location, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.$watchGroup(["vm.location.longitude","vm.location.latitude"], function (newAddress, oldVal) {
            if (!angular.isUndefined(newAddress) && newAddress!=null){
                if(vm.location.longitude != null && vm.location.latitude != null){
                    uiGmapGoogleMapApi.then(function(maps) {
                        gMapCallback(vm.location.longitude, vm.location.latitude);
                    });
                }
            }
        });

        function gMapCallback(longitude, latitude){
            $scope.mapRendering = false;
            $scope.map = { center: { latitude: latitude, longitude: longitude }, zoom: 8};
            // $scope.location = latLng;
            $scope.marker = {
                id: 0,
                coords: angular.copy($scope.map.center),
                options: { draggable: false },
                events: {
                    dragend: function (marker, eventName, args) {
                        $log.log('marker dragend');
                        var lat = marker.getPosition().lat();
                        var lon = marker.getPosition().lng();
                        $log.log(lat);
                        $log.log(lon);

                        $scope.marker.options = {
                            draggable: false,
                            labelContent: "lat: " + $scope.marker.coords.latitude + ' ' + 'lon: ' + $scope.marker.coords.longitude,
                            labelAnchor: "100 0",
                            labelClass: "marker-labels"
                        };
                    }
                }
            };
        }
    }
})();
