(function() {
    'use strict';
    angular
        .module('wamaApp')
        .factory('Category', Category)
        .factory('CategoryUtil', CategoryUtil);

    Category.$inject = ['$resource'];

    function Category ($resource) {
        var resourceUrl =  'api/categories/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'delete': { method:'DELETE', params: {deleteIfUsed: '@deleteIfUsed'}}
        });
    }

    function CategoryUtil (){
        var categoryUtil = {
            getNotVisibleCategoryNames: getNotVisibleCategoryNames
        };

        return categoryUtil;

        function getNotVisibleCategoryNames(product, maxNumCategories) {
            var result = "";
            if(product.categories.length > maxNumCategories){
                product.categories.forEach(function (category, key) {
                    if(key >= maxNumCategories){
                        result += category.name;
                        if(key < product.categories.length -1){
                            result +=" - ";
                        }
                    }
                });
            }
            return result;
        };
    }
})();
