(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('ProductDialogController', ProductDialogController);

    ProductDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Product', 'PointOfSale',
        'Category', 'Tax', 'Supplier', 'Location', 'ProductBarcode', '$mdDialog', '$translate', 'ProductBarcodeGeneration', 'StockEntry', 'CompanyPreferences', 'LoyaltyCardType'];

    function ProductDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Product, PointOfSale, Category,
                                      Tax, Supplier, Location, ProductBarcode, $mdDialog, $translate, ProductBarcodeGeneration, StockEntry, CompanyPreferences, LoyaltyCardType) {
        var vm = this;

        vm.taxes = Tax.query();
        vm.suppliers = Supplier.query({page: 0, size: 9999, sort: 'name'});
        vm.locations = Location.query({page: 0, size: 9998, sort: 'name'});
        vm.categories = Category.query({page: 0, size: 9999, sort: 'name'});
        vm.pointOfSale = PointOfSale.get({id: 0});
        vm.companyPreferences = CompanyPreferences.getPreferencesFromLocalStorage();
        vm.loyaltycardtypes = LoyaltyCardType.query({page: 0, size: 20, sort: 'name'});

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var initProduct = function () {
            //request to clone product
            if($stateParams.clone!=null && $stateParams.clone){
                entity.id = null;
                entity.photo = null;
                angular.forEach(entity.prices, function (price) {
                    price.id = null;
                });
            }
            //end clone
            vm.product = entity;
            if(!$stateParams.clone && (null == entity.id || 0 == entity.prices.length)){
                vm.product.prices = Array();
                var price = {currency: vm.pointOfSale.currencies[0]};

                if(vm.pointOfSale.defaultTax!=null){
                    price.tax = vm.pointOfSale.defaultTax;
                    price.taxIncluded=true;
                }
                vm.product.prices.push(price);
            }
            if(null != vm.product && vm.product.quantity >= 0){
                vm.quantityAvailable = vm.product.quantity - vm.product.quantityReserved;
            }
            if(vm.product != null && (vm.product.customFields == null || vm.product.customFields.length === 0)){
                vm.addNewCustomField();
            }
        };

        //to preselect default currency
        $scope.$watch('vm.pointOfSale.id', function() {
            if(null != vm.pointOfSale.id){
                initProduct();
            }
        }, true);


        var onSaveSuccess = function (result) {
            $scope.$emit('wamaApp:productUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function (error) {
            vm.isSaving = false;
            if(error.data && error.data.params && error.data.params[0] !=='undefined' && error.data.params[0] !==null && error.data.params[0] === 'wama.error.product.photo.update'){
                vm.photoError = true;
            }
            if(error.data && error.data.params && error.data.params[0] !=='undefined' && error.data.params[0] !==null && error.data.params[0] != 'wama.error.product.photo.update'){
                vm.genericError = true;
            }
        };

        vm.save = function (saveAllPointOfSales) {
            vm.isSaving = true;
            if(vm.photoRawBase64!=null){
                vm.product.photoRaw = vm.photoRawBase64.base64;
            }
            if(vm.product.customFields!=null){
                angular.forEach(vm.product.customFields, function(customField, key){
                    if(customField.name == null){
                        vm.product.customFields.splice(key, 1);
                    }
                });
            }
            if (vm.product.id !== null) {
                if(vm.product.prices!=null && vm.product.prices.length > 0 && vm.product.prices[0].price==null){
                    vm.product.prices=null;
                }
                Product.update(vm.product, onSaveSuccess, onSaveError);
            } else {
                vm.product.pointOfSale = vm.pointOfSale;
                if(vm.product.quantity > 0 && vm.purchasePrice!=null && vm.purchasePrice > 0){
                    var purchasePrice = {
                        price: vm.purchasePrice,
                        taxIncluded: vm.product.prices[0].taxIncluded,
                        currency: vm.product.prices[0].currency,
                        tax: vm.product.prices[0].tax
                    };
                    var stockEntry={
                        price: purchasePrice,
                        product: vm.product,
                        quantity: vm.product.quantity
                    };

                    StockEntry.save(stockEntry, onSaveSuccess, onSaveError);
                }else{
                    Product.save({saveAllPointOfSales: saveAllPointOfSales}, vm.product, onSaveSuccess, onSaveError);
                }
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.timeCreated = false;
        vm.datePickerOpenStatus.timeModified = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };

        //custom

        vm.clearPhotoRaw = function (event) {
            event.preventDefault();
            vm.product.photoRaw = null;
            vm.product.photo = null;
            vm.photoRawBase64 = null;
        };

        //********* BARCODE GENERATION ************
        vm.generateBarcode = generateBarcode;
        vm.isGeneratingBarcode = false;

        function generateBarcode() {
            vm.isGeneratingBarcode = true;
            ProductBarcodeGeneration.get(function (response) {
                vm.isGeneratingBarcode = false;
                if(response && response.length > 0){
                    vm.product.barcode = response[0];
                }
            })
        }

        //********* PRODUCT INFO LOOKUP

        vm.searchingByName = false;
        vm.searchingByBarcode = false;
        vm.searchingPhotos = false;

        vm.productInfoLookup = function (params) {
            vm.searchedTerm = params.searchTerm;
            if(params.searchField === 'name'){
                vm.searchingByName = true;
            }
            if(params.searchField === 'barcode'){
                vm.searchingByBarcode = true;
            }
            if(params.searchField === 'images'){
                vm.searchingPhotos = true;
            }
            ProductBarcode.get(params, onProductInfoLookupSuccess, OnProductInfoLookupError);

            function onProductInfoLookupSuccess(data){
                vm.productInfoLookupResults = data;
                if(data._name.length > 0){
                    vm.names = data._name;
                }
                if(data._description.length > 0){
                    vm.descriptions = data._description;
                }
                if(data._image.length > 0){
                    vm.images = data._image;
                }
                vm.noImages = false;
                if(vm.searchingPhotos){
                    if(vm.images.length == 0) {
                        vm.noImages = true;
                    }
                }
                vm.searchingByName = false;
                vm.searchingByBarcode = false;
                vm.searchingPhotos = false;
            }

            function OnProductInfoLookupError(error) {
                vm.productInfoLookupResults = null;
                vm.searchingByName = false;
                vm.searchingByBarcode = false;
                vm.searchingPhotos = false;
            }
        };

        vm.confirmAlert = function(ev, params){
            var parentEl = angular.element(".modal");
            var confirm = $mdDialog.confirm()
                .parent(parentEl)
                .title($translate.instant('wamaApp.product.lookup.beta.title'))
                .textContent($translate.instant('wamaApp.product.lookup.beta.message'))
                .targetEvent(ev)
                .ok($translate.instant('wamaApp.product.lookup.beta.confirm'))
                .cancel($translate.instant('wamaApp.product.lookup.beta.cancel'));

            $mdDialog.show(confirm).then(function() {
                vm.productInfoLookup(params);
            }, function() {
                $scope.status = 'You decided to keep your debt.';
            });
        };

        vm.selectedName=null;
        vm.selectedDescription=null;
        vm.selectedImage=null;
        vm.productInfoLookupUseSelectedValues = function () {
            if(vm.selectedName){
                vm.product.name = vm.selectedName;
                vm.highlightName = {color:'#2fbc1f'};
            }
            if(vm.selectedDescription){
                vm.product.description = vm.selectedDescription;
                vm.highlightDescription = {color:'#2fbc1f'};
            }
            if(vm.selectedImage){
                vm.product.photo = vm.selectedImage;
                vm.product.photoUrl = vm.selectedImage;
            }
        };
        //********* END PRODUCT INFO LOOKUP ***********

        //********* CREATE LOCATION ***************
        vm.saveNewLocation = function(){
            vm.isSaving = true;
            var location = {id:null, name: vm.locationName};
            Location.save(location, onSaveLocationSuccess, onSaveError);
            function onSaveLocationSuccess(location) {
                vm.isSaving = false;
                vm.createNewLocationVisible = false;
                if(vm.locations == null) {
                    vm.locations = Array();
                }
                vm.locations.unshift(location);
                vm.product.location = location;
            }
        };
        //********* CREATE SUPPLIER ***************
        vm.saveNewSupplier = function(){
            vm.isSaving = true;
            var supplier = {id:null, name: vm.supplierName};
            Supplier.save(supplier, onSaveSupplierSuccess, onSaveError);
            function onSaveSupplierSuccess(supplier) {
                vm.isSaving = false;
                vm.createNewSupplierVisible = false;
                if(vm.suppliers == null) {
                    vm.suppliers = Array();
                }
                vm.suppliers.unshift(supplier);
                vm.product.supplier = supplier;
            }
        };
        //********* CREATE CATEGORY ***************
        vm.saveNewCategory = function(){
            vm.isSaving = true;
            var category = {id:null, name: vm.categoryName};
            Category.save(category, onSaveCategorySuccess, onSaveError);
            function onSaveCategorySuccess(category) {
                vm.isSaving = false;
                vm.createNewCategoryVisible = false;
                if(vm.categories == null) {
                    vm.categories = Array();
                }
                vm.categories.unshift(category);
            }
        };
        //************ CUSTOM FIELD **************
        vm.addNewCustomField = function(){
            if(vm.product.customFields == null){
                vm.product.customFields = [];
            }
            if(_.has(vm.product.customFields[vm.product.customFields.length-1], 'name') || vm.product.customFields.length == 0){
                vm.product.customFields.push({});
            }
        };
        vm.deleteCustomField = function(customField){
            if(vm.product.customFields != null){
                angular.forEach(vm.product.customFields, function(entry, key){
                    if(entry.name == customField.name){
                        vm.product.customFields.splice(key, 1);
                    }
                });
            }
            if(vm.product.customFields!=null && vm.product.customFields.length == 0){
                vm.addNewCustomField();
            }
        };

        vm.currencyHelpers = currencyHelpers;
        vm.taxHelpers = taxHelpers;
        vm.priceCalculationHelpers = priceCalculationHelpers;

        //************ LOYALTY CARD **************
        vm.showLoyaltyPrice = false;
        $scope.$watch('vm.showLoyaltyPrice', function() {
            if (vm.showLoyaltyPrice && vm.product.prices.length && vm.loyaltycardtypes.length && vm.product.prices[0].loyaltyCardType == null) {
                vm.product.prices[0].loyaltyCardType = {};
                vm.product.prices[0].loyaltyCardType.id = vm.loyaltycardtypes[0].id;
            }
        });

        vm.removeLoyaltyPrice = function(){
            vm.showLoyaltyPrice = false;
            vm.product.prices[0].loyaltyCardType = null;
            vm.product.prices[0].discountedPrice = null;
        }
    }
})();
