(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$state', '$rootScope', 'Auth', 'Principal', 'ProfileService', 'LoginService', 'PointOfSale', 'PointOfSaleUser', 'CompanyPreferences', 'Preferences'];

    function NavbarController ($state, $rootScope, Auth, Principal, ProfileService, LoginService, PointOfSale, PointOfSaleUser, CompanyPreferences, Preferences) {
        var vm = this;

        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;

        ProfileService.getProfileInfo().then(function(response) {
            vm.inProduction = response.inProduction;
            vm.swaggerDisabled = response.swaggerDisabled;
        });

        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.$state = $state;
        updatePointOfSales();
        loadAllModulePreferences();

        function login() {
            collapseNavbar();
            LoginService.open();
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            $rootScope.USERNAME = "";
            $state.go('home');
        }


        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        deleteUsernameIfNotAuthenticated();

        function deleteUsernameIfNotAuthenticated(){
            if(!Principal.isAuthenticated){
                $rootScope.USERNAME = "";
            }
        }

        $rootScope.$on('authenticationSuccess', function(event, data) {
            updatePointOfSales();
        });

        function updatePointOfSales() {
            PointOfSale.get({id:-1}, onSuccess);
            vm.componentPointOfSales = PointOfSale.query({
                page: 0,
                size: 1000,
                sort: 'name'
            });
        }

        function onSuccess(result){
            vm.pointOfSale = result;
        }

        function loadAllModulePreferences() {
            Preferences.mapQuery({sections: 'MODULES,WAREHOUSE'}, onSuccess);
            function onSuccess(preferences) {
                CompanyPreferences.savePreferencesToLocalStorage(preferences);
            }
        }

        vm.companyPreferences = CompanyPreferences.getPreferencesFromLocalStorage();

        vm.changePointOfSale = changePointOfSale;

        function changePointOfSale(userId, pointOfSaleId) {
            PointOfSaleUser.update({userId: userId, pointOfSaleId: pointOfSaleId}, null, null);
            location.reload();
        }


        vm.getPosSelectIcon = function(){
            return {'background': $rootScope.getSiteTranslation('global.menu.account.posSelect.background', 'value')}
        }

        vm.hasAccessRights = Auth.hasAccessRights;

    }
})();
