(function() {
    'use strict';

    angular
        .module('wamaApp')
        .factory('StockEntryReasonSearch', StockEntryReasonSearch);

    StockEntryReasonSearch.$inject = ['$resource'];

    function StockEntryReasonSearch($resource) {
        var resourceUrl =  'api/_search/stock-entry-reasons/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
