(function () {
    'use strict';

    angular
        .module('wamaApp').component('stripeForm', {
        templateUrl: 'app/account/settings/stripe/stripeForm.html',
        bindings: {},
        controller: stripeFormController
    });
    stripeFormController.$inject = ['$scope', '$http', 'ToastAlertService', '$translate', 'SetupIntent', 'Country'];

    function stripeFormController($scope, $http, ToastAlertService, $translate, SetupIntent, Country) {
        var vm = this;

        vm.loading = true;
        vm.countries = Country.query({page: 0, size: 999, sort: "name"});

        vm.clear = function () {
            $scope.$emit('wamaApp:cardCloseModal', null);
        };
        vm.clientSecret = null;

        SetupIntent.get(null, onSetupIntentSuccess, onSetupIntentError);

        function onSetupIntentSuccess(response) {
            vm.clientSecret = response.clientSecret;

            //Init
            var stripe = Stripe('pk_live_hXspwWgAySyxj96efbAL4JKc'); //production WAMA_Prd
            // var stripe = Stripe('pk_test_YIqSXQuQTFPQFS1GEgvdsY5j00mW3jmtJQ');  //development STRIPE_UPDATE
            var elements = stripe.elements();
            var card = elements.create("card", style);

            //mount card element https://stripe.com/docs/stripe-js/reference#element-mount
            card.mount("#card-element");

            vm.loading = false;
            vm.disableSubmitBtn = false;

            vm.addCard = function () {
                vm.disableSubmitBtn = true;
                stripe.handleCardSetup(
                    vm.clientSecret, card, {
                        payment_method_data: {
                            billing_details: {
                                name: this.name,
                                email: this.email,
                                address: {
                                    line1:  this.address,
                                    city: this.city,
                                    country: this.country!=null ? this.country.code : null,
                                    postal_code: this.postalCode
                                }
                            }
                        }
                    }
                ).then(function (result) {
                    vm.disableSubmitBtn = false;
                    if (result.error) {
                        // Display error.message in your UI.
                        var errorMessage = $translate.instant('entity.card.error.add');
                        if(result.error!=null && result.error.message!=null){
                            errorMessage = result.error.message;
                        }
                        ToastAlertService.show({type: ToastAlertService.error, msg: errorMessage});
                    } else {
                        // The setup has succeeded. Display a success message.
                        postSetupIntentToServer(result);
                    }
                });

                function postSetupIntentToServer(result) {
                    $http.post('/api/stripe/setupIntent', result.setupIntent, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                        }
                    }).then(function (response) {
                        vm.clear();
                        //open subscription popup and refresh list of cards
                        $scope.$emit('wamaApp:cardAddedSuccessfully', null);

                    }, function (error) {
                        vm.clear();
                        ToastAlertService.show({type: ToastAlertService.error, msg: $translate.instant('entity.card.error.add')});
                    });
                }
            }

            card.addEventListener('change', function (event) {
                var displayError = document.getElementById('card-errors');
                if (event.error) {
                    displayError.textContent = event.error.message;
                    displayError.classList.add('visible');
                } else {
                    displayError.textContent = '';
                    displayError.classList.remove('visible');
                }
                vm.disableSubmitBtn = false;
            });
        }

        var style = {
            base: {
                iconColor: '#666ee8',
                color: '#31325f',
                fontWeight: 400,
                fontFamily:
                    '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '15px',
                '::placeholder': {
                    color: '#aab7c4',
                },
                ':-webkit-autofill': {
                    color: '#666ee8',
                },
            },
        };

        function onSetupIntentError() {
            vm.loading = false;
            ToastAlertService.show({type: ToastAlertService.error, msg: $translate.instant('entity.card.error.add')});
        }


    }
})();

