(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('CustomerAddressDeleteController',CustomerAddressDeleteController);

    CustomerAddressDeleteController.$inject = ['$uibModalInstance', 'Customer', '$scope', 'addressId'];

    function CustomerAddressDeleteController($uibModalInstance, Customer, $scope, addressId) {
        var vm = this;

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function () {
            $scope.$emit('wamaApp:addressDelete', addressId);
            $uibModalInstance.close(true);
        };
    }
})();
