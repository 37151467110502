(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('StockTransferEntryDeleteController',StockTransferEntryDeleteController);

    StockTransferEntryDeleteController.$inject = ['$uibModalInstance', 'entity', 'StockTransferEntry'];

    function StockTransferEntryDeleteController($uibModalInstance, entity, StockTransferEntry) {
        var vm = this;
        vm.stockTransferEntry = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            StockTransferEntry.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
