(function() {
    'use strict';

    angular
        .module('wamaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('purchase-order', {
            parent: 'entity',
            url: '/purchase-order?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'wamaApp.purchaseOrder.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/purchase-order/purchase-orders.html',
                    controller: 'PurchaseOrderController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('purchaseOrder');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('purchase-order-detail', {
            parent: 'entity',
            url: '/purchase-order/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'wamaApp.purchaseOrder.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/purchase-order/purchase-order-detail.html',
                    controller: 'PurchaseOrderDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('purchaseOrder');
                    $translatePartialLoader.addPart('purchaseOrderEntry');
                    $translatePartialLoader.addPart('price');
                    $translatePartialLoader.addPart('address');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'PurchaseOrder', function($stateParams, PurchaseOrder) {
                    return PurchaseOrder.get({id : $stateParams.id});
                }]
            }
        })
        .state('purchase-order-detail-edit', {
            parent: 'entity',
            url: '/purchase-order/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/purchase-order/purchase-order-edit.html',
                    controller: 'PurchaseOrderEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('purchaseOrder');
                    $translatePartialLoader.addPart('product');
                    $translatePartialLoader.addPart('purchaseOrderEntry');
                    $translatePartialLoader.addPart('price');
                    $translatePartialLoader.addPart('address');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'PurchaseOrder', function($stateParams, PurchaseOrder) {
                    if($stateParams.id){
                        return PurchaseOrder.get({id : $stateParams.id});
                    }
                    //this will be returned when creating a new purchase order (id not available)
                    return {
                        code: null,
                        supplierCode: null,
                        note: null,
                        timeCreated: null,
                        timeModified: null,
                        timeExpectedDelivery: null,
                        timeRequestedDelivery: null,
                        timeClosed: null,
                        shippingAddress: null,
                        id: null
                    };
                }]
            }
        })
        .state('purchase-order-detail-edit.status', {
            parent: 'purchase-order-detail-edit',
            url: '/status',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/purchase-order/purchaseOrderStatusModal.html',
                    controller: 'PurchaseOrderDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) { }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('purchase-order-detail-edit.product', {
            parent: 'purchase-order-detail-edit',
            url: '/addedProducts/{addedProducts}',
            data: {
                authorities: ['ROLE_USER']
            },
            params: {
                supplier: null
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/purchase-order/purchase-order-dialog-product.html',
                    controller: 'PurchaseOrderDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                            return {
                                page: 1,
                                sort: 'id,desc',
                                predicate: PaginationUtil.parsePredicate($stateParams.sort),
                                ascending: PaginationUtil.parseAscending($stateParams.sort),
                                search: $stateParams.search
                            };
                        }]
                    }
                }).result.then(function() {
                    $state.go('purchase-order-detail-edit', null, { reload: false, notify: true });
                }, function() {
                    $state.go('purchase-order-detail-edit');
                });
            }]
        })
        .state('purchase-order-detail.close', {
            parent: 'purchase-order-detail',
            url: '/close',
            data: {
                authorities: ['ROLE_USER']
            },
            params: {
                entries: null
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/purchase-order/purchase-order-dialog-close.html',
                    controller: 'PurchaseOrderDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) { }]
                    }
                }).result.then(function() {
                    $state.go('purchase-order', {id: $stateParams.id}, { reload: false, notify: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('purchase-order-detail.cancel', {
            parent: 'purchase-order-detail',
            url: '/cancel/?purchaseOrderCode',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/purchase-order/purchase-order-dialog-cancel.html',
                    controller: 'PurchaseOrderDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) { }]
                    }
                }).result.then(function() {
                    $state.go('purchase-order', {id: $stateParams.id}, { reload: false, notify: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('purchase-order-detail.export', {
            parent: 'purchase-order-detail',
            url: '/export/{id}?supplierEmail&ccEmail',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/purchase-order/purchase-order-dialog-export.html',
                    controller: 'PurchaseOrderDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) { }]
                    }
                }).result.then(function() {
                    $state.go('purchase-order-detail', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('purchase-order-detail.released', {
            parent: 'purchase-order-detail',
            url: '/released/{id}',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/purchase-order/purchase-order-dialog-released.html',
                    controller: 'PurchaseOrderDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) { }]
                    }
                }).result.then(function() {
                    $state.go('purchase-order-detail', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('purchase-order-detail.delete', {
            parent: 'purchase-order-detail',
            url: '/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/purchase-order/purchase-order-delete-dialog.html',
                    controller: 'PurchaseOrderDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['PurchaseOrder', function(PurchaseOrder) {
                            return PurchaseOrder.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('purchase-order', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
