(function () {
    'use strict';

    angular
        .module('wamaApp')
        .controller('ProductShopifyController', ProductShopifyController);

    ProductShopifyController.$inject = ['$uibModalInstance', 'entity', 'ShopifyProductSync', 'ToastAlertService',
        'Product', '$scope'];

    function ProductShopifyController($uibModalInstance, entity, ShopifyProductSync, ToastAlertService, Product, $scope) {
        var vm = this;
        vm.product = entity;
        vm.isSaving = false;
        vm.clear = function () {
            $uibModalInstance.dismiss('cancel');
        };
        vm.createProduct = function () {
            vm.isSaving = true;
            ShopifyProductSync.save({productId: vm.product.id}, onSuccess, onError);
        }
        vm.saveProduct = function () {
            vm.isSaving = true;
            Product.update(vm.product, onSuccess, onError);
        }

        function onSuccess(product) {
            vm.isSaving = false;
            vm.product = product;

            $scope.$emit('wamaApp:productUpdate', product);
            $uibModalInstance.close(product);
        }

        function onError() {
            vm.isSaving = false;
            ToastAlertService.show({type: ToastAlertService.error, msg: "Error"});
        }
    }
})();
