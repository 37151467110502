(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('RegisterController', RegisterController);


    RegisterController.$inject = ['$translate', '$timeout', 'Auth', 'LoginService', '$rootScope', '$state', 'CompanyPreferences'];

    function RegisterController ($translate, $timeout, Auth, LoginService, $rootScope, $state, CompanyPreferences) {
        var vm = this;

        vm.doNotMatch = null;
        vm.error = null;
        vm.errorUserExists = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.registerAccount = {};
        vm.success = null;
        vm.isSaving = false;

        $timeout(function (){angular.element('#login').focus();});

        function register () {
            if (vm.registerAccount.password !== vm.confirmPassword) {
                vm.doNotMatch = 'ERROR';
            } else {
                vm.registerAccount.langKey = $translate.use();
                vm.doNotMatch = null;
                vm.error = null;
                vm.errorUserExists = null;
                vm.errorEmailExists = null;
                vm.registerAccount.login = "notrequired@wama.cloud";
                vm.isSaving = true;
                Auth.createAccount(vm.registerAccount).then(function () {
                    vm.isSaving = false;
                    //vm.success = 'OK';
                    var salesOrderPreference = {property: 'MODULE_SALES_ORDER', valueBoolean: true};
                    CompanyPreferences.savePreferenceToLocalStorage(salesOrderPreference);
                    login(vm.registerAccount.email, vm.registerAccount.password, true);
                }).catch(function (response) {
                    vm.isSaving = false;
                    vm.success = null;
                    if (response.status === 400 && response.data === 'login already in use') {
                        vm.errorUserExists = 'ERROR';
                    } else if (response.status === 400 && response.data === 'e-mail address already in use') {
                        vm.errorEmailExists = 'ERROR';
                    } else {
                        vm.error = 'ERROR';
                    }
                });
            }
        }

        function login (username, password, rememberMe) {
            Auth.login({
                username: username,
                password: password,
                rememberMe: rememberMe
            }).then(function () {
                vm.authenticationError = false;
                if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                    $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                    $state.go('home');
                }

                $rootScope.$broadcast('authenticationSuccess');

                // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                // since login is succesful, go to stored previousState and clear previousState
                if (Auth.getPreviousState()) {
                    var previousState = Auth.getPreviousState();
                    Auth.resetPreviousState();
                    $state.go(previousState.name, previousState.params);
                }
            }).catch(function () {
                vm.authenticationError = true;
            });
        }
    }
})();
