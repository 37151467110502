(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('RestApiController', RestApiController);

    RestApiController.$inject = ['$scope', '$state', 'Company', 'ParseLinks', 'AlertService', 'PointOfSale', 'Auth'];

    function RestApiController ($scope, $state, Company, ParseLinks, AlertService, PointOfSale, Auth) {
        var vm = this;
        vm.pointOfSale = PointOfSale.get({id: 0, logo: true}, null, null);

        vm.hasAccessRights = Auth.hasAccessRights;

    }
})();
