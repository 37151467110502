(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('SaleOrderDetailController', SaleOrderDetailController);

    SaleOrderDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', '$http', '$filter', 'Auth', 'CategoryUtil'];

    function SaleOrderDetailController($scope, $rootScope, $stateParams, entity, $http, $filter, Auth, CategoryUtil) {
        var vm = this;
        vm.saleOrder = entity;
        vm.maxNumCategories = 2;

        var unsubscribe = $rootScope.$on('wamaApp:saleOrderUpdate', function(event, result) {
            vm.saleOrder = result;
        });
        $scope.$on('$destroy', unsubscribe);


        vm.downloadSaleOrderPDF = downloadSaleOrderPDF;

        function downloadSaleOrderPDF () {
            $http.get('/api/sale-orders/'+ $stateParams.id +'/pdf', {responseType: 'arraybuffer'})
                .success(function (data) {
                    var blob = new Blob([data], {
                        type: 'application/pdf'
                    });
                    var id = vm.saleOrder.id;
                    if(vm.saleOrder.code!=null){
                        id = vm.saleOrder.code;
                    }
                    var currentDate = $filter('date')(new Date(), "yyyy-MM-dd_HH:mm:ss");
                    var filename = "WAMA_Order_" + id + "-"  + currentDate + ".pdf";
                    saveAs(blob, filename);
                    vm.isSaving = false;
                }).error(function () {
                vm.isSaving = false;
            });
        }

        vm.salesOrderStatusColor = salesOrderStatusColor;
        vm.salesOrderPaymentStatusColor = salesOrderPaymentStatusColor;
        vm.currencyHelpers = currencyHelpers;
        vm.taxHelpers = taxHelpers;
        vm.hasAccessRights = Auth.hasAccessRights;
        vm.getNotVisibleCategoryNames = CategoryUtil.getNotVisibleCategoryNames;
    }
})();
