(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('PriceDialogController', PriceDialogController);

    PriceDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Price', 'Product', 'PointOfSale', 'Tax', 'Currency', 'PriceList'];

    function PriceDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Price, Product, PointOfSale, Tax, Currency, PriceList) {
        var vm = this;
        vm.price = entity;
        vm.products = Product.query();
        vm.pointofsales = PointOfSale.query();
        vm.taxes = Tax.query();
        vm.currencies = Currency.query();
        vm.pricelists = PriceList.query({page: 0, size: 9999, sort: "name"});

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('wamaApp:priceUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.price.id !== null) {
                Price.update(vm.price, onSaveSuccess, onSaveError);
            } else {
                Price.save(vm.price, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.priceStartValidity = false;
        vm.datePickerOpenStatus.priceEndValidity = false;
        vm.datePickerOpenStatus.discountStartValidity = false;
        vm.datePickerOpenStatus.discountEndValidity = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
    }
})();
