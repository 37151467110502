(function() {
    'use strict';

    angular
        .module('wamaApp')
        .factory('DeliveryNoteSearch', DeliveryNoteSearch);

    DeliveryNoteSearch.$inject = ['$resource'];

    function DeliveryNoteSearch($resource) {
        var resourceUrl =  'api/_search/delivery-notes/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
