(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('StockEntryDeleteController',StockEntryDeleteController);

    StockEntryDeleteController.$inject = ['$uibModalInstance', 'entity', 'StockEntry'];

    function StockEntryDeleteController($uibModalInstance, entity, StockEntry) {
        var vm = this;
        vm.stockEntry = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            StockEntry.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                    localStorage.setItem("selectedIndex", 1);
                });
        };
    }
})();
