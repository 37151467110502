(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('LocationDetailController', LocationDetailController);

    LocationDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'LocationProducts', 'uiGmapGoogleMapApi'];

    function LocationDetailController($scope, $rootScope, $stateParams, entity, LocationProducts, uiGmapGoogleMapApi) {
        var vm = this;
        vm.location = entity;
        $scope.mapRendering = true;

        getLocationProducts();

        var unsubscribe = $rootScope.$on('wamaApp:locationUpdate', function(event, result) {
            vm.location = result;
        });
        $scope.$on('$destroy', unsubscribe);


        function getLocationProducts(){
            LocationProducts.query({
                id: $stateParams.id
            }, onSuccess, onError);
        };

        function onSuccess(data) {
            vm.products = data;
        };

        function onError() {
        };

        $scope.$watchGroup(["vm.location.longitude","vm.location.latitude"], function (newValue, oldVal) {
            if (!angular.isUndefined(newValue) && newValue!=null){
                if(vm.location.longitude != null && vm.location.latitude != null){
                    uiGmapGoogleMapApi.then(function(maps) {
                        gMapCallback(vm.location.longitude, vm.location.latitude);
                    });
                }
            }
        });

        function gMapCallback(longitude, latitude){
            $scope.mapRendering = false;
            $scope.map = { center: { latitude: latitude, longitude: longitude }, zoom: 8};
            // $scope.location = latLng;
            $scope.marker = {
                id: 0,
                coords: angular.copy($scope.map.center),
                options: { draggable: false },
                events: {
                    dragend: function (marker, eventName, args) {
                        var lat = marker.getPosition().lat();
                        var lon = marker.getPosition().lng();

                        $scope.marker.options = {
                            draggable: false,
                            labelContent: "lat: " + $scope.marker.coords.latitude + ' ' + 'lon: ' + $scope.marker.coords.longitude,
                            labelAnchor: "100 0",
                            labelClass: "marker-labels"
                        };
                    }
                }
            };
        }
    }
})();
