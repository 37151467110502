(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('LotNumberDialogController', LotNumberDialogController);

    LotNumberDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'LotNumber', 'PointOfSale'];

    function LotNumberDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, LotNumber, PointOfSale) {
        var vm = this;

        vm.lotNumber = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.pointofsales = PointOfSale.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.lotNumber.id !== null) {
                LotNumber.update(vm.lotNumber, onSaveSuccess, onSaveError);
            } else {
                LotNumber.save(vm.lotNumber, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('wamaApp:lotNumberUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.expiryDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
