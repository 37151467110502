(function () {
    'use strict';

    angular
        .module('wamaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('import-export', {
                parent: 'entity',
                url: '/import-export',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_WAREHOUSE_ASSISTANT'],
                    pageTitle: 'global.menu.entities.importExport'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/import-export/import-export.html',
                        controller: 'ImportExportController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'timeModified,desc',
                        squash: true
                    },
                    search: null,
                    outOfStock: false
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            outOfStock: $stateParams.outOfStock
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('stockEntry');
                        $translatePartialLoader.addPart('price');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('location');
                        $translatePartialLoader.addPart('supplier');
                        $translatePartialLoader.addPart('subscription');
                        $translatePartialLoader.addPart('customer');
                        $translatePartialLoader.addPart('address');
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })
            .state('export-product-catalogue', {
                parent: 'import-export',
                url: '/product-catalogue',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_WAREHOUSE_ASSISTANT'],
                    pageTitle: 'global.menu.entities.importExport'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/import-export/exportProductCataloguePdf.html',
                        controller: 'ExportProductCatalogueController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'timeModified,desc',
                        squash: true
                    },
                    search: null,
                    outOfStock: false
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            outOfStock: $stateParams.outOfStock
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('stockEntry');
                        $translatePartialLoader.addPart('price');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('location');
                        $translatePartialLoader.addPart('supplier');
                        $translatePartialLoader.addPart('subscription');
                        $translatePartialLoader.addPart('customer');
                        $translatePartialLoader.addPart('address');
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })
    }

})();
