(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('DeliveryNoteDeleteController',DeliveryNoteDeleteController);

    DeliveryNoteDeleteController.$inject = ['$uibModalInstance', 'entity', 'DeliveryNote'];

    function DeliveryNoteDeleteController($uibModalInstance, entity, DeliveryNote) {
        var vm = this;
        vm.deliveryNote = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            DeliveryNote.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
