(function() {
    'use strict';
    angular
        .module('wamaApp')
        .factory('Consent', Consent);

    Consent.$inject = ['$resource'];

    function Consent ($resource) {
        var resourceUrl =  'api/consents/:action/:type';

        return $resource(resourceUrl, {}, {
            'get': { method: 'GET' , params:{email:'@email'}},
            'update': { method:'PUT' }
        });
    }
})();
