(function () {
    'use strict';

    angular
        .module('wamaApp').component('module', {
        templateUrl: 'app/components/settings/module.template.html',
        bindings: {
            name: '@',
            icon: '@',
            preference: '<',
            settingsUrl: '@',
            hideSwitch: "<",
            isPaidSubscription: '<',
            tryLink: '@',
            readMore: '@',
            documentation: '@'
        },
        controller: moduleController
    });

    moduleController.$inject = ['$scope', 'Preferences', 'ToastAlertService', 'CompanyPreferences', '$window'];

    function moduleController($scope, Preferences, ToastAlertService, CompanyPreferences, $window) {
        var vm = this;
        vm.save = save;

        function save(preference) {
            Preferences.update(preference,
                function (result) {
                    CompanyPreferences.savePreferenceToLocalStorage(result);
                    preference.id = result.id;
                },
                function (error) {
                    ToastAlertService.show({
                        type: ToastAlertService.error,
                        msg: error.data.message
                    });
                });
        }
    }
})();
