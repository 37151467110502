(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('StockEntryDialogController', StockEntryDialogController);

    StockEntryDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'StockEntry', 'Product', 'Tax', 'PointOfSale', 'Currency', 'Supplier',
        'Location', 'StockEntryReason', 'LotNumber'];

    function StockEntryDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, StockEntry, Product, Tax, PointOfSale, Currency, Supplier, Location,
                                         StockEntryReason, LotNumber) {
        var vm = this;
        //todo select only data of the current point of sale
        vm.stockEntry = entity;
        vm.product = Product.get({id:$stateParams.id});
        vm.taxes = Tax.query();
        //vm.currencies = Currency.query();
        vm.suppliers = Supplier.query({page: 0, size: 9999, sort: 'name'});
        vm.locations = Location.query({page: 0, size: 9995, sort: 'name'});
        vm.reasons = StockEntryReason.query({page: 0, size: 9999, sort: 'code'});
        vm.lotnumbers = LotNumber.query({closed:false, page: 0, size: 99999, sort: 'name'});

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('wamaApp:stockEntryUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
            localStorage.setItem("selectedIndex", 1);
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.stockEntry.id !== null) {
                if(vm.stockEntry.price !==null && vm.stockEntry.price.price == null){
                    vm.stockEntry.price = null;
                }
                StockEntry.update(vm.stockEntry, onSaveSuccess, onSaveError);
            } else {
                StockEntry.save(vm.stockEntry, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.timeCreated = false;
        vm.datePickerOpenStatus.timeModified = false;
        vm.datePickerOpenStatus.bestBeforeDate = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };

        //to preselect default currency
        $scope.$watch('vm.stockEntry.id', function() {
            if(null != vm.stockEntry.id){
                initDefaultValues();
            }
        }, true);

        function initDefaultValues() {
            if(null == entity.id || null == entity.price){
                vm.product.price = Array();
                var pointOfSale = entity.product.pointOfSale;
                var price = {currency: pointOfSale.currencies[0]};
                if(pointOfSale.defaultTax!=null){
                    price.tax = pointOfSale.defaultTax;
                    price.taxIncluded=true;
                }
                vm.stockEntry.price = price;
            }
        }

        vm.currencyHelpers = currencyHelpers;
        vm.taxHelpers = taxHelpers;
        vm.priceCalculationHelpers = priceCalculationHelpers;
    }
})();
