(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('ShopifyShopDialogController', ShopifyShopDialogController);

    ShopifyShopDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ShopifyShop', 'Company'];

    function ShopifyShopDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, ShopifyShop, Company) {
        var vm = this;
        vm.shopifyShop = entity;
        vm.companies = Company.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('wamaApp:shopifyShopUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.shopifyShop.id !== null) {
                ShopifyShop.update(vm.shopifyShop, onSaveSuccess, onSaveError);
            } else {
                ShopifyShop.save(vm.shopifyShop, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
