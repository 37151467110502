(function() {
    'use strict';
    angular
        .module('wamaApp')
        .factory('StripeConnectAccount', StripeConnectAccount);

    StripeConnectAccount.$inject = ['$resource'];

    function StripeConnectAccount ($resource) {
        var resourceUrl =  'api/stripe-connect-accounts/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if(data) {
                        data = angular.fromJson(data);
                        return data;
                    }
                    return null;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
