(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('StockTransferStatusDeleteController',StockTransferStatusDeleteController);

    StockTransferStatusDeleteController.$inject = ['$uibModalInstance', 'entity', 'StockTransferStatus'];

    function StockTransferStatusDeleteController($uibModalInstance, entity, StockTransferStatus) {
        var vm = this;
        vm.stockTransferStatus = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            StockTransferStatus.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
