(function() {
    'use strict';
    angular
        .module('wamaApp')
        .factory('Shop', Shop);

    Shop.$inject = ['$resource', 'DateUtils', '$filter'];

    function Shop ($resource, DateUtils) {
        var resourceUrl =  'api/shop/:pointOfSaleId';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', params:{outOfStock:'@outOfStock'}, isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.timeCreated = DateUtils.convertDateTimeFromServer(data.timeCreated);
                    data.timeModified = DateUtils.convertDateTimeFromServer(data.timeModified);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'head': {method:'HEAD'}
        });
    };
})();
