(function() {
    'use strict';
    angular
        .module('wamaApp')
        .factory('Tax', Tax);

    Tax.$inject = ['$resource', 'DateUtils'];

    function Tax ($resource, DateUtils) {
        var resourceUrl =  'api/taxes/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.startValidity = DateUtils.convertDateTimeFromServer(data.startValidity);
                    data.endValidity = DateUtils.convertDateTimeFromServer(data.endValidity);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();


var taxHelpers = {
    'taxDisplay': function(tax, noName) {
        if(!tax){
            return "";
        }
        if (tax.percentage) {
            if(noName!=null && noName){
                return tax.value+'%';
            }else {
                return tax.name+' '+tax.value+'%';
            }
        }
        return tax.name+' '+tax.value;
    }
};


