(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('LangDetailController', LangDetailController);

    LangDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Lang'];

    function LangDetailController($scope, $rootScope, $stateParams, entity, Lang) {
        var vm = this;
        vm.lang = entity;
        
        var unsubscribe = $rootScope.$on('wamaApp:langUpdate', function(event, result) {
            vm.lang = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
