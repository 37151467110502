(function () {
    'use strict';

    angular
        .module('wamaApp')
        .controller('PosController', PosController)
        .filter('currencySymbol', CurrencySymbolFilter);

    PosController.$inject = ['entity', '$scope', '$state', '$filter', 'Product', 'ProductSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'Currency', 'Category',
        '$rootScope', 'Auth', '$stateParams', '$q', 'OrderCalculation', 'ToastAlertService', 'PointOfSale', 'Tax', '$timeout'];

    function PosController(entity, $scope, $state, $filter, Product, ProductSearch, ParseLinks, AlertService, pagingParams, paginationConstants, Currency, Category, $rootScope, Auth, $stateParams,
                           $q, OrderCalculation, ToastAlertService, PointOfSale, Tax, $timeout) {
        var vm = this;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.clear = clear;
        vm.search = search;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.clearSale = clearSale;
        vm.selectedCategory = null;
        vm.cloneOrder = $stateParams.saleOrder;
        vm.manualEntry = 0.00;
        vm.manualDescription = null;
        vm.manualCurrency = null;
        vm.manualTax = null;
        vm.manualTaxIncluded = false;
        vm.pointOfSale = PointOfSale.get({id: 0}, onPointOfSaleSuccess);
        Tax.query(null, onManualTaxSuccess);

        initSale();

        function initSale() {
            if(entity!=null){
                vm.order = entity;
            } else {
                clearSale(false);
                restoreCart();
            }
        }

        function restoreCart() {
            var storedCart;
            try {
                storedCart = JSON.parse(localStorage.getItem('cart'));
            } catch (e) {
                clearSale(true);
            }
            $q.all(storedCart)
                .then(function (saleOrder) {
                    if (saleOrder == null || saleOrder.orderEntries == null) {
                        clearSale(true);
                    } else {
                        //restoring cart
                        vm.order = saleOrder;
                    }
                });
        }

        $q.all(vm.cloneOrder )
            .then(function (saleOrder) {
                cloneOrder(saleOrder);
            });

        function cloneOrder(saleOrder) {
            if(saleOrder!=null && saleOrder.orderEntries!=null ){
                clearSale(true);
                angular.forEach(saleOrder.orderEntries, function(entry) {
                    if(entry.product == null){
                        ToastAlertService.error("wamaApp.pos.error.copyProduct")
                    }else {
                        vm.addToCart(entry.product, entry.quantity);
                    }
                });
                if(vm.order!=null){
                    vm.order.customer = saleOrder.customer;
                }
            }
        }

        vm.loadByCategory = loadByCategory;
        loadAllCategories();
        vm.numItems = 24; //paginationConstants.itemsPerPage
        vm.categorySideBarVisible = true;

        vm.nextPageAvailable = function(){
            return pagingParams.page < vm.totalItems / vm.numItems;
        };

        vm.prevPageAvailable = function(){
            return pagingParams.page > 1;
        };

        vm.nextPage = function () {
            if(vm.nextPageAvailable()){
                pagingParams.page = pagingParams.page + 1;
                if(vm.selectedCategory == null){
                    loadAll();
                }else {
                    vm.loadByCategory(vm.selectedCategory, false);
                }
            }
        };

        vm.prevPage = function () {
            if(vm.prevPageAvailable()) {
                pagingParams.page = pagingParams.page - 1;
                if(vm.selectedCategory == null){
                    loadAll();
                }else {
                    vm.loadByCategory(vm.selectedCategory, false);
                }
            }
        };

        vm.selectedCategory = JSON.parse(localStorage.getItem('pos-view-category')); //pagingParams.category is set in product.state to null when the page refreshes
        if (vm.selectedCategory == null || vm.selectedCategory == 'all' || pagingParams.search) {
            vm.loadAll();
            localStorage.removeItem("pos-view-category");
        } else {
            vm.loadByCategory(vm.selectedCategory, false);
            vm.selectedCategory = selectedCategory;
            localStorage.setItem('pos-view-category', JSON.stringify(selectedCategory)); //the product category listing page will continue to show the category products also after the page refresh F5
        }

        function loadByCategory(category, buttonClicked) {
            if(buttonClicked){
                pagingParams.page = 1;
            }
            if(vm.selectedCategory!= null && category.id == vm.selectedCategory.id && buttonClicked){
                vm.selectedCategory = null;
                loadAll();
            } else {
                vm.selectedCategory = category;
                Product.query({
                    page: pagingParams.page - 1,
                    size: vm.numItems,
                    sort: sort(),
                    outOfStock: pagingParams.outOfStock,
                    category: category.id
                }, onSuccess, onError);
            }
        }

        function loadAllCategories() {
            Category.query({
                page: 0,
                size: 9000,
                sort: sort()
            }, onCategoriesSuccess, onError)
        }

        function loadAll() {
            vm.selectedCategory = null;
            if (pagingParams.search) {
                ProductSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.numItems,
                    sort: sort(),
                    priceList: true
                }, onSuccess, onError);
            } else {
                Product.query({
                    page: pagingParams.page - 1,
                    size: vm.numItems,
                    sort: sort(),
                    outOfStock: pagingParams.outOfStock,
                    priceList: true
                }, onSuccess, onError);
            }
        }


        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.products = data;
            vm.page = pagingParams.page;
        }

        function onCategoriesSuccess(data, headers) {
            vm.totalCategories = headers('X-Total-Count');
            vm.categories = data;
        }

        function onError(error) {
            ToastAlertService.error(error.data.message);
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }


        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function clearSale (clearLocalStorage) {
            vm.order = {
                orderEntries: Array(),
                discountPercentage: true
            };
            if(clearLocalStorage) {
                localStorage.setItem('cart', vm.order);
            }
        };

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }



        vm.addToCart = function (product, quantity, replaceQuantity) {
            if (product.prices == null || product.prices.length === 0 || product.prices[0].price == null) {
                ToastAlertService.errorTranslated("wamaApp.pos.error.noPrice");
                return;
            }
            var price = product.prices[0];
            if (price.currency == null){
                ToastAlertService.errorTranslated("wamaApp.product.messages.validate.price.currency.null");
                return;
            }
            if(vm.order.currency!=null && vm.order.currency.id !== price.currency.id){
                ToastAlertService.errorTranslated("wamaApp.saleOrder.error.currency.different");
                return;
            }

            var updated = false;
            angular.forEach(vm.order.orderEntries, function (entry) {
                if (entry.product != null && entry.product.id === product.id){ // && entry.product.prices[0].price == product.prices[0].price) {
                    if (entry.quantity > 0) {
                        if(replaceQuantity){
                            entry.quantity = quantity;
                            entry.productName = entry.product.id < 0 ? product.name : null; //update name when editing the entry line
                        } else {
                            entry.quantity = entry.quantity + quantity;
                        }
                        updated = true;
                    }
                }
            });
            if (!updated) {
                var productName = product.name;
                if(product.id < 0){
                    product.name = null;
                }
               var entry = {
                   id: null,
                   quantity: quantity,
                   product: product,
                   productName: product.id < 0 ? productName : null
                };
                vm.order.orderEntries.push(entry);
                vm.order.currency = price.currency;
            }
            OrderCalculation.calculateOrder(vm.order);
        };

        // #### SEARCH
        $timeout(function (){
            angular.element('#search').focus();
        });

        vm.searchType = "BARCODE";
        vm.switchSearch = function(searchType){
            vm.searchTerm = null;
            if(searchType === "BARCODE"){
                vm.searchType = "BARCODE"
            } else {
                vm.searchType = "TEXT";
            }
            angular.element('#search').focus();
        }

        function onSearchSuccess(result){
            if(result!=null && result.length >= 1){
                vm.addToCart(result[0], 1);
                vm.searchTerm = null;
            } else {
                var msg = vm.searchType === "BARCODE" ? "wamaApp.product.messages.validate.barcode.notFound" : "wamaApp.product.searchNotFound"
                ToastAlertService.errorTranslated(msg);
            }
        }

        vm.searchProduct = function () {
            if(vm.searchType === "BARCODE"){
                Product.query({barcode: vm.searchTerm}, onSearchSuccess, function () {
                    ToastAlertService.errorTranslated("wamaApp.product.messages.validate.barcode.notFound");
                })
            } else {
                ProductSearch.query({
                    query: vm.searchTerm,
                    page: 0,
                    size: 20,
                    sort: sort()
                }, function(result, headers){
                    if(result!=null && (result.length === 1 || result.length === 0)){
                        onSearchSuccess(result);
                    } else {
                        onSuccess(result, headers);
                    }
                }, onError);
            }

        };
        // #### END SEARCH

        vm.addToCartManualEntry = function(){
            var manualProduct = {
                id: getLastNegativeEntryId(),
                name: vm.manualDescription,
                prices: [
                    {
                        price: parseFloat(vm.manualEntry),
                        currency: vm.manualCurrency,
                        tax: vm.manualTax,
                        taxIncluded: vm.manualTaxIncluded
                    }
                ]
            };
            vm.addToCart(manualProduct, 1);
            vm.manualEntry = 0;
            vm.manualDescription = null;
        };

        function getLastNegativeEntryId(){
            var lastNegativeId=-1;
            angular.forEach(vm.order.orderEntries, function (entry) {
                if(entry.product.id <= lastNegativeId){
                    lastNegativeId = entry.product.id - 1
                }
            });
            return lastNegativeId;
        }

        function onManualTaxSuccess(taxes){
            vm.taxes = taxes;
            var savedTax = JSON.parse(localStorage.getItem('pos-manual-tax'));
            if(savedTax != null && vm.taxes != null) {
                angular.forEach(vm.taxes, function (tax) {
                    if (tax.id == savedTax.id) {
                        vm.manualTax = tax;
                        localStorage.setItem('pos-manual-tax', JSON.stringify(tax));
                    }
                });
            }
            if(vm.manualTax == null){
                vm.manualTax = taxes[0];
            }
            var savedTaxIncluded = localStorage.getItem('pos-manual-tax-included', status);
            if(savedTaxIncluded != null){
                vm.manualTaxIncluded = savedTaxIncluded;
            }
        }

        function onPointOfSaleSuccess(pointOfSale) {
            vm.pointOfSale = pointOfSale;
            vm.currencies = pointOfSale.currencies;

            var savedCurrency = JSON.parse(localStorage.getItem('pos-manual-currency'));
            if(savedCurrency!=null && vm.currencies!=null) {
                angular.forEach(vm.currencies, function (currency) {
                    if (currency.id == savedCurrency.id) {
                        vm.manualCurrency = currency;
                        localStorage.setItem('pos-manual-currency', JSON.stringify(currency));
                    }
                });
            }
            if(vm.manualCurrency == null) {
                vm.manualCurrency = pointOfSale.currencies.length > 0 ? pointOfSale.currencies[0] : null;
            }
        }

        vm.selectManualTax = function(tax){
            vm.manualTax = tax;
            localStorage.setItem('pos-manual-tax', JSON.stringify(tax));
            vm.taxPickerVisible = false;
        };

        vm.changeManualTaxIncluded = function(status){
            vm.manualTaxIncluded = status;
            localStorage.setItem('pos-manual-tax-included', status);
            vm.taxPickerVisible = false;
        };

        vm.selectManualCurrency = function(currency){
            vm.manualCurrency = currency;
            localStorage.setItem('pos-manual-currency', JSON.stringify(currency));
            vm.currencyPickerVisible = false;
        };


        vm.remove = function (product) {
            var deleted = false;
            angular.forEach(vm.order.orderEntries, function (entry, key) {
                if (entry.product != null && entry.product.id === product.id) {
                    if(vm.order.orderEntries.length == 1){
                        clearSale(true);
                    } else {
                        vm.order.orderEntries.splice(key, 1);
                    }
                    deleted = true;
                }
            });
            if(!deleted){
                ToastAlertService.errorTranslated("wamaApp.pos.messages.deleteError");
            }
        };

        vm.keypress = function(value){
            if(vm.manualEntry.toString().length >=8
                || (value=='.' && vm.manualEntry.toString().includes('.'))){
                return;
            }
            if(vm.manualEntry == 0 && ! vm.manualEntry.toString().includes('.')){
                if(value == '.'){
                    value = '0.';
                }
                vm.manualEntry=value;
            } else {
                vm.manualEntry = vm.manualEntry.toString().concat(value);
            }
        };

        vm.keyBackSpace = function(){
            if(vm.manualEntry.toString().length == 1){
                vm.manualEntry = 0;
                return;
            }
            vm.manualEntry = vm.manualEntry.slice(0, -1);
        };

        var posEntryEditEventListener = $rootScope.$on('wamaApp:posEntryEdit', function(event, entry) {
            vm.addToCart(entry.product, entry.quantity, true)
        });


        var posEntryDeleteEventListener = $rootScope.$on('wamaApp:posEntryDelete', function(event, data) {
            var product = data.product;
            vm.remove(product);
            OrderCalculation.calculateOrder(vm.order);
        });

        var orderPlacedEventListener = $rootScope.$on('wamaApp:orderPlaced', function(event, result) {
            clearSale(true);
            loadAll();
        });

        $scope.$on('$destroy', function() {
            posEntryDeleteEventListener();
            posEntryEditEventListener();
            orderPlacedEventListener();
        });

        vm.currencyHelpers = currencyHelpers;

        vm.hasAccessRights = Auth.hasAccessRights;
    }

    function CurrencySymbolFilter() {
        return function (currency) {
            if(currency == null){
                return ;
            }
            return currency.symbol != null ? currency.symbol : currency.code;
        };
    }
})();
