(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('PlanDeleteController',PlanDeleteController);

    PlanDeleteController.$inject = ['$uibModalInstance', 'entity', 'Plan'];

    function PlanDeleteController($uibModalInstance, entity, Plan) {
        var vm = this;
        vm.plan = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            Plan.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
