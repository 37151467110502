(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('LocationAddDialogController', LocationAddDialogController);

    LocationAddDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Location', 'Product'];

    function LocationAddDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Location, Product) {
        var vm = this;
        vm.locations = Location.query({
            productId: $stateParams.id,
            availableLocations: true
        });
        vm.product = Product.get({id: $stateParams.id});


        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('wamaApp:locationUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
            localStorage.setItem("selectedIndex", 2);
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.location.id !== null) {
                vm.location.products = Array();
                vm.location.products.push(vm.product);
                Location.update(vm.location, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
