(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('ConsentController', ConsentController);

    ConsentController.$inject = ['$stateParams', 'Consent', '$translate'];

    function ConsentController ($stateParams, Consent, $translate) {
        var vm = this;

        vm.email = null;
        vm.success = false;
        vm.error = false;

        vm.type = $stateParams.type;
        vm.email = $stateParams.email;
        vm.lang = $stateParams.lang;


        $translate.use($stateParams.lang);

        if($stateParams.action!=null && $stateParams.action && $stateParams.email && $stateParams.type && $stateParams.action){
            Consent.get({type: $stateParams.type, action: $stateParams.action.toLowerCase(), email: $stateParams.email, lang: vm.lang}, onSuccess, onError);
        } else {
            vm.error = true;
        }

        function onSuccess() {
            vm.success = true;
            vm.email = $stateParams.email;
        }

        function onError() {
            vm.error = true;
        }

    }
})();
