(function() {
    'use strict';

    angular
        .module('wamaApp', [
            'ngStorage',
            'tmh.dynamicLocale',
            'pascalprecht.translate',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ui.bootstrap',
            'ui.bootstrap.datetimepicker',
            'ui.router',
            'infinite-scroll',
            // jhipster-needle-angularjs-add-module JHipster will add new module here
            'angular-loading-bar',
            'angularBootstrapNumberpicker',
            'nvd3',
            'ngMaterial',
            'naif.base64',
            'ngMessages',
            'toastr',
            'angular-svg-round-progressbar',
            'uiGmapgoogle-maps',
            'angular-barcode',
            'angularGrid'
        ])
        .config(function($mdThemingProvider) {
            $mdThemingProvider.theme('default')
                .primaryPalette('light-blue')
                .accentPalette('blue-grey');
        })
        .config(['uiGmapGoogleMapApiProvider', function (GoogleMapApi) {
            GoogleMapApi.configure({
                key: 'AIzaSyDwnMJeDIrGr9hBweXir_05ZVnX5sGUmlw',
                libraries: 'places'
            });
        }])
        .config(function(toastrConfig) {
            angular.extend(toastrConfig, {
                positionClass: "toast-bottom-right",
                preventDuplicates: false,
                preventOpenDuplicates: true
            });
        })
        .run(run);

    run.$inject = ['stateHandler', 'translationHandler'];

    function run(stateHandler, translationHandler) {
        stateHandler.initialize();
        translationHandler.initialize();
    }
})();
