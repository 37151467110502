(function () {
    'use strict';

    angular
        .module('wamaApp')
        .controller('SupplierDetailController', SupplierDetailController);

    SupplierDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'previousState', 'ProductSupplier', '$q', 'pagingParams', 'paginationConstants', 'ParseLinks'];

    function SupplierDetailController($scope, $rootScope, $stateParams, entity, previousState, ProductSupplier, $q, pagingParams, paginationConstants, ParseLinks) {
        var vm = this;
        vm.supplier = entity;
        vm.previousState = previousState.name;
        vm.currencyHelpers = currencyHelpers;
        vm.numItems = 15; //paginationConstants.itemsPerPage


        //waiting to resolve promise
        $q.all(entity)
            .then(function (supplier) {
                loadProductSuppliers(supplier);
            })
            .catch(function (err) {
                //Handle errors
            });

        function loadProductSuppliers(supplier){
            ProductSupplier.query({supplierId: supplier.id, page: pagingParams.page - 1, size: vm.numItems, sort: 'timeModified,desc'},
                function (data, headers) {
                    vm.productSuppliers = data;
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                    vm.saleOrders = data;
                    vm.page = pagingParams.page;
                });
        }

        vm.nextPageAvailable = function(){
            return pagingParams.page < vm.totalItems / vm.numItems;
        };

        vm.prevPageAvailable = function(){
            return pagingParams.page > 1;
        };

        vm.nextPage = function () {
            if(vm.nextPageAvailable()){
                pagingParams.page = pagingParams.page + 1;
                loadProductSuppliers(vm.supplier);
            }
        };

        vm.prevPage = function () {
            if(vm.prevPageAvailable()) {
                pagingParams.page = pagingParams.page - 1;
                loadProductSuppliers(vm.supplier);
            }
        };


        var unsubscribe = $rootScope.$on('wamaApp:supplierUpdate', function (event, result) {
            vm.supplier = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
