(function() {
    'use strict';
    angular
        .module('wamaApp')
        .factory('SaleOrderStats', SaleOrderStats);

    SaleOrderStats.$inject = ['$resource', 'DateUtils'];

    function SaleOrderStats ($resource, DateUtils) {
        var resourceUrl =  'api/sale-orders/statistics';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.startDate = DateUtils.convertDateTimeFromServer(data.dateIssue);
                    data.endDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                    return data;
                }
            }
        });
    }
})();
