(function() {
    'use strict';

    angular
        .module('wamaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('product', {
            parent: 'entity',
            url: '/product?page&sort&search',
            data: {
                authorities: ['ROLE_USER','ROLE_OPERATOR','ROLE_WAREHOUSE_ASSISTANT'],
                pageTitle: 'wamaApp.product.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/product/products.html',
                    controller: 'ProductController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'timeModified,desc',
                    squash: true
                },
                search: null,
                outOfStock: null,
                category: null,
                lowStock: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', '$localStorage', function ($stateParams, PaginationUtil, $localStorage) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search,
                        outOfStock: $localStorage.filter != null ? $localStorage.filter.outOfStock : $stateParams.outOfStock,
                        category: $stateParams.category,
                        lowStock: $localStorage.filter != null ? $localStorage.filter.lowStock : $stateParams.lowStock
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('product');
                    $translatePartialLoader.addPart('stockEntry');
                    $translatePartialLoader.addPart('price');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('supplier');
                    $translatePartialLoader.addPart('location');
                    $translatePartialLoader.addPart('address');
                    $translatePartialLoader.addPart('category');
                    $translatePartialLoader.addPart('subscription');
                    $translatePartialLoader.addPart('stockEntryReason');
                    $translatePartialLoader.addPart('lotNumber');
                    $translatePartialLoader.addPart('shopifyProduct');
                    return $translate.refresh();
                }]
            }
        })
        .state('product-detail', {
            parent: 'entity',
            url: '/product/{id}',
            data: {
                authorities: ['ROLE_USER','ROLE_OPERATOR','ROLE_WAREHOUSE_ASSISTANT'],
                pageTitle: 'wamaApp.product.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/product/product-detail.html',
                    controller: 'ProductDetailController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('product');
                    $translatePartialLoader.addPart('price');
                    $translatePartialLoader.addPart('location');
                    $translatePartialLoader.addPart('supplier');
                    $translatePartialLoader.addPart('stockEntry');
                    $translatePartialLoader.addPart('subscription');
                    $translatePartialLoader.addPart('lotNumber');
                    $translatePartialLoader.addPart('pointOfSale');
                    $translatePartialLoader.addPart('productSupplier');
                    $translatePartialLoader.addPart('priceList');
                    $translatePartialLoader.addPart('category');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Product', function($stateParams, Product) {
                    return Product.get({id : $stateParams.id, formatPhoto: 'full', stockEntryInfo: true}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'product',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('product.new', {
            parent: 'product',
            url: '/new',
            data: {
                authorities: ['ROLE_USER','ROLE_WAREHOUSE_ASSISTANT']
            },
            params: {
                category: null
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                var categoriesArray = null;
                if($stateParams.category){
                    categoriesArray = [];
                    categoriesArray.push($stateParams.category);
                }
                $uibModal.open({
                    templateUrl: 'app/entities/product/product-dialog.html',
                    controller: 'ProductDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                code: null,
                                activated: null,
                                name: null,
                                description: null,
                                barcode: null,
                                quantity: null,
                                quantityReserved: null,
                                photo: null,
                                timeCreated: null,
                                timeModified: null,
                                reorderThreshold: null,
                                notes: null,
                                customFields: [],
                                barcodeType: null,
                                id: null,
                                categories: categoriesArray, //this will preselect the current category in the form to create a product
                                publiclyVisible: true
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('product', null, { reload: true });
                }, function() {
                    $state.go('product');
                });
            }]
        })
        .state('product.clone', {
            parent: 'product',
            url: '/{id}/clone',
            data: {
                authorities: ['ROLE_USER','ROLE_WAREHOUSE_ASSISTANT']
            },
            params: {
                category: null,
                clone: true
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                var categoriesArray = null;
                if($stateParams.category){
                    categoriesArray = [];
                    categoriesArray.push($stateParams.category);
                }
                $uibModal.open({
                    templateUrl: 'app/entities/product/product-dialog.html',
                    controller: 'ProductDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Product', function(Product) {
                            var product = Product.get({id : $stateParams.id}).$promise;;
                            product.id = null;
                            console.log("prod: ", product);
                            return product;
                        }]
                    }
                }).result.then(function() {
                    $state.go('product', null, { reload: true });
                }, function() {
                    $state.go('product');
                });
            }]
        })
        .state('product-detail.edit', {
            parent: 'product-detail',
            url: '/detail-edit',
            data: {
                authorities: ['ROLE_USER','ROLE_WAREHOUSE_ASSISTANT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/product/product-dialog.html',
                    controller: 'ProductDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Product', function(Product) {
                            return Product.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('product-detail', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('product.edit', {
            parent: 'product',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER','ROLE_WAREHOUSE_ASSISTANT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/product/product-dialog.html',
                    controller: 'ProductDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Product', function(Product) {
                            return Product.get({id : $stateParams.id, lastPurchasePrice: true}).$promise;;
                        }]
                    }
                }).result.then(function() {
                    $state.go('product', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('product.delete', {
            parent: 'product',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER','ROLE_WAREHOUSE_ASSISTANT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/product/product-delete-dialog.html',
                    controller: 'ProductDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Product', function(Product) {
                            return Product.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('product', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('product-detail.barcode', {
            parent: 'product-detail',
            url: '/barcode',
            data: {
                authorities: ['ROLE_USER','ROLE_WAREHOUSE_ASSISTANT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/product/product-barcode-dialog.html',
                    controller: 'ProductBarcodeController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Product', function(Product) {
                            return Product.get({id : $stateParams.id});
                        }],
                        pagingParams: null
                    }
                }).result.then(function() {
                    $state.go('product-detail', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('product.barcode', {
            parent: 'product',
            url: '/barcode',
            data: {
                authorities: ['ROLE_USER','ROLE_WAREHOUSE_ASSISTANT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/product/products-barcode-dialog.html',
                    controller: 'ProductBarcodeController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return null;
                        },
                        pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                            return {
                                page: 1,
                                sort: 'name,asc',
                                predicate: "name",
                                ascending: "asc",
                                category: $stateParams.category
                            };
                        }]
                    }
                }).result.then(function() {
                    $state.go('product', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('product.shopify', {
            parent: 'product',
            url: '/{id}/shopify',
            data: {
                authorities: ['ROLE_USER','ROLE_WAREHOUSE_ASSISTANT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/product/product-shopify-dialog.html',
                    controller: 'ProductShopifyController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Product', function(Product) {
                            return Product.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('product', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })


        //**********************
        //CUSTOM
        //**********************
        .state('product-detail.location>new', {
            parent: 'product-detail',
            url: '/location/new',
            data: {
                authorities: ['ROLE_USER','ROLE_WAREHOUSE_ASSISTANT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/product/location-dialog.html',
                    controller: 'ProductDetailsLocationDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                name: null,
                                quantity: null,
                                priority: null,
                                id: null,
                                product: null
                            };
                        }
                    }
                }).result.then(function(result) {
                    $state.go('product-detail', null, { reload: true });
                }, function() {
                    $state.go('product-detail');
                })
            }]
        })
        .state('product-detail.location>add', {
                    parent: 'product-detail',
                    url: '/location/add',
                    data: {
                        authorities: ['ROLE_USER','ROLE_WAREHOUSE_ASSISTANT']
                    },
                    onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                        $uibModal.open({
                            templateUrl: 'app/entities/location/location-dialog-add.html',
                            controller: 'LocationAddDialogController',
                            controllerAs: 'vm',
                            backdrop: 'static',
                            size: 'lg',
                            resolve: {
                                entity: function () {
                                    return {
                                        name: null,
                                        quantity: null,
                                        priority: null,
                                        id: null,
                                        product: null
                                    };
                                }
                            }
                        }).result.then(function(result) {
                            $state.go('product-detail', null, { reload: true });
                        }, function() {
                            $state.go('product-detail');
                        })
                    }]
                })
        .state('product-detail.location>delete',{
            parent: 'product-detail',
            url: '/location/{idloc}/delete',
            data: {
                authorities: ['ROLE_USER','ROLE_WAREHOUSE_ASSISTANT'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/product/location-delete-dialog.html',
                    controller: 'ProductDetailLocationDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Location', function(Location) {
                            return Location.get({id : $stateParams.idloc});
                        }]
                    }
                }).result.then(function(result) {
                    $state.go('product-detail', null, { reload: true});
                }, function() {
                    $state.go('^');
                })
            }]
        })
        .state('product-detail.location>edit', {
            parent: 'product-detail',
            url: '/location/{idloc}/edit',
            data: {
                authorities: ['ROLE_USER','ROLE_WAREHOUSE_ASSISTANT'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal' ,function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/product/location-dialog.html',
                    controller: 'ProductDetailsLocationDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Location', function(Location) {
                            return Location.get({id : $stateParams.idloc});
                        }]
                    }
                }).result.then(function(result) {
                    $state.go('product-detail',null,{reload:true});
                }, function() {
                    $state.go('^');
                })
            }]
        })
        .state('product-detail.stock-entry>edit', {
            parent: 'product-detail',
            url: '/stock-entry/{idstock}/edit',
            data: {
                authorities: ['ROLE_USER','ROLE_WAREHOUSE_ASSISTANT'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal' ,function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/stock-entry/stock-entry-dialog.html',
                    controller: 'StockEntryDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['StockEntry', function(StockEntry) {
                            return StockEntry.get({id : $stateParams.idstock});
                        }]
                    }
                }).result.then(function(result) {
                    $state.go('product-detail',null,{reload:true});
                }, function() {
                    $state.go('^');
                })
            }]
        })
        .state('product-detail.stock-entry>delete',{
            parent: 'product-detail',
            url: '/stock-entry/{idstock}/delete',
            data: {
                authorities: ['ROLE_USER','ROLE_WAREHOUSE_ASSISTANT'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/stock-entry/stock-entry-delete-dialog.html',
                    controller: 'StockEntryDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['StockEntry', function(StockEntry) {
                            return StockEntry.get({id : $stateParams.idstock});
                        }]
                    }
                }).result.then(function(result) {
                    $state.go('product-detail', null, { reload: true});
                }, function() {
                    $state.go('^');
                })
            }]
        })
        .state('product-detail.other-pos', {
            parent: 'product-detail',
            url: '/other-pos',
            data: {
                authorities: ['ROLE_USER','ROLE_WAREHOUSE_ASSISTANT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/product/product-other-pos-dialog.html',
                    controller: 'ProductOtherPosDialogController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Product', function(Product) {
                            return Product.get({id : $stateParams.id, otherPointOfSalesInfo: true});
                        }]
                    }
                }).result.then(function() {
                    $state.go('product', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('product-detail.supplier', {
            parent: 'product-detail',
            url: '/suppliers',
            data: {
                authorities: ['ROLE_USER','ROLE_WAREHOUSE_ASSISTANT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/product/product-detail-supplier-dialog.html',
                    controller: 'ProductDetailSupplierDialogController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Product', function(Product) {
                            return Product.get({id : $stateParams.id});
                        }],
                        pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                            return {
                                page: PaginationUtil.parsePage($stateParams.page),
                                sort: $stateParams.sort,
                                predicate: PaginationUtil.parsePredicate($stateParams.sort),
                                ascending: PaginationUtil.parseAscending($stateParams.sort),
                                search: $stateParams.search
                            };
                        }]
                    }
                }).result.then(function() {
                    $state.go('product', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('product-detail.customerPriceList', {
            parent: 'product-detail',
            url: '/customer-price-list',
            data: {
                authorities: ['ROLE_USER','ROLE_WAREHOUSE_ASSISTANT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/product/product-detail-price-list-dialog.html',
                    controller: 'ProductDetailPriceListDialogController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Product', function(Product) {
                            return Product.get({id : $stateParams.id, priceList: true});
                        }],
                        pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                            return {
                                page: PaginationUtil.parsePage($stateParams.page),
                                sort: $stateParams.sort,
                                predicate: PaginationUtil.parsePredicate($stateParams.sort),
                                ascending: PaginationUtil.parseAscending($stateParams.sort),
                                search: $stateParams.search
                            };
                        }]
                    }
                }).result.then(function() {
                    $state.go('product', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('product.quantity', {
            parent: 'product',
            url: '/{id}/quantity',
            data: {
                authorities: ['ROLE_USER', 'ROLE_OPERATOR','ROLE_WAREHOUSE_ASSISTANT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/product/product-quantity.html',
                    controller: 'ProductQuantityController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Product', function(Product) {
                            return Product.get({id : $stateParams.id, lastPurchasePrice: true, stockEntryInfo: true});
                        }]
                    }
                }).result.then(function() {
                    $state.go('product', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
            // *********************************** CATEGORIES ******************************************
        .state('product-category', {
            parent: 'entity',
            url: '/product-category?page&sort&search',
            data: {
                authorities: ['ROLE_USER','ROLE_OPERATOR','ROLE_WAREHOUSE_ASSISTANT'],
                pageTitle: 'wamaApp.product.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/product/products-category.html',
                    controller: 'CategoryController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'name,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('product');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('category');
                    $translatePartialLoader.addPart('subscription');
                    return $translate.refresh();
                }]
            }
        })
        .state('product-category.new', {
            parent: 'product-category',
            url: '/new',
            data: {
                authorities: ['ROLE_USER','ROLE_WAREHOUSE_ASSISTANT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/category/category-dialog.html',
                    controller: 'CategoryDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                name: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('product-category', null, { reload: true });
                }, function() {
                    $state.go('product-category');
                });
            }]
        })
        .state('product-category.edit', {
            parent: 'product-category',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER','ROLE_WAREHOUSE_ASSISTANT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/category/category-dialog.html',
                    controller: 'CategoryDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Category', function(Category) {
                            return Category.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('product-category', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('product-category.delete', {
            parent: 'product-category',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER','ROLE_WAREHOUSE_ASSISTANT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/category/category-delete-dialog.html',
                    controller: 'CategoryDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Category', function(Category) {
                            return Category.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('product-category', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
