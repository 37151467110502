(function() {
    'use strict';
    angular
        .module('wamaApp')
        .factory('ProductImport', ProductImport);

    ProductImport.$inject = ['$resource', 'DateUtils'];

    function ProductImport ($resource, DateUtils) {
        var resourceUrl =  'api/stock-entries/import/:id';

        return $resource(resourceUrl, {includeRelations: true}, {
            'post': { method:'POST',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.timeCreated = DateUtils.convertDateTimeFromServer(data.timeCreated);
                    data.timeModified = DateUtils.convertDateTimeFromServer(data.timeModified);
                    return data;
                }
            },
            'import': { method: 'PUT'}
        });
    };
})();

