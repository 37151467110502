(function() {
    'use strict';
    angular
        .module('wamaApp')
        .factory('LocationProducts', LocationProducts);

    LocationProducts.$inject = ['$resource', 'DateUtils'];

    function LocationProducts ($resource, DateUtils) {
        var resourceUrl =  'api/locations/:id/products';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.timeCreated = DateUtils.convertDateTimeFromServer(data.timeCreated);
                    data.timeModified = DateUtils.convertDateTimeFromServer(data.timeModified);
                    return data;
                }
            },
        });
    }
})();
