(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('PurchaseOrderDialogController', PurchaseOrderDialogController);

    PurchaseOrderDialogController.$inject = ['$state', '$scope', '$stateParams', '$uibModalInstance', 'ProductFullSearch', 'Product', 'PurchaseOrderInvoice',
        'PurchaseOrderStatusUpdate', 'PointOfSale', 'Country', 'pagingParams', 'ParseLinks', 'Category', 'Supplier', 'ProductSupplier', 'AlertService'];

    function PurchaseOrderDialogController ($state, $scope, $stateParams, $uibModalInstance, ProductFullSearch, Product, PurchaseOrderInvoice, PurchaseOrderStatusUpdate,
                                            PointOfSale, Country, pagingParams, ParseLinks, Category, Supplier, ProductSupplier, AlertService) {
        var vm = this;

        vm.pointOfSale = PointOfSale.get({id: 0});
        vm.countries = Country.query({page: 0, size: 99999, sort: "name"});
        vm.purchaseOrderCode = $stateParams.purchaseOrderCode; //needed for cancel order modal
        vm.searchProduct = searchProduct;
        vm.select = select;
        vm.selectProductSupplier = selectProductSupplier;
        vm.purchaseOrder = $stateParams.purchaseOrder;
        vm.currencyHelpers = currencyHelpers;
        vm.noEntriesReceived = false;
        vm.forceClosing = false;
        vm.purchaseOrderId = $stateParams.id;

        // Load products from selected supplier
        if($stateParams.supplier!=null){
            vm.supplierPriceList = $stateParams.supplier;
            loadAll(null, null, vm.supplierPriceList, true)
        } else if(pagingParams !== undefined) {
            loadAll(null, null, null, true);
        }

        if($stateParams.entries){
            if($stateParams.entries.length){
                vm.entries = $stateParams.entries;
            } else if ($stateParams.entries !=null && !angular.isArray($stateParams.entries)){
                vm.entries = Array();
                vm.entries.push($stateParams.entries);
            }
        }
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };


        function searchProduct () {
            ProductFullSearch.query({
                query: vm.productSearchQuery,
                page: 0,
                size: 20,
                sort: "name",
                lastPurchasePrice: true
            }, function (data){
                vm.searchResults = data;
            }, onError);
        }

        vm.addedProducts = [];
        function select(product){
            var data = {product: product, productSupplier: null, entryId: $stateParams.entryId};
            $scope.$emit('wamaApp:purchaseOrderEditProduct', data);
            // $uibModalInstance.close(true);
            vm.addedProducts.push(product.id);
        }


        function selectProductSupplier(productSupplier){
            var data = {product: null, productSupplier: productSupplier, entryId: $stateParams.entryId};
            $scope.$emit('wamaApp:purchaseOrderEditProduct', data);
            // $uibModalInstance.close(true);
            vm.addedProducts.push(productSupplier.product.id);
        }

        if($stateParams.addedProducts){
            var ids = $stateParams.addedProducts.split(',');
            angular.forEach(ids, function(productId) {
                vm.addedProducts.push(+(productId));
            });
        }

        vm.closeOrder = function () {
            vm.isSaving = true;
            if(vm.forceClosing || isAnyEntryReceived(vm.entries)){
                PurchaseOrderStatusUpdate.update({id: $stateParams.id}, "CLOSED", onStatusUpdateSuccess, onSaveError);
            } else {
                vm.noEntriesReceived = true;
            }
        };

        vm.cancelOrder = function () {
            vm.isSaving = true;
            PurchaseOrderStatusUpdate.update({id: $stateParams.id}, "CANCELLED", onStatusUpdateSuccess, onSaveError);
        };

        vm.markAsReleased = function () {
            PurchaseOrderStatusUpdate.update({id: $stateParams.id}, "RELEASED", onStatusUpdateSuccess, onSaveError);
        };

        var onStatusUpdateSuccess = function (result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        function isAnyEntryReceived(entries) {
            var totalQuantity = 0;
            var totalQuantityOutstanding = 0;
            if(entries!=null){
                angular.forEach(entries, function (entry) {
                    totalQuantity += entry.quantity;
                    totalQuantityOutstanding += entry.quantityOutstanding;
                });
            }
            return totalQuantityOutstanding !== totalQuantity;
        }

        //EXPORT PURCHASE ORDER
        vm.supplierEmail = $stateParams.supplierEmail;
        vm.ccEmail = $stateParams.ccEmail;
        vm.exportPurchaseOrder = exportPurchaseOrder;

        function exportPurchaseOrder(method) {
            if(method == 'email'){
                sendInvoiceViaEmail();
            }
        }

        function sendInvoiceViaEmail () {
            PurchaseOrderInvoice.save({id: $stateParams.id, supplierEmail: vm.supplierEmail, ccEmail: vm.ccEmail, mode: "email"}, null, onSaveSuccess, onSaveError);
        }

        var onSaveSuccess = function (result) {
            $scope.$emit('wamaApp:purchaseOrderExport', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };



        /**
         *  ALL PRODUCTS
         */
        vm.lowStock = false;
        vm.loadAll = loadAll;
        vm.numItems = 15; //paginationConstants.itemsPerPage

        vm.categories = Category.query({page: 0, size: 9000, sort: "name"});
        vm.suppliers = Supplier.query({page: 0, size: 9999, sort: 'name'});



        function loadAll(category, supplier, supplierPriceList, resetPage, lowStock) {
            vm.lowStock = lowStock;
            if(category != null){
                vm.supplier = null;
                vm.supplierPriceList = null;
            }
            if(supplier != null){
                vm.category = null;
                vm.supplierPriceList = null;
                vm.lowStock = false;
            }
            if(supplierPriceList != null){
                vm.category = null;
                vm.supplier = null;
                vm.lowStock = false;
            }
            if(resetPage){
                pagingParams.page = 1;
            }
            if(supplierPriceList){
                ProductSupplier.query({supplierId: supplierPriceList.id, page: pagingParams.page - 1, size: vm.numItems, sort: 'product.quantity,asc'},
                    function (data, headers) {
                        onSuccess(data, headers);
                        vm.products = null;
                        vm.productSuppliers = data;
                    });
            } else {
                Product.query({
                    page: pagingParams.page - 1,
                    size: vm.numItems,
                    sort: "quantity,ASC",
                    outOfStock: false,
                    category: category != null ? category.id : "",
                    supplier: supplier != null ? supplier.id : "",
                    lastPurchasePrice: true,
                    lowStock: lowStock
                }, onSuccess, onError);
            }
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.productSuppliers = null;
            vm.products = data;
            vm.page = pagingParams.page;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        vm.nextPageAvailable = function(){
            return pagingParams.page < vm.totalItems / vm.numItems;
        };

        vm.prevPageAvailable = function(){
            return pagingParams.page > 1;
        };

        vm.nextPage = function (category, supplier, supplierPriceList, lowStock) {
            if(vm.nextPageAvailable()){
                pagingParams.page = pagingParams.page + 1;
                loadAll(category, supplier, supplierPriceList, false, lowStock);
            }
        };

        vm.prevPage = function (category, supplier, supplierPriceList, lowStock) {
            if(vm.prevPageAvailable()) {
                pagingParams.page = pagingParams.page - 1;
                loadAll(category, supplier, supplierPriceList, false, lowStock);
            }
        };

        vm.resetFilters = function(){
            vm.supplier = null; vm.category = null; vm.supplierPriceList = null; vm.products = null; vm.productSuppliers = null;
            vm.totalItems = null;
            vm.loadAll(vm.category, null, null, true, vm.lowStock)
        }

        vm.quantityReceivedClass = function(entry){
            if(entry.quantityOutstanding == entry.quantity){
                return "label label-danger";
            }
            if(entry.quantityOutstanding == 0){
                return "label label-success";
            }
            if(entry.quantityOutstanding < entry.quantity){
                return "label label-warning";
            }
        };

        vm.totalQuantityOrdered = function (entries) {
            var total = 0;
            angular.forEach(entries, function (entry) {
                total += entry.quantity;
            });
            return total;
        };

        vm.totalQuantityReceived = function (entries) {
            var total = 0;
            angular.forEach(entries, function (entry) {
                total += entry.quantity - entry.quantityOutstanding;
            });
            return total;
        };

        vm.totalQuantityReceivedClass = function(entries){
            var totalQuantityReceived = vm.totalQuantityReceived(entries);
            var totalQuantityOrdered = vm.totalQuantityOrdered(entries);
            if(totalQuantityReceived==0){
                return "label label-danger";
            }
            if(totalQuantityReceived < totalQuantityOrdered){
                return "label label-warning";
            }
            if(totalQuantityReceived == totalQuantityOrdered){
                return "label label-success";
            }
        };

        vm.createSupplierPriceList = function(supplierId) {
            window.open(window.location.href.replace(/#(.*)/,"#") + "/supplier/"+supplierId, '_blank');
        };
    }
})();
