(function() {
    'use strict';
    angular
        .module('wamaApp')
        .factory('ShopSettings', ShopSettings);

    ShopSettings.$inject = ['$resource'];

    function ShopSettings ($resource) {
        var resourceUrl =  'api/shop/:id/settings';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });
    }
})();
