(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('NotificationsController', NotificationsController);

    NotificationsController.$inject = ['$scope', '$state', 'Notification', 'ParseLinks', 'AlertService', 'Auth'];

    function NotificationsController ($scope, $state, Notification, ParseLinks, AlertService, Auth) {
        var vm = this;
        vm.loadAll = loadAll;
        vm.loadingComplete = false;

        function loadAll () {
            Notification.query(null, onSuccess, onError);

            function onSuccess(notifications) {
                vm.notifications = notifications;
                angular.forEach(notifications, function (notification) {
                    if(notification.eventName === 'stockTransferInTransit' && notification.channel === 'email' && notification.enabled){
                        vm.stockTransferInTransitEmail = true;
                    }
                    if(notification.eventName === 'stockTransferInTransit' && notification.channel === 'android' && notification.enabled){
                        vm.stockTransferInTransitAndroid = true;
                    }
                    if(notification.eventName === 'productLowStock' && notification.channel === 'email' && notification.enabled){
                        vm.productLowStockEmail = true;
                    }
                    if(notification.eventName === 'productLowStock' && notification.channel === 'android' && notification.enabled){
                        vm.productLowStockAndroid = true;
                    }
                    if(notification.eventName === 'salesOrderStatusChange' && notification.channel === 'android' && notification.enabled){
                        vm.salesOrderStatusChangeAndroid = true;
                    }
                    if(notification.eventName === 'salesOrdersDailySummary' && notification.channel === 'android' && notification.enabled){
                        vm.salesOrdersDailySummaryAndroid = true;
                    }
                    if(notification.eventName === 'reportDailyGeneral' && notification.channel === 'email' && notification.enabled){
                        vm.reportDailyGeneralEmail = true;
                    }
                });
                vm.loadingComplete = true;
            }
            function onError(error) {
                AlertService.error(error.data);
            }
        }

        vm.save = function (enabled, event) {
            vm.isSaving = true;
            var preferenceId = event.currentTarget.id;
            var channel = preferenceId.split("-")[1];
            var event = preferenceId.split("-")[0];
            var notification = {user: null, eventName: event, channel: channel, enabled: enabled};
            Notification.update(notification, onSaveSuccess, onSaveError);
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('wamaApp:companyUpdate', result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };


        vm.hasAccessRights = Auth.hasAccessRights;
    }
})();
