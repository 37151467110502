(function() {
    'use strict';
    angular
        .module('wamaApp')
        .factory('SubscriptionUser', SubscriptionUser);

    SubscriptionUser.$inject = ['$resource', 'DateUtils'];

    function SubscriptionUser ($resource, DateUtils) {
        var resourceUrl =  'api/subscriptions/user/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.timePayment = DateUtils.convertDateTimeFromServer(data.timePayment);
                    data.validityStartTime = DateUtils.convertDateTimeFromServer(data.validityStartTime);
                    data.validityExpireTime = DateUtils.convertDateTimeFromServer(data.validityExpireTime);
                    return data;
                }
            }
        });
    }
})();
