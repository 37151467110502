(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('PosDialogController', PosDialogController);

    PosDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance'];

    function PosDialogController ($timeout, $scope, $stateParams, $uibModalInstance) {
        var vm = this;
        vm.entry = $stateParams.entry;


        vm.updateEntry = function () {
            vm.isSaving = true;
            if(vm.entry.product.id < 0){
                vm.entry.product.name = vm.entry.productName;
            }
            $scope.$emit('wamaApp:posEntryEdit', vm.entry);
        };

        vm.deleteEntry = function() {
            var data = {product: vm.entry.product};
            $scope.$emit('wamaApp:posEntryDelete', data);
            vm.clear();
        };
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.updateQuantity = function (quantity, replace) {
            // event.preventDefault();
            if(replace && quantity > 0){
                vm.entry.quantity = quantity;
                vm.updateEntry();
            } else if(vm.entry.quantity + quantity > 0){
                vm.entry.quantity = vm.entry.quantity + quantity;
                vm.updateEntry();
            }
        };

    }
})();
