(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('PricePlanDetailController', PricePlanDetailController);

    PricePlanDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'PricePlan', 'Plan', 'Tax', 'Currency'];

    function PricePlanDetailController($scope, $rootScope, $stateParams, entity, PricePlan, Plan, Tax, Currency) {
        var vm = this;
        vm.pricePlan = entity;
        
        var unsubscribe = $rootScope.$on('wamaApp:pricePlanUpdate', function(event, result) {
            vm.pricePlan = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
