(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('UserManagementDetailController', UserManagementDetailController);

    UserManagementDetailController.$inject = ['$stateParams', 'UserAdmin', 'SubscriptionUser', 'Coupon'];

    function UserManagementDetailController ($stateParams, UserAdmin, SubscriptionUser, Coupon) {
        var vm = this;
        vm.getOverlayStyle = getOverlayStyle;
        vm.getColorByPercentage = getColorByPercentage;
        vm.calculatePercentage = calculatePercentage;
        vm.deleteCoupon = deleteCoupon;

        vm.load = load;
        vm.user = {};

        vm.load($stateParams.id);

        function load (id) {
            UserAdmin.get({id: id}, function(result) {
                vm.user = result;
                SubscriptionUser.get({id:vm.user.id}, function (subscription) {
                    vm.subscription = subscription;
                    vm.coupons = Coupon.query({companyId: vm.subscription.company.id});
                    var companyCreationDate = new Date(vm.subscription.company.timeCreated);
                    var now = new Date();
                    vm.dayDif = ((now - companyCreationDate)  / 1000 / 60 / 60 / 24).toFixed(0);

                });
            });
        }


        function getOverlayStyle() {
            var isSemiOverlayStyle = this.semicircle;
            var transformOverlayStyle = (isSemiOverlayStyle ? '' : 'translateY(-50%) ') + 'translateX(-50%)';

            return {
                'top': isSemiOverlayStyle ? 'auto' : '50%',
                'bottom': isSemiOverlayStyle ? '5%' : 'auto',
                'left': '50%',
                'transform': transformOverlayStyle,
                '-moz-transform': transformOverlayStyle,
                '-webkit-transform': transformOverlayStyle,
                'font-size': this.radius / 3.5 + 'px'
            };
        }

        function calculatePercentage(value,max){
            var percentage = (100*value)/max;
            return ""+percentage;
        }

        function getColorByPercentage(value,max) {
            var x = (100*value)/max;
            var elem = document.getElementById("round-progress");
            if(x>=(100)) {
                return getComputedStyle(elem,null).getPropertyValue('--high-color');
            }else if(x<(200/3)) {
                return getComputedStyle(elem,null).getPropertyValue('--low-color');
            }else{
                return getComputedStyle(elem,null).getPropertyValue('--middle-color');
            }
        }

        function deleteCoupon(coupon){
            var retVal = confirm("Are you sure you want to delete the coupon " + coupon.code + "?");
            if( retVal == true ){
                Coupon.delete({code: coupon.code, company: coupon.companyId}, function (result) {
                    vm.coupons = Coupon.query({companyId: vm.subscription.company.id});
                }, function () {
                    alert("Error deleting the coupon "+ coupon.code);
                });
            }
        }
    }
})();
