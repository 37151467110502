(function() {
    'use strict';
    angular
        .module('wamaApp')
        .factory('OrderCalculation', OrderCalculation);

    OrderCalculation.$inject = ['SaleOrderCalculation'];

    function OrderCalculation (SaleOrderCalculation) {

        return {
            'calculateOrder': calculateOrder,
            'calculateOrderBackEnd': calculateOrderBackEnd,
            'round': round
        };

        function calculateOrderBackEnd(order, skipCustomerPriceList, onSuccessCallback, onErrorCallback){
            if((order.discount > order.subtotal && !order.discountPercentage) || (order.discountPercentageValue >100 && order.discountPercentage)) {
                //validate discount
                order.discountError = true;
            }else {
                SaleOrderCalculation.save({skipCustomerPriceList: skipCustomerPriceList}, order, function (order) {
                    if (order.discountPercentage) {
                        order.discount = order.discountPercentageValue;
                    }
                    onSuccessCallback(order);
                }, function (error) {
                    onErrorCallback(error);
                });
            }
        }

        function calculateOrder (order, skipCustomerPriceList) {
            var numDecimals = 2;
            if(order.currency!=null && order.currency.numDecimals != null){
                numDecimals = order.currency.numDecimals;
            }
            calculateEntries(order, skipCustomerPriceList, numDecimals);
            order.total = round(order.taxValue + order.subtotal, numDecimals);
            //Add discount
            if(order.discount!=null && parseFloat(order.discount) > 0){
                order.discount = parseFloat(order.discount);
                if(order.discount > order.subtotal){
                    order.discountError = true;
                }
                if(order.discount <= order.subtotal && !order.discountPercentage){
                    // order.total = currencyHelpers.round(order.totalCopy - order.discount, order.currency);
                    order.subtotal = parseFloat((order.subtotal - parseFloat(order.discount)).toFixed(numDecimals)); //todo causes digest error
                } else if(order.discount <= 100 && order.discountPercentage){
                    // order.total = currencyHelpers.round(order.totalCopy - order.discount, order.currency);
                    var discountPercentage = parseFloat(order.discount);
                    var discountValue = parseFloat(order.subtotal) / 100 * discountPercentage;
                    order.subtotal = parseFloat(order.subtotal - discountValue);
                }
                order.total = round( order.taxValue + order.subtotal, numDecimals);
                //wrong the taxes should be recalculated after applying the discount
            }
            //Persist the cart through page changes
            localStorage.setItem('cart', JSON.stringify(order));
        }

        function calculateEntries(order, skipCustomerPriceList, numDecimals) {
            var totalTax = 0;
            var subTotal = 0;
            angular.forEach(order.orderEntries, function (entry) {
                calculateEntry(order, entry, skipCustomerPriceList, numDecimals);
                totalTax += entry.taxValue;
                subTotal += entry.totalPrice;
            });
            order.taxValue = round(totalTax, numDecimals);
            order.subtotal = round(subTotal, numDecimals);
        }

        function round(value, decimals) {
            if(decimals == null){
                decimals = 2;
            }
            // return parseFloat(Math.round(value).toFixed(decimals));
            return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
        }

        function calculateEntry(order, entry, skipCustomerPriceList, numDecimals) {
            var price = null;

            //check customer price list
            if(skipCustomerPriceList!=true && order.customer!=null && order.customer.priceList!=null){
                var priceList = order.customer.priceList;
                if(priceList.active && priceList.discount!=null){
                    // price.price = price.price + (price.price * parseFloat(priceList.discount) / 100);
                    angular.forEach(entry.product.customerPriceList, function (customerPrice) {
                        if(customerPrice.priceList!=null && customerPrice.priceList.active && customerPrice.priceList.code == order.customer.priceList.code){
                            price = angular.copy(customerPrice);
                        }
                    })
                }
            }

            if(price == null) {
                price = angular.copy(entry.product.prices[0]);
            }

            //calculate totals
            var netPrice = calculateNetPrice(price, numDecimals);
            var grossPrice = calculateGrossPrice(price);
            entry.basePrice = netPrice;
            entry.totalPrice = netPrice * entry.quantity;
            entry.taxValue = ((grossPrice * 10 - netPrice * 10) / 10) * entry.quantity;
            entry.taxPercentage = price.tax.value;
            entry.currency = price.currency;
            return entry;
        }



        function calculateNetPrice(price, numDecimals) {
            if(!price.taxIncluded){
                return price.price;
            }
            var priceWithTax = price.price;
            var percentageValue = price.tax.value / 100 + 1;
            return round((priceWithTax / percentageValue), numDecimals);
        }

        function calculateGrossPrice(price) {
            if(price.taxIncluded){
                return price.price;
            }else{
                return price.price + price.price * (price.tax.value / 100)
            }
        }
    }
})();
