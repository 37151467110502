(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('FeedbackController', FeedbackController);

    FeedbackController.$inject = ['$scope', '$translate', '$state', '$uibModal', 'Feedback'];

    function FeedbackController ($scope, $translate, $state, $uibModal, Feedback) {
        var vm = this;


        vm.openModalFeedback = function () {
            var modal = $uibModal.open({
                templateUrl: "app/account/feedback/feedbackModal.html",
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('feedback');
                        return $translate.refresh();
                    }]
                },
                controller: [
                    "$scope",
                    function ($scope) {
                        $scope.sendFeedback = function () {
                            if($scope.feedbackMessage){
                                Feedback.save("Feedback sent from WEB.\n\n"+$scope.feedbackMessage, onSaveSuccess, onSaveError);
                            }else{
                                $scope.error = "Error";
                            }

                        }
                        function onSaveSuccess (result) {
                            $scope.$emit('wamaApp:feedbackUpdate', result);
                            modal.close(result);
                            vm.isSaving = false;
                        }

                        function onSaveError () {
                            vm.isSaving = false;
                            $scope.error = "Error";
                        }
                    }

                ]
            });
        };



    }
})();
