(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('PurchaseOrderStatusDeleteController',PurchaseOrderStatusDeleteController);

    PurchaseOrderStatusDeleteController.$inject = ['$uibModalInstance', 'entity', 'PurchaseOrderStatus'];

    function PurchaseOrderStatusDeleteController($uibModalInstance, entity, PurchaseOrderStatus) {
        var vm = this;
        vm.purchaseOrderStatus = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            PurchaseOrderStatus.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
