(function() {
    'use strict';

    angular
        .module('wamaApp')
        .config(employees);
    employees.$inject = ['$stateProvider'];

    function employees($stateProvider) {
        $stateProvider
            .state('employees', {
                parent: 'account',
                url: '/employees',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'settings.employees.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/account/settings/employees.html',
                        controller: 'EmployeesController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('settings');
                        $translatePartialLoader.addPart('user-management');
                        $translatePartialLoader.addPart('register');
                        $translatePartialLoader.addPart('subscription');
                        return $translate.refresh();
                    }]
                }
            })
            .state('employees.delete', {
                parent: 'employees',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/account/settings/employees-delete-dialog.html',
                        controller: 'EmployeesDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['UserId', function(UserId) {
                                return UserId.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function() {
                        $state.go('employees', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('employees.edit', {
                parent: 'employees',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/account/settings/employee-dialog.html',
                        controller: 'EmployeesDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['UserId', function(UserId) {
                                return UserId.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function() {
                        $state.go('employees', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('employees.resend', {
                parent: 'employees',
                url: '/{id}/resend',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/account/settings/employees-resend-dialog.html',
                        controller: 'EmployeesResendController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['UserId', function(UserId) {
                                return UserId.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function() {
                        $state.go('employees', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    });
                }]
            });
    }
})();
