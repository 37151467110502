(function () {
    'use strict';

    angular
        .module('wamaApp').component('palette', {
        templateUrl: 'app/components/util/palette.template.html',
        bindings: {
            preference: '=',
            eventName: '@'
        },
        controller: paletteController
    });

    paletteController.$inject = ['$scope'];

    function paletteController($scope) {
        var vm = this;
        vm.colors = ["#adb4cc", "#4ed1c8", "#e9275e", "#017467", "#fc5f09", "#000000", "#be1e2d", "#ffde17", "#fdd9e9", "#21409a", "#f0c86a", "#6d7371", "#ff9d3c", "#800909", "#b9bab6",
            "#002852", "#69b418", "#94cf98", "#c7ebf0", "#d4aad4", "#575a7a", "#5a7a57", "#c89008", "#d9534f", "#ee3425"];
        vm.paletteOpened = false;
        vm.setColor = function (color) {
            this.preference = color;
            vm.paletteOpened = false;
            $scope.$emit(this.eventName, color);
            /**
             * When the color is selected a new event is launched in case is need to react at the color selection
             */
        };
        vm.openClosePalette = function (status) {
            if(!status){
                vm.paletteOpened = !vm.paletteOpened;
            } else {
                vm.paletteOpened = status;
            }
        };
    }
})();
