(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('SupplierDialogController', SupplierDialogController);

    SupplierDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'Supplier', 'Address', 'PointOfSale', 'Country'];

    function SupplierDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, Supplier, Address, PointOfSale, Country) {
        var vm = this;
        vm.supplier = entity;
        vm.countries = Country.query();
        vm.pointOfSale = PointOfSale.get({id: 0}, preselectDefaultCountry, null);

        $scope.$watch('vm.supplier', function() {
            if(vm.supplier.priority==0){
                vm.supplier.priority=true;
            }
        }, true);


        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function preselectDefaultCountry(pointOfSale){
            //preselect default country
            if(vm.supplier != null && vm.supplier.id>0){
                return;
            }
            if(vm.supplier.address == null){
                vm.supplier.address = {};
            }
            if(pointOfSale.address != null && pointOfSale.address.country != null){
                vm.supplier.address.country = pointOfSale.address.country;
            }else if(company.address != null && company.address.country != null){
                vm.supplier.address.country = company.address.country;
            }
        }

        var onSaveSuccess = function (result) {
            $scope.$emit('wamaApp:supplierUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if(vm.supplier.priority){
                vm.supplier.priority=0;
            }
            if (vm.supplier.id !== null) {
                Supplier.update(vm.supplier, onSaveSuccess, onSaveError);
            } else {
                Supplier.save(vm.supplier, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.timeCreated = false;
        vm.datePickerOpenStatus.timeModified = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
    }
})();
