(function () {
    'use strict';

    angular
        .module('wamaApp')
        .controller('IntegrationsController', IntegrationsController);

    IntegrationsController.$inject = ['$scope', '$state', 'Auth'];

    function IntegrationsController($scope, $state, Auth) {
        var vm = this;
        vm.hasAccessRights = Auth.hasAccessRights;
    }
})();
