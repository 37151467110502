(function () {
    'use strict';

    angular
        .module('wamaApp')
        .controller('UserDeleteController', UserDeleteController);

    UserDeleteController.$inject = ['$uibModalInstance', '$rootScope', 'entity', 'User', 'Auth', '$state', 'UserId', 'ToastAlertService','$translate'];

    function UserDeleteController($uibModalInstance, $rootScope, entity, User, Auth, $state, UserId, ToastAlertService, $translate) {
        var vm = this;

        vm.user = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        vm.preConfirm = preConfirm;
        vm.disable = disable;

        vm.preconfirm = false;

        function preConfirm() {
            vm.preconfirm = true;
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        // ********* DELETE ***********

        function confirmDelete(user) {
            UserId.delete({id: user.id},
                function () {
                    $uibModalInstance.close(true);
                }, function (error) {
                    ToastAlertService.error({msg: $translate.instant(error.data.message)});
                });
        }


        // ********* DISABLE **********


        function disable(user) {
            user.activated = false;
            User.update(user, function (result) {
                if (!result.activated) {
                    $uibModalInstance.close(true);
                    Auth.logout();
                    $rootScope.USERNAME = "";
                    $state.go('home');
                }
            });
        };
    }
})();
