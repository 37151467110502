(function() {
    'use strict';

    angular
        .module('wamaApp')
        .factory('PricePlanSearch', PricePlanSearch);

    PricePlanSearch.$inject = ['$resource'];

    function PricePlanSearch($resource) {
        var resourceUrl =  'api/_search/price-plans/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
