(function () {
    'use strict';

    angular
        .module('wamaApp')
        .controller('ShopProductController', ShopProductController);

    ShopProductController.$inject = ['$scope', '$stateParams', '$state', 'entity', 'ShopSettings', 'Image', '$location'];

    function ShopProductController($scope, $stateParams, $state, entity, ShopSettings, Image, $location) {
        var vm = this;
        vm.product = entity;
        loadSettings();
        vm.openModalImage = Image.openModalImage
        vm.currencyHelpers = currencyHelpers;

        function loadSettings() {
            ShopSettings.get({id: $stateParams.pointOfSaleId}, function (result) {
                vm.shopSettings = result;
            });
        }

        $scope.$root.ld = {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": vm.product.name,
            "image": [
                vm.product.photo ? $location.protocol() + '://' + $location.host() + '/' + vm.product.photo : ""
            ],
            "gtin": vm.product.barcode,
            "description": vm.product.description,
            "sku": vm.product.code,
            "offers": {
                "@type": "Offer",
                "url": $location.absUrl(),
                "priceCurrency": vm.product.prices && vm.product.prices.length && vm.product.prices[0].currency ? vm.product.prices[0].currency.code : "",
                "price": vm.product.prices && vm.product.prices.length ? vm.product.prices[0].price : "",
                "availability": getLdAvailability()
            }
        }

        function getLdAvailability() {
            if (vm.product.reorderThreshold && vm.product.reorderThreshold > vm.product.quantity) {
                return "https://schema.org/LimitedAvailability"
            } else if (vm.product.quantity <= 0) {
                return "https://schema.org/OutOfStock"
            } else {
                return "https://schema.org/InStock"
            }
        }
    }
})();
