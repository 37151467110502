(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('StockTransferStatusDetailController', StockTransferStatusDetailController);

    StockTransferStatusDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'StockTransferStatus'];

    function StockTransferStatusDetailController($scope, $rootScope, $stateParams, entity, StockTransferStatus) {
        var vm = this;
        vm.stockTransferStatus = entity;
        
        var unsubscribe = $rootScope.$on('wamaApp:stockTransferStatusUpdate', function(event, result) {
            vm.stockTransferStatus = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
