(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('TimezoneDetailController', TimezoneDetailController);

    TimezoneDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Timezone', 'Country'];

    function TimezoneDetailController($scope, $rootScope, $stateParams, entity, Timezone, Country) {
        var vm = this;
        vm.timezone = entity;
        
        var unsubscribe = $rootScope.$on('wamaApp:timezoneUpdate', function(event, result) {
            vm.timezone = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
