(function() {
    'use strict';

    angular
        .module('wamaApp')
        .factory('OrderEntryStatusSearch', OrderEntryStatusSearch);

    OrderEntryStatusSearch.$inject = ['$resource'];

    function OrderEntryStatusSearch($resource) {
        var resourceUrl =  'api/_search/order-entry-statuses/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
