(function () {
    'use strict';

    angular
        .module('wamaApp')
        .controller('CustomerDetailController', CustomerDetailController);

    CustomerDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'previousState', 'SaleOrder', 'pagingParams', 'ParseLinks', 'SaleOrderStats','$translate'];

    function CustomerDetailController($scope, $rootScope, $stateParams, entity, previousState, SaleOrder, pagingParams, ParseLinks, SaleOrderStats, $translate) {
        var vm = this;
        vm.customer = entity;
        vm.previousState = previousState.name;
        vm.numItems = 15;

        loadSalesOrders(vm.customer);

        function loadSalesOrders(customer) {
            SaleOrder.query({
                    customer: customer.id,
                    page: pagingParams.page - 1,
                    size: vm.numItems,
                    sort: 'dateIssue,desc'
                },
                function (data, headers) {
                    vm.salesOrders = data;
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                    vm.saleOrders = data;
                    vm.page = pagingParams.page;
                });
        }


        vm.nextPageAvailable = function () {
            return pagingParams.page < vm.totalItems / vm.numItems;
        };

        vm.prevPageAvailable = function () {
            return pagingParams.page > 1;
        };

        vm.nextPage = function () {
            if (vm.nextPageAvailable()) {
                pagingParams.page = pagingParams.page + 1;
                loadSalesOrders(vm.customer);
            }
        };

        vm.prevPage = function () {
            if (vm.prevPageAvailable()) {
                pagingParams.page = pagingParams.page - 1;
                loadSalesOrders(vm.customer);
            }
        };


        var unsubscribe = $rootScope.$on('wamaApp:customerUpdate', function (event, result) {
            vm.customer = result;
        });
        $scope.$on('$destroy', unsubscribe);

        vm.currencyHelpers = currencyHelpers;

        //ORDERS STATISTICS
        vm.validateStartDate = function () {
            if (vm.startDate > vm.endDate) {
                vm.endDate = vm.startDate;
            }
            vm.dayDiff(vm.startDate, vm.endDate);
            setupMinMaxDate();
        };
        vm.validateEndDate = function () {
            if (vm.endDate < vm.startDate) {
                vm.startDate = vm.endDate;
            }
            vm.dayDiff(vm.startDate, vm.endDate);
            setupMinMaxDate();
        };

        vm.dayDiff = function (firstDate, secondDate) {
            var timeDiff = Math.abs(secondDate.getTime() - firstDate.getTime());
            vm.dayDifference = Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1;
        };

        function setupMinMaxDate() {
            vm.startDate.min = new Date(
                vm.startDate.getFullYear() - 1,
                vm.startDate.getMonth(),
                vm.startDate.getDate()
            );

            vm.startDate.max = new Date();
            vm.endDate.max = new Date();

            vm.dayDiff(vm.startDate, vm.endDate);
        }


        vm.getStatisticsToday = function () {
            vm.startDate = new Date();
            vm.endDate = new Date();
            vm.dayDiff(vm.startDate, vm.endDate);
            vm.getStatisticsForDateRange(vm.startDate, vm.endDate, 'today');
        };
        vm.getStatisticsYesterday = function () {
            var startDate = new Date();
            startDate.setDate(startDate.getDate() - 1);
            vm.startDate = startDate;
            vm.endDate = startDate;
            vm.dayDiff(vm.startDate, vm.endDate);
            vm.getStatisticsForDateRange(vm.startDate, vm.endDate, 'yesterday');

        };
        vm.getStatisticsLastSevenDays = function () {
            var startDate = new Date();
            startDate.setDate(startDate.getDate() - 6);
            vm.startDate = startDate;
            vm.endDate = new Date();
            vm.dayDiff(vm.startDate, vm.endDate);
            vm.getStatisticsForDateRange(vm.startDate, vm.endDate, 'sevendays');
        };

        vm.getStatisticsForDateRange = function (startDate, endDate, pressedButton) {
            vm.fetchingData = true;
            var salesOrdersPrice = [];
            var salesOrdersNumber = [];
            SaleOrderStats.get({customer: vm.customer.id, startDate: startDate, endDate: endDate}, function (data) {
                vm.fetchingData = false;
                vm.salesOrderStats = data;
                vm.salesOrdersTotalPrice = decodeURIComponent(data.totalPrice).replace('null', '').split(':');
                vm.salesOrdersAvgPrice = decodeURIComponent(data.avgPrice).replace('null', '').split(':');
                vm.salesOrdersTotalCount = data.totalCount;
                vm.salesOrdersAvgCount = data.avgCount;


                //GRAPHS
                angular.forEach(data.count, function(value, key){
                    var entry = {
                        "label": new Date(key),
                        "value": value
                    };
                    salesOrdersNumber.push(entry);
                });

                angular.forEach(data.price, function(value, key){
                    var entry = {
                        "label": new Date(key),
                        "value": value
                    };
                    salesOrdersPrice.push(entry);
                });


                vm.salesOrdersNumber = [{
                    key: "Sales Orders number",
                    values: salesOrdersNumber
                }];
                vm.salesOrdersPrice = [{
                    key: "Sales Orders total price",
                    values: salesOrdersPrice
                }];


            }, function () {
                vm.fetchingData = false;
            });
            vm.buttonStatsPeriod = pressedButton;
            setupMinMaxDate();
        };

        vm.options = {
            chart: {
                type: 'discreteBarChart',
                height: 450,
                margin: {
                    top: 20,
                    right: 20,
                    bottom: 50,
                    left: 55
                },
                x: function (d) {
                    return d.label;
                },
                y: function (d) {
                    return d.value + (1e-10);
                },
                showValues: false,
                valueFormat: function (d) {
                    return d3.format(',.0f')(d);
                },
                duration: 500,
                xAxis: {
                    rotateLabels: -60,
                    tickFormat: function (d) {
                        return d3.time.format('%e %b')(new Date(d));
                    }
                },
                yAxis: {
                    //axisLabel: 'Y Axis',
                    axisLabelDistance: -10
                },
                noData: $translate.instant('home.analytics.noData')
            }
        };

        vm.getStatisticsLastSevenDays();
    }
})();
