(function () {
    'use strict';

    angular
        .module('wamaApp')
        .controller('ModulesController', ModulesController);

    ModulesController.$inject = ['$scope', '$state', 'ToastAlertService', 'PointOfSale', 'Auth', '$translate', 'Preferences', 'SubscriptionActive'];

    function ModulesController($scope, $state, ToastAlertService, PointOfSale, Auth, $translate, Preferences, SubscriptionActive) {
        var vm = this;
        vm.loadAll = loadAll;
        vm.loadAll();
        vm.pointofsales = PointOfSale.query({page: 0, size: 99999, sort: "name"});
        loadSubscription();

        /******* Add here another property to save it automatically *******/
        vm.moduleSalesOrder = {};
        vm.modulePublicShop = {};
        vm.modulePriceList = {};
        vm.moduleLotNumbers = {};
        vm.moduleExpiryDates = {};
        var properties = {
            'MODULE_SALES_ORDER': vm.moduleSalesOrder,
            'MODULE_PUBLIC_SHOP': vm.modulePublicShop,
            'MODULE_PRICE_LIST': vm.modulePriceList,
            'MODULE_LOT_NUMBERS': vm.moduleLotNumbers,
            'MODULE_EXPIRY_DATES': vm.moduleExpiryDates
        };
        var defaultPropertiesValue = {
            'MODULE_SALES_ORDER': {
                'propertyType': 'BOOLEAN',
                'section': 'MODULES',
                'nameOnPreferences': 'moduleSalesOrder'
            },
            'MODULE_PUBLIC_SHOP': {
                'propertyType': 'BOOLEAN',
                'section': 'MODULES',
                'nameOnPreferences': 'modulePublicShop'
            },
            'MODULE_PRICE_LIST': {
                'propertyType': 'BOOLEAN',
                'section': 'MODULES',
                'nameOnPreferences': 'modulePriceList'
            },
            'MODULE_LOT_NUMBERS': {
                'propertyType': 'BOOLEAN',
                'section': 'MODULES',
                'nameOnPreferences': 'moduleLotNumbers'
            },
            'MODULE_EXPIRY_DATES': {
                'propertyType': 'BOOLEAN',
                'section': 'MODULES',
                'nameOnPreferences': 'moduleExpiryDates'
            }
        };
        angular.forEach(properties, function (value, key) {
            value.property = key;
            value.propertyType = defaultPropertiesValue[key]['propertyType'];
            value.section = defaultPropertiesValue[key]['section'];
            value.nameOnPreferences = defaultPropertiesValue[key]['nameOnPreferences'];
        });

        /******************* END ******************/
        // vm.requestCounter = Object.entries(properties).length;

        function loadAll() {

            Preferences.mapQuery({sections: 'MODULES'}, onSuccess, onError);

            function onSuccess(data) {
                angular.forEach(data, function (value, key) {
                    if (properties[key]) {
                        //found property
                        properties[key].id = value.id;
                        properties[key].property = (value.property) ? value.property : key;
                        properties[key].section = (value.section) ? value.section : defaultPropertiesValue[key]['section'];
                        properties[key].propertyType = (value.propertyType) ? value.propertyType : defaultPropertiesValue[key]['propertyType'];
                        if (value.value != null && (value.value.toUpperCase() === "TRUE" ||
                            value.value.toUpperCase() === "FALSE")) {
                            properties[key].value = value.value.toUpperCase() === "TRUE";
                        } else {
                            properties[key].value = value.value;
                        }
                    }
                });
            }

            function onError(error) {
                ToastAlertService.error(error.data.message);
            }
        }

        function loadSubscription() {
            SubscriptionActive.get(function (data) {
                vm.isPaidSubscription = "FREE" != data.plan.code;
            }, function () {
                vm.isPaidSubscription = false;
            });
        }

        vm.hasAccessRights = Auth.hasAccessRights;


    }
})();
