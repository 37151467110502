(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('PurchaseOrderStatusDetailController', PurchaseOrderStatusDetailController);

    PurchaseOrderStatusDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'PurchaseOrderStatus'];

    function PurchaseOrderStatusDetailController($scope, $rootScope, $stateParams, entity, PurchaseOrderStatus) {
        var vm = this;
        vm.purchaseOrderStatus = entity;
        
        var unsubscribe = $rootScope.$on('wamaApp:purchaseOrderStatusUpdate', function(event, result) {
            vm.purchaseOrderStatus = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
