(function() {
    'use strict';

    angular
        .module('wamaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('consent-withdraw', {
            parent: 'account',
            url: '/consent/withdraw/{type}?email&lang',
            data: {
                authorities: [],
                pageTitle: 'consent.title'
            },
            params: {
                action: 'withdraw'
            },
            views: {
                'content@': {
                    templateUrl: 'app/custom/consent/consent-withdraw.html',
                    controller: 'ConsentController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('consent');
                    return $translate.refresh();
                }]
            }
        }),
        $stateProvider.state('consent-give', {
            parent: 'account',
            url: '/consent/give/{type}?email',
            data: {
                authorities: [],
                pageTitle: 'consent.title'
            },
            params: {
                action: 'give'
            },
            views: {
                'content@': {
                    templateUrl: 'app/custom/consent/consent-give.html',
                    controller: 'ConsentController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('consent');
                    return $translate.refresh();
                }]
            }
        })
    }
})();
