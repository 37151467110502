(function () {
    'use strict';

    angular
        .module('wamaApp')
        .factory('EmployeeInvitation', EmployeeInvitation);

    EmployeeInvitation.$inject = ['$resource'];

    function EmployeeInvitation ($resource) {
        return $resource('api/employees/invitation/:id', {}, {});
    }
})();
