(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('ProductDetailPriceListDialogController', ProductDetailPriceListDialogController);

    ProductDetailPriceListDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'PriceList', 'Tax', 'Product'];

    function ProductDetailPriceListDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, PriceList, Tax, Product) {
        var vm = this;
        vm.product = entity;
        vm.taxes = Tax.query();
        vm.pricelists = PriceList.query({page: 0, size: 9999, sort: "name"});
        vm.price = {};

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('wamaApp:priceListUpdate', result);
            // $uibModalInstance.close(result);
            vm.addNewPrice = false;
            vm.isSaving = false;
            vm.product = result;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.savePrice = function () {
            vm.isSaving = true;
            if(vm.product.prices == null){
                vm.product.prices = [];
            }
            vm.product.prices.push(vm.price);
            Product.update(vm.product, onSaveSuccess, onSaveError);
        };

        vm.save = function () {
            vm.isSaving = true;
            // if (vm.priceList.id !== null) {
            //     PriceList.update(vm.priceList, onSaveSuccess, onSaveError);
            // } else {
            //     PriceList.save(vm.priceList, onSaveSuccess, onSaveError);
            // }
        };

        vm.deletePrice = function(priceToDelete){
            if(vm.product.prices != null){
                angular.forEach(vm.product.prices, function (price, key) {
                    if (price.id == priceToDelete.id) {
                        vm.product.prices.splice(key, 1);
                        Product.update(vm.product, onSaveSuccess, onSaveError);
                    }
                })
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.currencyHelpers = currencyHelpers;
        vm.taxHelpers = taxHelpers;
    }
})();
