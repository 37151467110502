(function() {
    'use strict';
    angular
        .module('wamaApp')
        .factory('PointOfSale', PointOfSale);

    PointOfSale.$inject = ['$resource'];

    function PointOfSale ($resource) {
        var resourceUrl =  'api/point-of-sales/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET', params:{query:'@query', startDate:'@startDate', endDate:'@endDate'},
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'head': {method:'HEAD'}
        });

    }
})();
