(function() {
    'use strict';

    angular
        .module('wamaApp')
        .factory('StockTransferReasonSearch', StockTransferReasonSearch);

    StockTransferReasonSearch.$inject = ['$resource'];

    function StockTransferReasonSearch($resource) {
        var resourceUrl =  'api/_search/stock-transfer-reasons/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
