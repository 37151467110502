(function() {
    'use strict';

    angular
        .module('wamaApp')
        .config(translationConfig)
        .run(siteSpecificTranslations);

    translationConfig.$inject = ['$translateProvider', 'tmhDynamicLocaleProvider'];

    function translationConfig($translateProvider, tmhDynamicLocaleProvider) {
        // Initialize angular-translate
        $translateProvider.useLoader('$translatePartialLoader', {
            urlTemplate: 'i18n/{lang}/{part}.json'
        });

        $translateProvider.preferredLanguage('en');
        $translateProvider.useStorage('translationStorageProvider');
        $translateProvider.useSanitizeValueStrategy('escaped');
        $translateProvider.addInterpolation('$translateMessageFormatInterpolation');

        tmhDynamicLocaleProvider.localeLocationPattern('i18n/angular-locale_{{locale}}.js');
        tmhDynamicLocaleProvider.useCookieStorage();
        tmhDynamicLocaleProvider.storageKey('NG_TRANSLATE_LANG_KEY');
    }

    siteSpecificTranslations.$inject = ['$rootScope', '$translate'];

    function siteSpecificTranslations($rootScope, $translate) {
        $rootScope.$on('$translateChangeSuccess', function () {
            $rootScope.getSiteTranslation = function(prefix, suffix){
                var host = location.hostname.replace("www","").replace(/^./, '').split(".")[0];
                var key = prefix + '.' + host + '.' + suffix;
                var value = $translate.instant(key);
                return value === key ? $translate.instant(prefix + '.wama.' + suffix) : value;
            }
        });


    }
})();
