(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('PaymentMethodDetailController', PaymentMethodDetailController);

    PaymentMethodDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'PaymentMethod'];

    function PaymentMethodDetailController($scope, $rootScope, $stateParams, entity, PaymentMethod) {
        var vm = this;
        vm.paymentMethod = entity;
        
        var unsubscribe = $rootScope.$on('wamaApp:paymentMethodUpdate', function(event, result) {
            vm.paymentMethod = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
