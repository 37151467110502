(function() {
    'use strict';

    angular
        .module('wamaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('stock-transfer-entry', {
            parent: 'entity',
            url: '/stock-transfer-entry?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'wamaApp.stockTransferEntry.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/stock-transfer-entry/stock-transfer-entries.html',
                    controller: 'StockTransferEntryController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('stockTransferEntry');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('stock-transfer-entry-detail', {
            parent: 'entity',
            url: '/stock-transfer-entry/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'wamaApp.stockTransferEntry.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/stock-transfer-entry/stock-transfer-entry-detail.html',
                    controller: 'StockTransferEntryDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('stockTransferEntry');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'StockTransferEntry', function($stateParams, StockTransferEntry) {
                    return StockTransferEntry.get({id : $stateParams.id});
                }]
            }
        })
        .state('stock-transfer-entry.new', {
            parent: 'stock-transfer-entry',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/stock-transfer-entry/stock-transfer-entry-dialog.html',
                    controller: 'StockTransferEntryDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                productName: null,
                                productCode: null,
                                basePrice: null,
                                totalPrice: null,
                                taxValue: null,
                                taxPercentage: null,
                                taxIncluded: null,
                                discountValue: null,
                                discountPercentage: null,
                                quantity: null,
                                quantityProcessed: null,
                                notes: null,
                                createdBy: null,
                                createdDate: null,
                                lastModifiedBy: null,
                                lastModifiedDate: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('stock-transfer-entry', null, { reload: true });
                }, function() {
                    $state.go('stock-transfer-entry');
                });
            }]
        })
        .state('stock-transfer-entry.edit', {
            parent: 'stock-transfer-entry',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/stock-transfer-entry/stock-transfer-entry-dialog.html',
                    controller: 'StockTransferEntryDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['StockTransferEntry', function(StockTransferEntry) {
                            return StockTransferEntry.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('stock-transfer-entry', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('stock-transfer-entry.delete', {
            parent: 'stock-transfer-entry',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/stock-transfer-entry/stock-transfer-entry-delete-dialog.html',
                    controller: 'StockTransferEntryDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['StockTransferEntry', function(StockTransferEntry) {
                            return StockTransferEntry.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('stock-transfer-entry', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
