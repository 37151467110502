(function() {
    'use strict';

    angular
        .module('wamaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('warehouse', {
                parent: 'account',
                url: '/warehouse',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'settings.warehouse.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/account/settings/warehouse.html',
                        controller: 'WarehouseController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('pointOfSale');
                        $translatePartialLoader.addPart('company');
                        $translatePartialLoader.addPart('tax');
                        $translatePartialLoader.addPart('currency');
                        $translatePartialLoader.addPart('preferences');
                        return $translate.refresh();
                    }]
                }
            })
            .state('warehouse.delete', {
                parent: 'warehouse',
                url: '/{warehouseId}/currency/{currencyId}/delete',
                data: {
                    authorities: ['ROLE_USER'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/account/settings/warehouse-delete-dialog.html',
                        controller: 'WarehouseDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['PointOfSale', function(PointOfSale) {
                                return PointOfSale.get({id : $stateParams.warehouseId});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('warehouse', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            })
            .state('warehouse.tax-new', {
                parent: 'warehouse',
                url: '/new',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/tax/tax-dialog.html',
                        controller: 'TaxDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    name: null,
                                    description: null,
                                    value: null,
                                    percentage: null,
                                    startValidity: null,
                                    endValidity: null,
                                    activated: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function() {
                        $state.go('warehouse', null, { reload: true });
                    }, function() {
                        $state.go('warehouse');
                    });
                }]
            })
            .state('warehouse.tax-edit', {
                parent: 'warehouse',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/tax/tax-dialog.html',
                        controller: 'TaxDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Tax', function(Tax) {
                                return Tax.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function() {
                        $state.go('warehouse', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('warehouse.tax-delete', {
                parent: 'warehouse',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/tax/tax-delete-dialog.html',
                        controller: 'TaxDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Tax', function(Tax) {
                                return Tax.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function() {
                        $state.go('warehouse', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('warehouse.deleteData', {
                parent: 'warehouse',
                url: '/deleteData',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'password.deleteAccount.title'
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal, $rootScope, User) {
                    $uibModal.open({
                        templateUrl: 'app/account/settings/company-data-delete-dialog.html',
                        controller: 'CompanyDeleteDataController',
                        controllerAs: 'vm',
                        size: 'md'
                    }).result.then(function (result) {
                        $state.go('warehouse', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    })
                }]
            });

    }
})();
