(function() {
    'use strict';

    angular
        .module('wamaApp')
        .factory('WamaStatsService', WamaStatsService);

    WamaStatsService.$inject = ['$resource'];

    function WamaStatsService ($resource) {
        var resourceUrl = 'management/jhipster/stats';
        return $resource(resourceUrl, {}, {
            'head': {method:'HEAD'},
            'changeLevel': { method: 'PUT'}
        });

    }
})();
