(function () {
    'use strict';

    angular
        .module('wamaApp')
        .controller('ElasticsearchReindexController', ElasticsearchReindexController);

    ElasticsearchReindexController.$inject = ['ElasticsearchReindex', 'AlertService'];

    function ElasticsearchReindexController(ElasticsearchReindex, AlertService) {
        var vm = this;

        vm.reindexSeCheckByBoxCompanyId = {
            checked: true
        };
        vm.reindexSOCheckByBoxCompanyId = {
            checked: true
        };
        vm.reindexSeCheckByBoxCompanyName = {
            checked: true
        };
        vm.reindexSOCheckByBoxCompanyName = {
            checked: false
        };
        vm.reindexSeCheckByUserEmail = {
            checked: true
        };


        vm.reindexEntity = reindexEntity;
        vm.reindexProductsByCompanyId = reindexProductsByCompanyId;
        vm.reindexProductsByCompanyName = reindexProductsByCompanyName;
        vm.reindexProductsByUserEmail = reindexProductsByUserEmail;

        function reindexEntity() {
            ElasticsearchReindex.post.reindex(null,{entity:vm.entity}, function () {
                AlertService.success("Success");
            }, function () {
                AlertService.error("Error");
            });
        }

        function reindexProductsByCompanyId(){
            ElasticsearchReindex.company.reindex(null,{companyId: vm.companyId, reindexStockEntries: vm.reindexSeCheckByBoxCompanyId.checked, reindexSalesOrders: vm.reindexSOCheckByBoxCompanyId.checked},function () {
                console.log("OK");
                AlertService.success("Success");
            }, function () {
                console.log("ERROR")
                AlertService.error("Error");
            });
        }

        function reindexProductsByCompanyName(){
            ElasticsearchReindex.company.reindex(null,{companyName: vm.companyName,reindexStockEntries: vm.reindexSeCheckByBoxCompanyName.checked, reindexSalesOrders: vm.reindexSOCheckByBoxCompanyName.checked},function () {
                AlertService.success("Success");
            }, function () {
                AlertService.error("Error");
            });
        }

        function reindexProductsByUserEmail(){
            ElasticsearchReindex.company.reindex(null,{userEmail: vm.userEmail,reindexStockEntries: vm.reindexSeCheckByUserEmail.checked},function () {
                AlertService.success("Success");
            }, function () {
                AlertService.error("Error");
            });
        }

    }
})();
