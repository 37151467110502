/**
 * Created by roberto on 20/06/17.
 */
(function() {
    'use strict';
    angular
        .module('wamaApp')
        .factory('PurchaseOrderInvoice', PurchaseOrderInvoice);

    PurchaseOrderInvoice.$inject = ['$resource', 'DateUtils'];

    function PurchaseOrderInvoice ($resource, DateUtils) {
        var resourceUrl =  'api/purchase-orders/:id/invoice';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.timeCreated = DateUtils.convertDateTimeFromServer(data.timeCreated);
                    data.timeModified = DateUtils.convertDateTimeFromServer(data.timeModified);
                    data.timeExpectedDelivery = DateUtils.convertDateTimeFromServer(data.timeExpectedDelivery);
                    data.timeRequestedDelivery = DateUtils.convertDateTimeFromServer(data.timeRequestedDelivery);
                    data.timeClosed = DateUtils.convertDateTimeFromServer(data.timeClosed);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
