/**
 * Created by roberto on 15/02/16.
 */

(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('WarehouseDeleteController',WarehouseDeleteController);

    WarehouseDeleteController.$inject = ['$stateParams', '$uibModalInstance', 'entity', 'PointOfSale'];

    function WarehouseDeleteController($stateParams, $uibModalInstance, entity, PointOfSale) {
        var vm = this;
        vm.pointOfSale = entity;
        vm.confirmDeleteCurrency = confirmDeleteCurrency;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        function confirmDeleteCurrency(pointOfSale){
            var currencyId = $stateParams.currencyId;
            if (pointOfSale && currencyId){
                angular.forEach(pointOfSale.currencies, function(currency,index){
                    if(currency.id == currencyId){
                        pointOfSale.currencies.splice(index, 1);
                    }
                });
                PointOfSale.update(pointOfSale, onSaveSuccess, onSaveError);
            }else{
                alert("Ops... Error!");
            }
        }

        var onSaveSuccess = function (result) {
            $uibModalInstance.close(true);
        };

        var onSaveError = function (result) {
            $uibModalInstance.close(true);
        };
    }
})();
