(function() {
    'use strict';

    angular
        .module('wamaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('price-plan', {
            parent: 'entity',
            url: '/price-plan?page&sort&search',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'wamaApp.pricePlan.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/price-plan/price-plans.html',
                    controller: 'PricePlanController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('pricePlan');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('price-plan-detail', {
            parent: 'entity',
            url: '/price-plan/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'wamaApp.pricePlan.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/price-plan/price-plan-detail.html',
                    controller: 'PricePlanDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('pricePlan');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'PricePlan', function($stateParams, PricePlan) {
                    return PricePlan.get({id : $stateParams.id});
                }]
            }
        })
        .state('price-plan.new', {
            parent: 'price-plan',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/price-plan/price-plan-dialog.html',
                    controller: 'PricePlanDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                value: null,
                                taxIncluded: null,
                                startValidity: null,
                                endValidity: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('price-plan', null, { reload: true });
                }, function() {
                    $state.go('price-plan');
                });
            }]
        })
        .state('price-plan.edit', {
            parent: 'price-plan',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/price-plan/price-plan-dialog.html',
                    controller: 'PricePlanDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['PricePlan', function(PricePlan) {
                            return PricePlan.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('price-plan', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('price-plan.delete', {
            parent: 'price-plan',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/price-plan/price-plan-delete-dialog.html',
                    controller: 'PricePlanDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['PricePlan', function(PricePlan) {
                            return PricePlan.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('price-plan', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
