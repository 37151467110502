(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('StockEntryDetailController', StockEntryDetailController);

    StockEntryDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'StockEntry', 'Product', 'PointOfSale', 'Supplier', 'Price', 'Location'];

    function StockEntryDetailController($scope, $rootScope, $stateParams, entity, StockEntry, Product, PointOfSale, Supplier, Price, Location) {
        var vm = this;
        vm.stockEntry = entity;
        
        var unsubscribe = $rootScope.$on('wamaApp:stockEntryUpdate', function(event, result) {
            vm.stockEntry = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
