(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('PriceListDialogController', PriceListDialogController);

    PriceListDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'PriceList'];

    function PriceListDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, PriceList) {
        var vm = this;
        vm.priceList = entity;
        if(vm.priceList.id==null){
            vm.priceList.active = true;
            vm.isDiscount = true;
        } else {
            vm.isDiscount = vm.priceList.discount < 0;
            vm.priceList.discount = Math.abs(vm.priceList.discount);
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('wamaApp:priceListUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.generateCodeFromName = function () {
            vm.priceList.code = vm.priceList.name.replace(/[^a-zA-Z0-9 ]/g, "").replace(/\s/g, '_').toUpperCase();
        };

        vm.save = function () {
            vm.isSaving = true;
            vm.priceList.discount = Math.abs(vm.priceList.discount);
            if(vm.isDiscount){
                vm.priceList.discount = -vm.priceList.discount;
            }
            if (vm.priceList.id !== null) {
                PriceList.update(vm.priceList, onSaveSuccess, onSaveError);
            } else {
                PriceList.save(vm.priceList, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
