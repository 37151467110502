(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('CustomerDialogController', CustomerDialogController);

    CustomerDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Customer', 'Country', '$rootScope', 'PriceList', 'PointOfSale', 'uiGmapGoogleMapApi', 'uiGmapIsReady'];

    function CustomerDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Customer, Country, $rootScope, PriceList, PointOfSale, uiGmapGoogleMapApi, uiGmapIsReady) {
        var vm = this;
        vm.customer = entity;
        vm.countries = Country.query();
        vm.pricelists = PriceList.query({page: 0, size: 9999, sort: "name"});
        vm.createAddress = false;

        function addAddress(address) {
            if(address.id==null){ //create negative ids for new addresses ad needed by track by in the html page
                var newId = -1;
                angular.forEach(vm.customer.addresses, function (addr) {
                    if(addr.id < 0){
                        newId = addr.id -1;
                    }
                });
                address.id= newId;
            }

            if(vm.customer.addresses == null){
                vm.customer.addresses = Array();
            }
            var replaced = false;
            angular.forEach(vm.customer.addresses, function(val, i) {
                if (val.id === address.id ) {
                    vm.customer.addresses[i] = address;
                    replaced = true;
                }
            });
            if(!replaced){
                vm.customer.addresses.push(address);
            }
        }

        vm.deleteAddress = function(addressIdToDelete) {
            angular.forEach(vm.customer.addresses, function (address, index) {
                if(address.id == addressIdToDelete){
                    vm.customer.addresses.splice(index, 1);
                }
            });
            vm.isDeletingAddress = false;
            vm.createAddress = false;
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            angular.forEach(vm.customer.addresses, function(address, i) {
                //reset negative ids
                if (address.id <0) {
                    vm.customer.addresses[i].id = null;
                }
            });
            if (vm.customer.id !== null) {
                Customer.update(vm.customer, onSaveSuccess, onSaveError);
            } else {
                Customer.save(vm.customer, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.timeCreated = false;
        vm.datePickerOpenStatus.timeModified = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };




        //ADDRESS FUNCTIONS
        $scope.mapRendering = true;
        // vm.address = entity;
        vm.address = {};
        vm.isDeletingAddress = false;

        vm.editAddress = function(address){
            vm.address = address;
            vm.createAddress = true;
        }
        vm.addAddress = function(){
            vm.address = {}
            vm.createAddress = true;
            preselectDefaultCountry(vm.pointOfSale);
            lookupAddress(vm.address);
        }
        vm.pointOfSale = PointOfSale.get({id: 0});

        function preselectDefaultCountry(pointOfSale){
            if(vm.address != null && vm.address.id>0){
                return;
            }
            //preselect default country
            if(pointOfSale.address!=null && pointOfSale.address.country!=null){
                vm.address.country = pointOfSale.address.country;
            }else if(company.address!=null && company.address.country!=null){
                vm.address.country = company.address.country;
            }
        }


        $scope.$watchGroup(["vm.address.postcode","vm.address.city", "vm.address.line1", "vm.address.country", "vm.createAddress"], function (newAddress, oldVal) {
            lookupAddress(newAddress);
        });

        function lookupAddress(newAddress){
            if (!angular.isUndefined(newAddress) && newAddress!=null && vm.createAddress){
                var address = (vm.address.city!=null ? vm.address.city: "") + " " + (vm.address.line1 != null ? vm.address.line1 : "") + " " + (vm.address.postcode != null ? vm.address.postcode : "") + (vm.address.country != null ? vm.address.country.name : "");
                uiGmapGoogleMapApi.then(function() {
                    geocodeAddress(address, gMapCallback);
                });
            }
        }

        // geocode the given address
        var geocodeAddress = function(address, callback) {
            var geocoder = new google.maps.Geocoder();
            geocoder.geocode( { 'address': address}, function(results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                    callback(results[0].geometry.location);
                } else {
                    console.log("Geocode was not successful for the following reason: " + status);
                }
            });
        };
        uiGmapIsReady.promise()
        .then(function(maps) {
        });

        function gMapCallback(latLng){
            $scope.mapRendering = false;
            $scope.map = { center: { latitude: latLng.lat(), longitude: latLng.lng() }, zoom: 8};
            // $scope.location = latLng;
            $scope.marker = {
                id: 0,
                coords: angular.copy($scope.map.center),
                options: { draggable: false },
                events: {
                    dragend: function (marker, eventName, args) {
                        $log.log('marker dragend');
                        var lat = marker.getPosition().lat();
                        var lon = marker.getPosition().lng();
                        $log.log(lat);
                        $log.log(lon);

                        $scope.marker.options = {
                            draggable: false,
                            labelContent: "lat: " + $scope.marker.coords.latitude + ' ' + 'lon: ' + $scope.marker.coords.longitude,
                            labelAnchor: "100 0",
                            labelClass: "marker-labels"
                        };
                    }
                }
            };
        }

        vm.saveAddress = function () {
            addAddress(vm.address)
            vm.createAddress = false;
        };

    }
})();
