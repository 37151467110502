(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('StockEntryReasonDialogController', StockEntryReasonDialogController);

    StockEntryReasonDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'StockEntryReason', 'PointOfSale'];

    function StockEntryReasonDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, StockEntryReason, PointOfSale) {
        var vm = this;
        vm.stockEntryReason = entity;
        vm.pointofsales = PointOfSale.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('wamaApp:stockEntryReasonUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.stockEntryReason.id !== null) {
                StockEntryReason.update(vm.stockEntryReason, onSaveSuccess, onSaveError);
            } else {
                StockEntryReason.save(vm.stockEntryReason, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
