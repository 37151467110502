(function() {
    'use strict';

    angular
        .module('wamaApp')
        .filter('roundup', RoundUpFilter);

    function RoundUpFilter(){
        return function (value) {
            return Math.ceil(value);
        };
    }
})();
