(function () {
    'use strict';

    angular
        .module('wamaApp')
        .controller('ImportExportController', ImportExportController);

    ImportExportController.$inject = ['$state', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'ProductImport', 'Upload', 'PointOfSale', 'Auth', 'Category',
        'Export', 'LocationImport', 'CustomerImport', 'PriceList', '$httpParamSerializer', '$http', '$filter'];

    function ImportExportController($state, ParseLinks, AlertService, pagingParams, paginationConstants, ProductImport, Upload, PointOfSale, Auth, Category,
                                    Export, LocationImport, CustomerImport, PriceList, $httpParamSerializer, $http, $filter) {
        var vm = this;
        vm.isSaving = false;
        vm.savingInProgress = false;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        //custom

        vm.exportData = Export.exportData;
        vm.exportAllData = Export.exportAllData;
        vm.exportProductCatalogOld = exportProductCatalogOld

        vm.currencyHelpers = currencyHelpers;
        vm.successImportConfirm = false;
        vm.successUpload = false;
        vm.errorMsg = false;
        vm.importInProgress = false;
        vm.oldExport = false;
        vm.pointOfSale = PointOfSale.get({id: 0}, onPosSuccess, null);
        vm.priceLists = PriceList.query({page: 0, size: 9999, sort: "name"});
        loadAllCategories();

        //****************** wama custom functions **************

        vm.importDataDiscard = importDataDiscard;
        vm.importTemplate = 'products';
        vm.changeImportTemplate = changeImportTemplate;
        changeImportTemplate();

        function changeImportTemplate() {
            vm.importDataDiscard();
            if (vm.importTemplate == 'products') {
                vm.productXLSXUrl = 'wamaApp.product.import.download.products.url.xlsx';
                vm.productXLSUrl = 'wamaApp.product.import.download.products.url.xls';
            } else if (vm.importTemplate == 'locations') {
                vm.productXLSXUrl = 'wamaApp.product.import.download.locations.url.xlsx';
                vm.productXLSUrl = 'wamaApp.product.import.download.locations.url.xls';
            } else if (vm.importTemplate == 'customers') {
                vm.productXLSXUrl = 'wamaApp.product.import.download.customers.url.xlsx';
                vm.productXLSUrl = 'wamaApp.product.import.download.customers.url.xls';
            }
        };

        vm.importData = function (file) {
            vm.importDataDiscard();
            var url = "";
            if (vm.importTemplate == 'products') {
                url = 'api/stock-entries/upload';
            } else if (vm.importTemplate == 'locations') {
                url = 'api/locations/upload';
            } else if (vm.importTemplate == 'customers') {
                url = 'api/customers/upload';
            }
            var currency;
            if (vm.currency && vm.importTemplate == 'products') {
                currency = vm.currency.id;
            }
            vm.importInProgress = true;
            if (file) {
                Upload.upload({
                    url: url,
                    data: {
                        file: file
                    },
                    params: {
                        currency: currency
                    },
                    isArray: true
                }).then(function (response) {
                    vm.importInProgress = false;
                    vm.successImportConfirm = false;
                    vm.successUpload = true;
                    vm.errorMsg = false;
                    if (vm.importTemplate == 'locations') {
                        vm.importedLocations = angular.fromJson(response.data);
                    } else if (vm.importTemplate == 'products') {
                        vm.importedProducts = angular.fromJson(response.data);
                    } else if (vm.importTemplate == 'customers') {
                        vm.importedCustomers = angular.fromJson(response.data);
                    }
                }, function (response) {
                    vm.importInProgress = false;
                    if (response.status > 0) {
                        vm.errorMsg = response.status + ': ' + response.data;
                        if (response.data.params != null && response.data.params[0] !== 'undefined' && response.data.params[0] !== null) {
                            vm.errorMsgCode = response.data.message;
                            vm.errorMsgDetails = response.data.params[0];
                            vm.errorMsgDetailsSecond = response.data.params.length > 1 ? response.data.params[1] : "";
                        }
                        vm.importedProducts = [];
                        vm.importedLocations = [];
                        vm.importedCustomers = [];
                    }
                }, function (evt) {
                    vm.progress =
                        Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
                });
            }
        };

        function importDataDiscard() {
            vm.importedProducts = [];
            vm.importedLocations = [];
            vm.importedCustomers = [];
            vm.successImportConfirm = false;
            vm.successUpload = false;
        };

        vm.importDataConfirm = function () {
            vm.isSaving = true;
            vm.savingInProgress = true;

            if (vm.importTemplate == 'products') {
                ProductImport.import(vm.importedProducts, onImportSuccess, onImportError);
            } else if (vm.importTemplate == 'locations') {
                LocationImport.import(vm.importedLocations, onImportSuccess, onImportError);
            } else if (vm.importTemplate == 'customers') {
                CustomerImport.import(vm.importedCustomers, onImportSuccess, onImportError);
            } else {
                AlertService.error("Template not recognized");
            }

            function onImportSuccess(result) {
                vm.isSaving = false;
                vm.savingInProgress = false;
                vm.importDataDiscard();
                vm.successImportConfirm = true;
                vm.errorMsg = false;
            }

            function onImportError(error) {
                vm.savingInProgress = false;
                vm.isSaving = false;
                // AlertService.error(error.data.message);
                vm.successImportConfirm = false;
            }
        };

        vm.importDataDelete = function (id) {
            var data = vm.importedProducts;
            angular.forEach(data, function (elem) {
                if (elem.product.id === id) {
                    var index = data.indexOf(elem);
                    if (index > -1) {
                        data.splice(index, 1);
                    }
                }
            });
            vm.successImportConfirm = false;
            vm.errorMsg = false;
        };

        vm.importLocationDataDelete = function (id) {
            removeEntryFromImportedData(vm.importedLocations, id);
        };

        vm.importCustomerDataDelete = function (id) {
            removeEntryFromImportedData(vm.importedCustomers, id);
        };

        function removeEntryFromImportedData(data, id) {
            angular.forEach(data, function (elem) {
                if (elem.id === id) {
                    var index = data.indexOf(elem);
                    if (index > -1) {
                        data.splice(index, 1);
                    }
                }
            });
            vm.successImportConfirm = false;
            vm.errorMsg = false;
        }

        vm.viewModeChange = function () {
            if (vm.viewMode === "category") {
                $state.go("product-category");
            }
        };

        function loadAllCategories() {
            Category.query({
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [name + ',' + 'asc'];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.categories = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function onPosSuccess(pointOfSale) {
            vm.currency = pointOfSale.currencies[0];
        }

        vm.limit = 15;
        vm.loadMore = function () {
            vm.limit = vm.limit + 10;
        };


        vm.fields = {
            code: true,
            photo: true,
            quantity: true,
            barcode: true,
            barcodeImage: true,
            price: true,
            description: true
        };


        // DATE PICKER

        vm.validateStartDate = function () {
            if (vm.startDate > vm.endDate) {
                vm.endDate = vm.startDate;
            }
            vm.dayDiff(vm.startDate, vm.endDate);
            setupMinMaxDate();
        };
        vm.validateEndDate = function () {
            if (vm.endDate < vm.startDate) {
                vm.startDate = vm.endDate;
            }
            vm.dayDiff(vm.startDate, vm.endDate);
            setupMinMaxDate();

        };

        vm.dayDiff = function (firstDate, secondDate) {
            var timeDiff = Math.abs(secondDate.getTime() - firstDate.getTime());
            var daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
            vm.dayDifference = daysDiff != null && daysDiff > 0 ? daysDiff : "";
        };

        function setupMinMaxDate() {
            vm.startDate.min = new Date(
                vm.startDate.getFullYear() - 1,
                vm.startDate.getMonth(),
                vm.startDate.getDate()
            );

            vm.startDate.max = new Date();
            vm.endDate.max = new Date();

            vm.dayDiff(vm.startDate, vm.endDate);

        }

        vm.setDatesToday = function () {
            vm.startDate = new Date();
            vm.endDate = new Date();
            vm.dayDiff(vm.startDate, vm.endDate);
            vm.buttonStatsPeriod = 'today';
            setupMinMaxDate();
        };
        vm.setDatesLastMonth = function () {
            var startDate = new Date();
            startDate.setDate(startDate.getDate() - 30);
            vm.startDate = startDate;
            vm.endDate = new Date();
            vm.dayDiff(vm.startDate, vm.endDate);
            vm.buttonStatsPeriod = 'thirthydays';
            setupMinMaxDate();
        };
        vm.setDatesLastSevenDays = function () {
            var startDate = new Date();
            startDate.setDate(startDate.getDate() - 6);
            vm.startDate = startDate;
            vm.endDate = new Date();
            vm.dayDiff(vm.startDate, vm.endDate);
            vm.buttonStatsPeriod = 'sevendays';
            setupMinMaxDate();
        };

        function exportProductCatalogOld (fields, priceList) {
            vm.isSaving = true;
            var url = '/api/products/catalog?' + $httpParamSerializer(fields);
            if (priceList) {
                url = url + '&priceList=' + priceList;
            }
            $http.get(url, {responseType: 'arraybuffer'})
                .success(function (data) {
                    vm.isSaving = false;
                    var blob = new Blob([data], {
                        type: '"application/pdf"'
                    });
                    var currentDate = $filter('date')(new Date(), "yyyy-MM-dd_HH:mm:ss");
                    var filename = "Catalog_" + currentDate + ".pdf";
                    saveAs(blob, filename);
                });
        }


        vm.setDatesLastSevenDays();

        vm.hasAccessRights = Auth.hasAccessRights;
    }
})();
