(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('DeliveryNoteDetailController', DeliveryNoteDetailController);

    DeliveryNoteDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'DeliveryNote', 'Dealer', 'Supplier', 'PointOfSale'];

    function DeliveryNoteDetailController($scope, $rootScope, $stateParams, entity, DeliveryNote, Dealer, Supplier, PointOfSale) {
        var vm = this;
        vm.deliveryNote = entity;
        
        var unsubscribe = $rootScope.$on('wamaApp:deliveryNoteUpdate', function(event, result) {
            vm.deliveryNote = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
