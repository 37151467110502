(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('StockTransferMethodDetailController', StockTransferMethodDetailController);

    StockTransferMethodDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'StockTransferMethod', 'PointOfSale'];

    function StockTransferMethodDetailController($scope, $rootScope, $stateParams, entity, StockTransferMethod, PointOfSale) {
        var vm = this;
        vm.stockTransferMethod = entity;
        
        var unsubscribe = $rootScope.$on('wamaApp:stockTransferMethodUpdate', function(event, result) {
            vm.stockTransferMethod = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
