'use strict';

angular.module('wamaApp')
    .controller('CashDetailController', function ($scope, $rootScope, $stateParams, entity, Sale, User, PointOfSale, PaymentMethod, Currency) {
        $scope.sale = entity;
        $scope.load = function (id) {
            Sale.get({id: id}, function(result) {
                $scope.sale = result;
            });
        };
        $rootScope.$on('wamaApp:cashUpdate', function(event, result) {
            $scope.sale = result;
        });
    });
