(function() {
    'use strict';
    angular
        .module('wamaApp')
        .factory('Subscription', Subscription);

    Subscription.$inject = ['$resource', 'DateUtils'];

    function Subscription ($resource, DateUtils) {
        var resourceUrl =  'api/subscriptions/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.timePayment = DateUtils.convertDateTimeFromServer(data.timePayment);
                    data.validityStartTime = DateUtils.convertDateTimeFromServer(data.validityStartTime);
                    data.validityExpireTime = DateUtils.convertDateTimeFromServer(data.validityExpireTime);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
