(function() {
    'use strict';
    angular
        .module('wamaApp')
        .factory('ProductBarcodeGeneration', ProductBarcodeGeneration);

    ProductBarcodeGeneration.$inject = ['$resource', 'DateUtils'];

    function ProductBarcodeGeneration ($resource) {
        var resourceUrl =  'api/products/barcode';

        return $resource(resourceUrl, {}, {
            'get': { method: 'GET', isArray: true }
        });
    };
})();

