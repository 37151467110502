(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('SaleOrderEntryDetailController', SaleOrderEntryDetailController);

    SaleOrderEntryDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'SaleOrderEntry', 'Product', 'SaleOrder', 'User', 'Currency', 'OrderEntryStatus', 'PointOfSale'];

    function SaleOrderEntryDetailController($scope, $rootScope, $stateParams, entity, SaleOrderEntry, Product, SaleOrder, User, Currency, OrderEntryStatus, PointOfSale) {
        var vm = this;
        vm.saleOrderEntry = entity;
        
        var unsubscribe = $rootScope.$on('wamaApp:saleOrderEntryUpdate', function(event, result) {
            vm.saleOrderEntry = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
