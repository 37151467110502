(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('CompanyDeleteController',CompanyDeleteController);

    CompanyDeleteController.$inject = ['$uibModalInstance', 'entity', 'Company'];

    function CompanyDeleteController($uibModalInstance, entity, Company) {
        var vm = this;
        vm.company = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            Company.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
