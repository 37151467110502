(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('EmployeesController', EmployeesController);

    EmployeesController.$inject = ['$scope', '$state', 'ToastAlertService', 'PointOfSale', 'Auth', '$translate', 'Employee'];

    function EmployeesController ($scope, $state, ToastAlertService, PointOfSale, Auth, $translate, Employee) {
        var vm = this;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.transition = transition;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll();
        vm.register = register;
        vm.pointofsales = PointOfSale.query({page: 0, size: 99999, sort: "name"});

        vm.registerAccount = {
            "firstName": null,
            "lastName": null,
            "email": null,
            "authorities": ['ROLE_OPERATOR']
        };


        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }


        function register () {
            if (vm.registerAccount.email !== vm.emailConfirm) {
                vm.doNotMatch = 'ERROR';
            } else {
                vm.registerAccount.langKey = $translate.use();
                vm.doNotMatch = null;
                vm.error = null;
                vm.errorUserExists = null;
                vm.errorEmailExists = null;
                vm.registerAccount.login = vm.registerAccount.email;
                vm.registerAccount.password = "notused";

                Auth.createEmployeeAccount(vm.registerAccount).then(function (result) {
                    vm.loadAll();
                    vm.success = 'OK';
                    vm.registerAccount = {
                        "firstName": null,
                        "lastName": null,
                        "email": null,
                        "authorities": Array()
                    };
                    $scope.$emit('wamaApp:employeeUpdate', result);
                }).catch(function (response) {
                    vm.success = null;
                    var errorMsg = "ERROR";
                    if (response.status === 400 && response.data === 'login already in use') {
                        errorMsg= "register.messages.error.userexists";
                    } else if (response.status === 400 && response.data === 'e-mail address already in use') {
                        errorMsg= "register.messages.error.userexists";
                    } else {
                        errorMsg = response.data.message;
                    }
                    ToastAlertService.show({type: ToastAlertService.error, msg: $translate.instant(errorMsg)});
                });
            }


        }

        function loadAll () {

            Employee.query(null , onSuccess, onError);

            function onSuccess(data, headers) {
                vm.employees = data;
            }
            function onError(error) {
                ToastAlertService.error(error.data.message);
            }
        }

        vm.hasAccessRights = Auth.hasAccessRights;

    }
})();
