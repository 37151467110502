(function () {
    'use strict';

    angular
        .module('wamaApp')
        .factory('translationStorageProvider', translationStorageProvider);

    translationStorageProvider.$inject = ['$cookies', '$log', 'LANGUAGES'];

    function translationStorageProvider($cookies, $log, LANGUAGES) {
        return {
            get: get,
            put: put
        };

        function get(name) {
            var customLanguages = LANGUAGES;
            var browserLang = navigator.language || navigator.systemLanguage;
            var lang = browserLang.toLowerCase();
            var preferredLanguage = 'en';
            //defaulting new visitor language to browser language if supported
            if (customLanguages.indexOf(lang) !== -1) {
                preferredLanguage = lang;
            }

            if (LANGUAGES.indexOf($cookies.getObject(name)) === -1) {
                $log.info('Resetting invalid cookie language "' + $cookies.getObject(name) + '" to prefered language "' + preferredLanguage + '"');
                $cookies.putObject(name, preferredLanguage);
            }
            return $cookies.getObject(name);
        }

        function put(name, value) {
            $cookies.putObject(name, value);
        }
    }
})();
