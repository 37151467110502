(function() {
    'use strict';

    angular
        .module('wamaApp')
        .config(aboutHelp);

    aboutHelp.$inject = ['$stateProvider'];

    function aboutHelp($stateProvider) {
        $stateProvider.state('about-help', {
            parent: 'account',
            url: '/about-help',
            data: {
                authorities: ['ROLE_USER','ROLE_OPERATOR','ROLE_SALES_OPERATOR','ROLE_WAREHOUSE_ASSISTANT'],
                pageTitle: 'global.menu.account.settings'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/settings/settings.about-help.html',
                    controller: 'SettingsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('settings');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
