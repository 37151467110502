(function () {
    'use strict';

    angular
        .module('wamaApp')
        .controller('LoyaltyCardDialogController', LoyaltyCardDialogController);

    LoyaltyCardDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'LoyaltyCard', 'LoyaltyCardType', 'Customer'];

    function LoyaltyCardDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, LoyaltyCard, LoyaltyCardType, Customer) {
        var vm = this;
        vm.loyaltyCard = entity;
        vm.loyaltycardtypes = LoyaltyCardType.query();
        vm.customers = Customer.query({page: 0, size: 99999, sort: "name"});

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
            if (vm.loyaltyCard && !vm.loyaltyCard.loyaltyCardTypeId && vm.loyaltycardtypes.length) {
                vm.loyaltyCard.loyaltyCardTypeId = vm.loyaltycardtypes[0].id;
            }
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('wamaApp:loyaltyCardUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.loyaltyCard.id !== null) {
                LoyaltyCard.update(vm.loyaltyCard, onSaveSuccess, onSaveError);
            } else {
                LoyaltyCard.save(vm.loyaltyCard, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function () {
            $uibModalInstance.dismiss('cancel');
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.expiryDate = false;
        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.lastModifiedDate = false;

        vm.openCalendar = function (date) {
            vm.datePickerOpenStatus[date] = true;
        };

        vm.formatCustomerName = function (customer) {
            if (customer != null) {
                return customer.name + " " + customer.surname;
            }
            return "";
        }
    }
})();
