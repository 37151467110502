(function () {
    'use strict';

    angular
        .module('wamaApp').directive('richcard', ['$sce', '$filter', function ($sce, $filter) {
        return {
            restrict: 'EA',
            link: function ($scope, element) {
                $scope.$watch('ld', function (val) {
                    var val = $sce.trustAsHtml($filter('json')(val));
                    element[0].outerHTML = '<script type="application/ld+json">' + val + '</script>'
                });
            }
        };
    }]);
})();
