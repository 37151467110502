(function () {
    'use strict';
    angular
        .module('wamaApp')
        .factory('CompanyPreferences', CompanyPreferences);

    CompanyPreferences.$inject = ['$localStorage'];

    function CompanyPreferences($localStorage) {
        return {
            savePreferencesToLocalStorage: savePreferencesToLocalStorage,
            savePreferenceToLocalStorage: savePreferenceToLocalStorage,
            getPreferencesFromLocalStorage: getPreferencesFromLocalStorage
        };

        function savePreferencesToLocalStorage(preferences) {
            angular.forEach(preferences, function (preference) {
                savePreferenceToLocalStorage(preference);
            });
        }

        function savePreferenceToLocalStorage(preference){
            if($localStorage.preferences == null){
                $localStorage.preferences = {};
            }
            if(preference.property == "MODULE_LOT_NUMBERS"){
                $localStorage.preferences.lotNumbers = preference.valueBoolean;
            }
            if(preference.property == "MODULE_EXPIRY_DATES"){
                $localStorage.preferences.expiryDates = preference.valueBoolean;
            }
            if(preference.property == "MODULE_PRICE_LIST"){
                $localStorage.preferences.priceList = preference.valueBoolean;
            }
            if(preference.property == "MODULE_SALES_ORDER"){
                $localStorage.preferences.salesOrder = preference.valueBoolean;
            }
        }

        function getPreferencesFromLocalStorage() {
            return $localStorage.preferences;
        }
    }
})();
