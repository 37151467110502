(function () {
    'use strict';

    angular
        .module('wamaApp')
        .controller('ShopifyShopSyncDialogController', ShopifyShopSyncDialogController);

    ShopifyShopSyncDialogController.$inject = ['$uibModalInstance', 'ShopifyStockSync', '$stateParams'];

    function ShopifyShopSyncDialogController($uibModalInstance, ShopifyStockSync, $stateParams) {
        var vm = this;
        vm.pointOfSaleId = $stateParams.pointOfSaleId;
        vm.shop = $stateParams.shop;
        vm.isSaving = false;
        vm.clear = function () {
            $uibModalInstance.dismiss('cancel');
        };

        vm.syncProductsStock = function (shop, pointOfSaleId) {
            ShopifyStockSync.save({shop: shop, pointOfSaleId: pointOfSaleId}, onSuccess, onError);
        }

        function onSuccess(product) {
            vm.isSaving = false;
            $uibModalInstance.close(product);
        }

        function onError() {
            vm.isSaving = false;
        }
    }
})();
