(function() {
    'use strict';

    //works only in combination with CTRL pressed
    angular
        .module('wamaApp').directive('hotkey', function() {
        return {
            link: function(scope, element, attrs) {
                var config = scope.$eval(attrs.hotkey)
                angular.forEach(config, function(value, key) {
                    angular.element(window).on('keydown', function(e) {
                        if (e.keyCode === Number(key)  && e.altKey) {
                            element.triggerHandler(value)
                        }
                    })
                })
            }
        };
    });
})();
