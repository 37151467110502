(function () {
    'use strict';

    angular
        .module('wamaApp')
        .factory('ElasticsearchReindex', ElasticsearchReindex);

    ElasticsearchReindex.$inject = ['$resource'];

    function ElasticsearchReindex($resource) {
        return {
            post: $resource('api/elasticsearch/index/:entity', {}, {
                'reindex': {method: 'POST', params:{entity:'@entity'}}
            }),
            company: $resource('api/elasticsearch/index/company',{},{
                'reindex': {
                    method: 'GET',
                    params: {
                        companyId:'@companyId',
                        companyName: '@companyName',
                        email:'@userEmail',
                        reindexStockEntries:'@reindexStockEntries',
                        reindexSalesOrders:'@reindexSalesOrders'
                    }
                }
            })
        };
    }
})();
