(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('ProductBarcodeController', ProductBarcodeController);

    ProductBarcodeController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Auth', '$http', '$filter', '$uibModalInstance', 'ToastAlertService', 'pagingParams', 'Product',
    'ProductSearch', 'ParseLinks', 'Category', '$translate'];

    function ProductBarcodeController($scope, $rootScope, $stateParams, entity, Auth, $http, $filter, $uibModalInstance, ToastAlertService, pagingParams, Product, ProductSearch, ParseLinks,
                                      Category, $translate) {
        var vm = this;
        vm.product = entity;
        vm.exportPDFBarcode = exportPDFBarcode;
        vm.downloadProductsBarcode = downloadProductsBarcode;
        vm.loadAll = loadAll;
        vm.loadAllCategories = loadAllCategories;
        vm.loadByCategory = loadByCategory;
        vm.search = search;
        vm.clearSearch = clearSearch;
        if(pagingParams) {
            vm.searchQuery = pagingParams.search;
            vm.currentSearch = pagingParams.search;
        }
        vm.numItems = 8;
        vm.selectedProducts = [];
        vm.maxProducts = 15;
        vm.isSaving = false;
        vm.allProducts = false;
        vm.customWidth = null;
        vm.customHeight = null;
        vm.customMargin = null;
        vm.formats = [{id: 1, size: "A4", translation: "a4", margin: "10mm", img: "barcodeA4.png"},
            {id: 2, size: "110mm 62mm", translation: "110x62", margin: "3mm", img: "barcode110.png"},
            {id: 3, size: null, translation: "custom", margin: null, img: "barcodeCustom.png"}]
        vm.selectedFormat = 1;


        vm.bc = {
            format: 'CODE128',
            lineColor: '#000000',
            width: 2,
            height: 100,
            displayValue: true,
            fontOptions: '',
            font: 'monospace',
            textAlign: 'center',
            textPosition: 'bottom',
            textMargin: 2,
            fontSize: 20,
            background: '#ffffff',
            margin: 0,
            marginTop: undefined,
            marginBottom: undefined,
            marginLeft: undefined,
            marginRight: undefined,
            valid: function (valid) {
            }
        };
        vm.showCode=true;
        vm.showName=true;
        vm.showPrice=true;
        vm.onOffer=false;
        vm.showBorder=false;
        vm.numLabels="1";

        vm.selectAllProducts = function () {
            if(!vm.allProducts){
                vm.numLabels = "1";
            }
            vm.allProducts = !vm.allProducts;
        }
        // EXPORT SINGLE PRODUCT PDF
        function exportPDFBarcode(){
            vm.selectedProducts = [vm.product];
            downloadProductsBarcode();
        }

        // EXPORT MULTIPLE PRODUCTS PDF
        function downloadProductsBarcode(){
            var productIds=[];
            angular.forEach(vm.selectedProducts, function(product){
                productIds.push(product.id)
            });
            vm.error = validateCustomFormat();
            if(vm.error!=null){
                return
            }
            var selectedFormat = getFormatById(+vm.selectedFormat);

            vm.isSaving = true;
            $http.post('/api/products/PDFBarcode?' +
                'numLabels=' + vm.numLabels+
                '&showCode=' + vm.showCode+
                '&showName=' + vm.showName +
                '&showPrice=' + vm.showPrice+
                '&onOffer=' + vm.onOffer+
                '&showBorder=' + vm.showBorder +
                '&pageFormat=' + (selectedFormat != null ? selectedFormat.size : null) +
                '&pageMargin=' + (selectedFormat != null ? selectedFormat.margin : null) +
                '&allProducts=' + vm.allProducts,
                JSON.stringify(productIds),
                {responseType: 'arraybuffer'})
                .success(function (data) {
                    vm.isSaving = false;
                    var blob = new Blob([data], {
                        type: 'application/pdf'
                    });
                    var currentDate = $filter('date')(new Date(), "yyyy-MM-dd_HH:mm:ss");
                    var filename = "Label_" + currentDate + ".pdf";
                    saveAs(blob, filename);
                })
                .error(function () {
                    vm.isSaving = false;
                });
        }
        function validateCustomFormat(){
            if(vm.selectedFormat==3 && (vm.customWidth <= vm.customMargin * 2 || vm.customHeight <= vm.customMargin * 2)) {
                return 'wamaApp.product.barcodeDialog.format.error.marginTooBig'
            }
            return null;
        }

        vm.printImg = function(imgName) {
            var printContents = document.getElementsByClassName(imgName)[0];
            if(printContents == null){
                alert("Error");
                return;
            }
            var popupWin = window.open();
            popupWin.document.open();
            popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="style.css" /></head><body onload="window.print()"><img src="' + printContents.src + '" /></html>');
            popupWin.document.close();
        };

        vm.addToSelectedProducts = function (product) {
            vm.allProducts = false;
            if(vm.selectedProducts.length >= vm.maxProducts){
                ToastAlertService.error({msg: $translate.instant("wamaApp.product.barcodeDialog.pdf.maxProducts")});
                return;
            }
            if(product.barcode != null && product.barcode.trim().length!="" && !includesProduct(product) ){
                vm.selectedProducts.push(product);
            } else {
                ToastAlertService.error({msg: $translate.instant("wamaApp.product.barcodeDialog.noBarcode")});
            }
        };

        vm.removeFromSelectedProducts = function (product) {
            angular.forEach(vm.selectedProducts, function(entry, key){
                if(entry.id == product.id){
                    vm.selectedProducts.splice(key, 1);
                }
            });
        };

        vm.selectedProductFilter = function(product){
            return !includesProduct(product);
        };

        function includesProduct(product){
            var result = false;
            _.forEach(vm.selectedProducts, function (selectedProduct) {
                if(selectedProduct.id == product.id){
                    result = true;
                }
            });
            return result;
        }

        function loadAll() {
            vm.selectedCategory = null;
            if (pagingParams.search) {
                ProductSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.numItems,
                    sort: 'name,asc',
                    priceList: true
                }, onSuccess, onError);
            } else {
                Product.query({
                    page: pagingParams.page - 1,
                    size: vm.numItems,
                    sort: pagingParams.sort,
                    outOfStock: pagingParams.outOfStock,
                    priceList: false
                }, onSuccess, onError);
            }
        }

        function loadByCategory(category, buttonClicked) {
            if(buttonClicked){
                pagingParams.page = 1;
            }
            if(vm.selectedCategory!= null && category.id == vm.selectedCategory.id && buttonClicked){
                vm.selectedCategory = null;
                loadAll();
            } else {
                vm.selectedCategory = category;
                Product.query({
                    page: pagingParams.page - 1,
                    size: vm.numItems,
                    sort: 'name,asc',
                    outOfStock: pagingParams.outOfStock,
                    category: category.id
                }, onSuccess, onError);
            }
        }


        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            // vm.queryCount = vm.totalItems;
            vm.products = _.difference(data, vm.selectedProducts, productComparator);
            vm.queryCount = vm.products.length;
            vm.page = pagingParams.page;
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clearSearch();
            }
            vm.page = 1;
            vm.currentSearch = searchQuery;
            pagingParams.search = searchQuery
            vm.loadAll();
        }

        function clearSearch () {
            vm.page = 1;
            pagingParams.search = null;
            vm.currentSearch = null;
            vm.loadAll();
        }

        function productComparator(a, b) {
            return a.id === b.id;
        }

        function onError(error) {
            ToastAlertService.error(error.data.message);
        }


        vm.nextPageAvailable = function(){
            return pagingParams.page < vm.totalItems / vm.numItems;
        };

        vm.prevPageAvailable = function(){
            return pagingParams.page > 1;
        };

        vm.nextPage = function () {
            if(vm.nextPageAvailable()){
                pagingParams.page = pagingParams.page + 1;
                if(vm.selectedCategory == null){
                    loadAll();
                }else {
                    vm.loadByCategory(vm.selectedCategory, false);
                }
            }
        };

        function loadAllCategories() {
            Category.query({
                page: 0,
                size: 9000,
                sort: "name,asc"
            }, onCategoriesSuccess, onError)
        }


        vm.prevPage = function () {
            if(vm.prevPageAvailable()) {
                pagingParams.page = pagingParams.page - 1;
                if(vm.selectedCategory == null){
                    loadAll();
                }else {
                    vm.loadByCategory(vm.selectedCategory, false);
                }
            }
        };

        function onCategoriesSuccess(data, headers) {
            vm.totalCategories = headers('X-Total-Count');
            vm.categories = data;
        }

        function getFormatById(id){
            var result = null
            vm.formats.forEach(function (value) {
                if(value.id === id){
                    result = value;
                }
            })
            if(result!=null && result.id===+3){
                result = {id: 3, size: Math.abs(vm.customWidth) + "mm " + Math.abs(vm.customHeight) + "mm", margin: Math.abs(vm.customMargin) + "mm"}
            }
            return result;
        }

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.hasAccessRights = Auth.hasAccessRights;
    }
})();
