(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('StockTransferDetailController', StockTransferDetailController);

    StockTransferDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', '$http', '$filter', 'Auth'];

    function StockTransferDetailController($scope, $rootScope, $stateParams, entity, $http, $filter, Auth) {
        var vm = this;
        vm.stockTransfer = entity;

        var unsubscribe = $rootScope.$on('wamaApp:stockTransferUpdate', function(event, result) {
            vm.stockTransfer = result;
        });
        $scope.$on('$destroy', unsubscribe);

        vm.stockTransferCalculationHelpers = stockTransferCalculationHelpers;
        vm.currencyHelpers = currencyHelpers;
        vm.taxHelpers = taxHelpers;
        vm.stockTransferStatusColor = stockTransferStatusColor;

        $scope.$watch('vm.stockTransfer.id', function () {
            if(null != vm.stockTransfer.id) {
                vm.stockTransfer.subtotal = $filter('currency')(vm.stockTransfer.subtotal, vm.currencyHelpers.currencyDisplay(vm.stockTransfer.currency), 2);
                vm.stockTransfer.taxValue = $filter('currency')(vm.stockTransfer.taxValue, vm.currencyHelpers.currencyDisplay(vm.stockTransfer.currency), 2);
                vm.stockTransfer.total = $filter('currency')(vm.stockTransfer.total, vm.currencyHelpers.currencyDisplay(vm.stockTransfer.currency), 2);
            }
        },true);

        vm.downloadStockTransfer = downloadStockTransfer;

        function downloadStockTransfer (stockTransfer) {
            var timezone = encodeURIComponent(Intl.DateTimeFormat().resolvedOptions().timeZone);
            $http.get('/api/stock-transfers/'+ stockTransfer.id +'/pdf?timezone=' + timezone, {responseType: 'arraybuffer'})
                .success(function (data) {
                    var blob = new Blob([data], {
                        type: 'application/pdf'
                    });
                    var currentDate = $filter('date')(new Date(), "yyyy-MM-dd_HH:mm:ss");
                    var filename = "DDT_" + stockTransfer.code ? stockTransfer.code : stockTransfer.id + "-"  + currentDate + ".pdf";
                    saveAs(blob, filename);
                    vm.isSaving = false;
                }).error(function () {
                vm.isSaving = false;
            });
        }

        vm.hasAccessRights = Auth.hasAccessRights;

    }
})();
