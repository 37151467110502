(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('StockTransferGoodsDescriptionDetailController', StockTransferGoodsDescriptionDetailController);

    StockTransferGoodsDescriptionDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'StockTransferGoodsDescription', 'PointOfSale'];

    function StockTransferGoodsDescriptionDetailController($scope, $rootScope, $stateParams, entity, StockTransferGoodsDescription, PointOfSale) {
        var vm = this;
        vm.stockTransferGoodsDescription = entity;
        
        var unsubscribe = $rootScope.$on('wamaApp:stockTransferGoodsDescriptionUpdate', function(event, result) {
            vm.stockTransferGoodsDescription = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
