(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('PurchaseOrderDetailController', PurchaseOrderDetailController);

    PurchaseOrderDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'PurchaseOrder', 'Supplier', 'PointOfSale', 'PurchaseOrderStatus', 'PurchaseOrderEntry', 'PurchaseOrderInvoice', '$http', '$filter', '$uibModalStack'];

    function PurchaseOrderDetailController($scope, $rootScope, $stateParams, entity, PurchaseOrder, Supplier, PointOfSale, PurchaseOrderStatus, PurchaseOrderEntry, PurchaseOrderInvoice, $http, $filter, $uibModalStack) {
        var vm = this;
        vm.purchaseOrder = entity;

        var unsubscribe = $rootScope.$on('wamaApp:purchaseOrderExport', function(event, result) {
            vm.purchaseOrder = result;
        });
        $scope.$on('$destroy', unsubscribe);

        vm.calculationHelpers = calculationHelpers;
        vm.currencyHelpers = currencyHelpers;
        vm.taxHelpers = taxHelpers;
        vm.purchaseOrderStatusColor = purchaseOrderStatusColor;
        vm.downloadPurchaseOrderPDF = downloadPurchaseOrderPDF;

        function downloadPurchaseOrderPDF () {
            $http.get('/api/purchase-orders/'+ $stateParams.id +'/invoice/pdf?', {responseType: 'arraybuffer'})
                .success(function (data) {
                    var blob = new Blob([data], {
                        type: 'application/pdf'
                    });
                    var currentDate = $filter('date')(new Date(), "yyyy-MM-dd_HH:mm:ss");
                    var filename = "WAMAPurchaseOrder_" + $stateParams.id + "-"  + currentDate + ".pdf";
                    saveAs(blob, filename);
                    vm.isSaving = false;
                }).error(function () {
                vm.isSaving = false;
            });
        }
    }
})();
