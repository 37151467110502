/**
 * Created by roberto on 20/06/17.
 */
(function() {
    'use strict';
    angular
        .module('wamaApp')
        .factory('PurchaseOrderStatusUpdate', PurchaseOrderStatusUpdate);

    PurchaseOrderStatusUpdate.$inject = ['$resource', 'DateUtils'];

    function PurchaseOrderStatusUpdate ($resource) {
        var resourceUrl =  'api/purchase-orders/:id/status';

        return $resource(resourceUrl, {}, {
            'update': { method:'PUT' }
        });
    }
})();
