(function() {
    'use strict';

    angular
        .module('wamaApp')
        .factory('notificationInterceptor', notificationInterceptor);

    notificationInterceptor.$inject = ['$q', 'AlertService', '$injector'];

    function notificationInterceptor ($q, AlertService, $injector) {
        var service = {
            response: response
        };

        return service;

        function response (response) {
            var ToastAlertService= $injector.get('ToastAlertService');
            var alertKey = response.headers('X-wamaApp-alert');
            if (angular.isString(alertKey)) {
                ToastAlertService.show({type: ToastAlertService.success, msg: alertKey, params: response.headers('X-wamaApp-params')});
            }
            return response;
        }
    }
})();
