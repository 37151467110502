(function () {
    'use strict';

    angular
        .module('wamaApp')
        .controller('ModulePublicShopController', ModulePublicShopController);

    ModulePublicShopController.$inject = ['$scope', '$state', 'ToastAlertService', 'PointOfSale', 'Auth', '$translate', 'Preferences', '$rootScope', 'PointOfSaleAuthTenant',
        'StripeConnectAccount', '$window'];

    function ModulePublicShopController($scope, $state, ToastAlertService, PointOfSale, Auth, $translate, Preferences, $rootScope, PointOfSaleAuthTenant, StripeConnectAccount,
                                        $window) {
        var vm = this;
        vm.loadAll = loadAll;
        vm.save = save;
        vm.loadAll('MODULE_PUBLIC_SHOP');

        /******* Add here another property to save it automatically *******/
        vm.publicShopShowQuantity = {};
        vm.publicShopShowCode = {};
        vm.publicShopShowSellingPrice = {};
        vm.publicShopShowBarcode = {};
        vm.publicShopShowTopBar = {};
        vm.publicShopShowCompanyLogo = {};
        vm.publicShopTopBarTitle = {};
        vm.publicShopTopBarContactInfo = {};
        vm.publicShopBackgroundColor = {};
        vm.publicShopTitleColor = {};
        vm.publicShopEnableStripePayments = {};
        var properties = {
            'publicShopShowQuantity': vm.publicShopShowQuantity,
            'publicShopShowCode': vm.publicShopShowCode,
            'publicShopShowSellingPrice': vm.publicShopShowSellingPrice,
            'publicShopShowBarcode': vm.publicShopShowBarcode,
            'publicShopShowTopBar': vm.publicShopShowTopBar,
            'publicShopShowCompanyLogo': vm.publicShopShowCompanyLogo,
            'publicShopTopBarTitle': vm.publicShopTopBarTitle,
            'publicShopTopBarContactInfo': vm.publicShopTopBarContactInfo,
            'publicShopBackgroundColor': vm.publicShopBackgroundColor,
            'publicShopTitleColor': vm.publicShopTitleColor,
            'publicShopEnableStripePayments': vm.publicShopEnableStripePayments
        };
        var defaultPropertiesValue = {
            'publicShopShowQuantity': {'propertyType': 'BOOLEAN', 'section': 'MODULE_PUBLIC_SHOP', 'nameOnPreferences': 'publicShopShowQuantity'},
            'publicShopShowCode': {'propertyType': 'BOOLEAN', 'section': 'MODULE_PUBLIC_SHOP', 'nameOnPreferences': 'publicShopShowCode'},
            'publicShopShowSellingPrice': {'propertyType': 'BOOLEAN', 'section': 'MODULE_PUBLIC_SHOP', 'nameOnPreferences': 'publicShopShowSellingPrice'},
            'publicShopShowBarcode': {'propertyType': 'BOOLEAN', 'section': 'MODULE_PUBLIC_SHOP', 'nameOnPreferences': 'publicShopShowBarcode'},
            'publicShopShowTopBar': {'propertyType': 'BOOLEAN', 'section': 'MODULE_PUBLIC_SHOP', 'nameOnPreferences': 'publicShopShowTopBar'},
            'publicShopShowCompanyLogo': {'propertyType': 'BOOLEAN', 'section': 'MODULE_PUBLIC_SHOP', 'nameOnPreferences': 'publicShopShowCompanyLogo'},
            'publicShopTopBarTitle': {'propertyType': 'STRING', 'section': 'MODULE_PUBLIC_SHOP', 'nameOnPreferences': 'publicShopTopBarTitle'},
            'publicShopTopBarContactInfo': {'propertyType': 'STRING', 'section': 'MODULE_PUBLIC_SHOP', 'nameOnPreferences': 'publicShopTopBarContactInfo'},
            'publicShopBackgroundColor': {'propertyType': 'STRING', 'section': 'MODULE_PUBLIC_SHOP', 'nameOnPreferences': 'publicShopBackgroundColor'},
            'publicShopTitleColor': {'propertyType': 'STRING', 'section': 'MODULE_PUBLIC_SHOP', 'nameOnPreferences': 'publicShopTitleColor'},
            'publicShopEnableStripePayments': {'propertyType': 'BOOLEAN', 'section': 'MODULE_PUBLIC_SHOP', 'nameOnPreferences': 'publicShopEnableStripePayments'}
        };
        angular.forEach(properties, function (value, key) {
            value.property = key;
            value.propertyType = defaultPropertiesValue[key]['propertyType'];
            value.section = defaultPropertiesValue[key]['section'];
            value.nameOnPreferences = defaultPropertiesValue[key]['nameOnPreferences'];
        });

        /******************* END ******************/

        function loadAll(section) {

            Preferences.mapQuery({id: section}, onSuccess, onError);

            function onSuccess(data) {
                angular.forEach(data, function (value, key) {
                    if (properties[key]) {
                        //found property
                        properties[key].id = value.id;
                        properties[key].property = (value.property) ? value.property : key;
                        properties[key].section = (value.section) ? value.section : defaultPropertiesValue[key]['section'];
                        properties[key].propertyType = (value.propertyType) ? value.propertyType : defaultPropertiesValue[key]['propertyType'];
                        if (value.value != null && (value.value.toUpperCase() === "TRUE" ||
                            value.value.toUpperCase() === "FALSE")) {
                            properties[key].value = value.value.toUpperCase() === "TRUE";
                        } else {
                            properties[key].value = value.value;
                        }
                    }
                });
            }

            function onError(error) {
                ToastAlertService.error(error.data.message);
            }
        }

        function save(preference) {
            Preferences.update(preference,
                function (data) {
                    properties[data.property].id = data.id;
                },
                function () {
                    ToastAlertService.show({
                        type: ToastAlertService.error,
                        msg: 'wamaApp.preferences.modules.' + preference.nameOnPreferences + '.errorOnSave'
                    });
                });
        }

        vm.isCustomerRegistrationEnabled = false;
        vm.isChangedPointOfSale = false;
        PointOfSale.get({id: -1}, function (pointOfSale) {
            vm.pointOfSale = pointOfSale;
            vm.changePublicLink(pointOfSale);
            vm.isChangedPointOfSale = false;
            vm.isCustomerRegistrationEnabled = vm.pointOfSale != null && vm.pointOfSale.authTenantId != null
        }, function () {
            vm.publicLink = "Error";
        });

        vm.changePublicLink = function (pointOfSale) {
            vm.isChangedPointOfSale = true;
            vm.publicLink = "https://web.wama.cloud/#/shop/" + (pointOfSale.code ? pointOfSale.code : pointOfSale.id);
            vm.publicShopLink = "https://" + (pointOfSale.code ? pointOfSale.code : pointOfSale.id) + ".wama.app";
        };

        vm.isCustomerRegistrationEnabled = false;
        vm.registerAuthTenantId = function () {
            PointOfSaleAuthTenant.save(null, function (result) {
                vm.pointOfSale = result;
                vm.isCustomerRegistrationEnabled = vm.pointOfSale != null && vm.pointOfSale.authTenantId != null
            }, function (error){
                vm.isCustomerRegistrationEnabled = false;
                ToastAlertService.error({msg: $translate.instant(error.data.message)});
            });
        }

        vm.savePointOfSale = function () {
            PointOfSale.update(vm.pointOfSale, function () {
                vm.isChangedPointOfSale = false;
            }, function (error) {
                if ("error.dataIntegrityViolation" === error.data.message) {
                    ToastAlertService.error({msg: $translate.instant('wamaApp.pointOfSale.error.code.duplicated')});
                } else {
                    ToastAlertService.error({msg: $translate.instant('wamaApp.pointOfSale.error.generic')});
                }
            });
        };

        vm.openUrl = function (url) {
            window.open(url, '_blank');
        };

        var unsubscribeBackgroundColor = $rootScope.$on('wamaApp:publicShopBackgroundColorPick', function (event, result) {
            vm.publicShopBackgroundColor.value = result;
            save(vm.publicShopBackgroundColor);
        });
        $scope.$on('$destroy', unsubscribeBackgroundColor);


        var unsubscribeTitleColor = $rootScope.$on('wamaApp:publicShopTitleColorPick', function (event, result) {
            vm.publicShopTitleColor.value = result;
            save(vm.publicShopTitleColor);
        });
        $scope.$on('$destroy', unsubscribeTitleColor);

        vm.stripeConnectAccount = {};

        var onSaveSuccess = function (result) {
            vm.isSaving = false;
            $window.location.href =result.accountLinkUrl;
        };

        var onSaveError = function (error) {
            vm.isSaving = false;
            ToastAlertService.error(error.data.message);
        };
        vm.stripeConnectAccount = StripeConnectAccount.get();

        vm.createStripeConnectAccount = function () {
            vm.isSaving = true;
            StripeConnectAccount.save(vm.stripeConnectAccount, onSaveSuccess, onSaveError);
        }

        vm.hasAccessRights = Auth.hasAccessRights;


    }
})();
