(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('WamaErrorDialogController', WamaErrorDialogController);

    WamaErrorDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'WamaError'];

    function WamaErrorDialogController ($timeout, $scope, $stateParams, $uibModalInstance, DataUtils, entity, WamaError) {
        var vm = this;
        vm.wamaError = entity;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('wamaApp:wamaErrorUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.wamaError.id !== null) {
                WamaError.update(vm.wamaError, onSaveSuccess, onSaveError);
            } else {
                WamaError.save(vm.wamaError, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.sentDate = false;
        vm.datePickerOpenStatus.receivedDate = false;

        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
    }
})();
