(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('StockTransferGoodsDescriptionDialogController', StockTransferGoodsDescriptionDialogController);

    StockTransferGoodsDescriptionDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'StockTransferGoodsDescription', 'PointOfSale'];

    function StockTransferGoodsDescriptionDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, StockTransferGoodsDescription, PointOfSale) {
        var vm = this;
        vm.stockTransferGoodsDescription = entity;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('wamaApp:stockTransferGoodsDescriptionUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.stockTransferGoodsDescription.id !== null) {
                StockTransferGoodsDescription.update(vm.stockTransferGoodsDescription, onSaveSuccess, onSaveError);
            } else {
                StockTransferGoodsDescription.save(vm.stockTransferGoodsDescription, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
