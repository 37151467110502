(function() {
    'use strict';
    angular
        .module('wamaApp')
        .factory('StockEntry', StockEntry);

    StockEntry.$inject = ['$resource', 'DateUtils'];

    function StockEntry ($resource, DateUtils) {
        var resourceUrl =  'api/stock-entries/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.timeCreated = DateUtils.convertDateTimeFromServer(data.timeCreated);
                    data.timeModified = DateUtils.convertDateTimeFromServer(data.timeModified);
                    data.bestBeforeDate = DateUtils.convertDateTimeFromServer(data.bestBeforeDate);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
