(function() {
    'use strict';
    angular
        .module('wamaApp')
        .factory('PurchaseOrder', PurchaseOrder);

    PurchaseOrder.$inject = ['$resource', 'DateUtils'];

    function PurchaseOrder ($resource, DateUtils) {
        var resourceUrl =  'api/purchase-orders/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.timeCreated = DateUtils.convertDateTimeFromServer(data.timeCreated);
                    data.timeModified = DateUtils.convertDateTimeFromServer(data.timeModified);
                    data.timeExpectedDelivery = DateUtils.convertDateTimeFromServer(data.timeExpectedDelivery);
                    data.timeRequestedDelivery = DateUtils.convertDateTimeFromServer(data.timeRequestedDelivery);
                    data.timeClosed = DateUtils.convertDateTimeFromServer(data.timeClosed);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();

var calculationHelpers = {
    'subtotal': function(entries) {
        var subtotal = 0;
        var taxIncluded;
        angular.forEach(entries, function(entry, key){
            if(entry.price != null){
                if(taxIncluded == null){
                    taxIncluded = entry.price.taxIncluded;
                }
                if(taxIncluded && entry.price.tax != null){
                    subtotal += entry.price.price * entry.quantity / (1 + (entry.price.tax.value / 100));
                } else {
                    subtotal += entry.price.price * entry.quantity;
                }
            }
        });
        return subtotal;
    },
    'tax': function (entries) {
        var netPrice = 0;
        var grossPrice = 0;
        var taxIncluded;
        angular.forEach(entries, function(entry, key){
            if(entry.price != null && entry.price.tax != null){
                if(taxIncluded == null){
                    taxIncluded = entry.price.taxIncluded;
                }
                if(taxIncluded){
                    netPrice += entry.price.price * entry.quantity / (1 + (entry.price.tax.value / 100));
                    grossPrice += entry.price.price * entry.quantity;
                } else {
                    netPrice += entry.price.price * entry.quantity;
                    grossPrice += entry.price.price * entry.quantity * (1 + (entry.price.tax.value / 100));
                }
            }
        });
        return grossPrice - netPrice;
    },
    'total': function (entries) {
        var total = 0;
        var taxIncluded;
        angular.forEach(entries, function(entry, key){
            if(entry.price != null){
                if(taxIncluded == null){
                    taxIncluded = entry.price.taxIncluded;
                }
                if(!taxIncluded  && entry.price.tax != null){
                    total += entry.price.price * entry.quantity * (1 + (entry.price.tax.value / 100));
                } else {
                    total += entry.price.price * entry.quantity;
                }
            }
        });
        return total;
    }
};

var purchaseOrderStatusColor = function (statusCode) {
    switch(statusCode){
        case 'NEW':
            return 'label-info';
            break;
        case 'RELEASED':
            return 'label-primary';
            break;
        case 'RECEIVED':
            return 'label-success';
            break;
        case 'CHANGE_ORDER':
            return 'label-warning';
            break;
        case 'CANCELLED':
            return 'label-danger';
            break;
        case 'CLOSED':
            return 'label-default';
            break;
        default:
            return 'label-default';
    }
};
