(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('SaleOrderDeleteController',SaleOrderDeleteController);

    SaleOrderDeleteController.$inject = ['$uibModalInstance', 'entity', 'SaleOrder'];

    function SaleOrderDeleteController($uibModalInstance, entity, SaleOrder) {
        var vm = this;
        vm.saleOrder = entity;
        vm.isDeleting = false;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            vm.isDeleting = true;
            SaleOrder.delete({id: id},
                function () {
                    vm.isDeleting = false;
                    $uibModalInstance.close(true);
                }, function () {
                    vm.isDeleting = false;
                });
        };
    }
})();
