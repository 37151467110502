(function() {
    'use strict';

    angular
        .module('wamaApp').directive('copyToClipboard', ['ToastAlertService', function (ToastAlertService) {
        return {
            restrict: 'A',
            link: function (scope, elem, attrs) {
                elem.click(function () {
                    if (attrs.copyToClipboard) {
                        var $temp_input = $("<input>");
                        $("body").append($temp_input);
                        $temp_input.val(attrs.copyToClipboard).select();
                        document.execCommand("copy");
                        $temp_input.remove();
                        ToastAlertService.show({type: ToastAlertService.success, msg: 'global.copiedToClipboard'});
                    }
                });
            }
        };
    }]);
})();
