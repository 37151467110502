(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('StockEntryReasonDeleteController',StockEntryReasonDeleteController);

    StockEntryReasonDeleteController.$inject = ['$uibModalInstance', 'entity', 'StockEntryReason'];

    function StockEntryReasonDeleteController($uibModalInstance, entity, StockEntryReason) {
        var vm = this;
        vm.stockEntryReason = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            StockEntryReason.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
