(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('CountryDialogController', CountryDialogController);

    CountryDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Country', 'Currency', 'TaxInfo', 'Lang'];

    function CountryDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Country, Currency, TaxInfo, Lang) {
        var vm = this;
        vm.country = entity;
        vm.currencies = Currency.query();
        vm.taxinfos = TaxInfo.query();
        vm.langs = Lang.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('wamaApp:countryUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.country.id !== null) {
                Country.update(vm.country, onSaveSuccess, onSaveError);
            } else {
                Country.save(vm.country, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
