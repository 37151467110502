(function () {
    'use strict';

    angular
        .module('wamaApp')
        .factory('Employee', Employee);

    Employee.$inject = ['$resource'];

    function Employee ($resource) {
        return $resource('api/employees/:id', {}, {});
    }
})();
