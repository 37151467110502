(function () {
    'use strict';

    angular
        .module('wamaApp')
        .controller('IntegrationShopifyController', IntegrationShopifyController);

    IntegrationShopifyController.$inject = ['$scope', '$state', 'ToastAlertService', 'Auth', '$stateParams', 'ShopifyShopActivate', 'PointOfSale', 'ShopifyShop',
        'ShopifyLocation', 'ShopifyProductSync', 'ShopifyStockSync'];

    function IntegrationShopifyController($scope, $state, ToastAlertService, Auth, $stateParams, ShopifyShopActivate, PointOfSale, ShopifyShop, ShopifyLocation,
                                          ShopifyProductSync, ShopifyStockSync) {
        var vm = this;
        vm.isSaving = false;
        vm.isLoading = false;
        vm.reinstallApp = false;

        vm.authorizationCode = $stateParams.authorizationCode;
        vm.hasAccessRights = Auth.hasAccessRights;

        function onLoadSuccess(result) {
            vm.isSaving = false;
            vm.isLoading = false;
        }

        function onSaveError() {
            vm.isSaving = false;
            vm.isLoading = false;
            vm.getShops();
        }

        if ($stateParams.authorizationCode === "null") {
            vm.reinstallApp = true;
        } else if ($stateParams.shopifyShop) {
            ShopifyShopActivate.get({shop: $stateParams.shopifyShop, authorizationCode: $stateParams.authorizationCode}, onGetShopToActivate, onSaveError);
        }

        function onGetShopToActivate(shopifyShop) {
            vm.shopifyShop = shopifyShop;
            vm.activate()
        }

        vm.activate = function () {
            if (vm.shopifyShop.companyId == null) {
                vm.isSaving = true;
                vm.isShowingShopSettings = true;
                ShopifyShopActivate.update(vm.shopifyShop, onSaveSuccess, onSaveError);
            }
        }

        var onSaveSuccess = function (result) {
            vm.isSaving = false;
            vm.isLoading = false;
            vm.getShops();
        };

        vm.getShops = function () {
            vm.shopifyAccounts = ShopifyShop.query();
        }

        vm.getLocations = function (shop) {
            vm.isLoading = true;
            vm.locations = ShopifyLocation.query({shop: shop}, onLoadSuccess, onSaveError);
        }

        vm.getShopifyLocationName = function (locationId) {
            if (!vm.locations) {

                ShopifyLocation.query({shop: shop}, function (locations) {
                    return vm.getSelectedLocation(locations, locationId)
                }, onSaveError);
            } else {
                return vm.getSelectedLocation(vm.locations, locationId)
            }
        }

        vm.getSelectedLocation = function (locations, locationId) {
            var locationName = "";

            angular.forEach(locations, function (location) {
                if (location.id === locationId) {
                    locationName = location.name;
                }
            });
            return locationName;
        }

        vm.syncProductsStock = function (shop, pointOfSaleId) {
            ShopifyStockSync.save({shop: shop, pointOfSaleId: pointOfSaleId});
        }
        vm.copyProducts = function (shop, pointOfSaleId) {
            ShopifyProductSync.save({shop: shop, pointOfSaleId: pointOfSaleId});
        }

        function onLoadPointOfSales(pointOfSales) {
            //need to map the shopifyLocationId to an object in order to preselect the one saved in the pos
            angular.forEach(pointOfSales, function (pointOfSale) {
                pointOfSale.shopifyLocationId = {id: pointOfSale.shopifyLocationId};
            });
            vm.pointOfSales = pointOfSales;
        }

        vm.isShowingShopSettings = false;
        vm.showShopSettings = function (shop) {
            vm.isShowingShopSettings = !vm.isShowingShopSettings
            if (vm.isShowingShopSettings && vm.locations == null) {
                vm.getLocations(shop)
                PointOfSale.query(null, onLoadPointOfSales);
            }
        }

        vm.savePointOfSale = function (pointOfSale) {
            pointOfSale.shopifyLocationId = pointOfSale.shopifyLocationId != null ? pointOfSale.shopifyLocationId.id : null;
            PointOfSale.update(pointOfSale, function () {
                PointOfSale.query(null, onLoadPointOfSales);
            });
        }

        vm.getShops();

    }
})();
