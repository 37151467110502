(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('StockTransferMethodDeleteController',StockTransferMethodDeleteController);

    StockTransferMethodDeleteController.$inject = ['$uibModalInstance', 'entity', 'StockTransferMethod'];

    function StockTransferMethodDeleteController($uibModalInstance, entity, StockTransferMethod) {
        var vm = this;
        vm.stockTransferMethod = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            StockTransferMethod.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
