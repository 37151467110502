(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('UserManagementDeleteController', UserManagementDeleteController);

    UserManagementDeleteController.$inject = ['$uibModalInstance', '$rootScope', 'entity', 'UserAdmin', 'Auth'];

    function UserManagementDeleteController ($uibModalInstance, $rootScope, entity, UserAdmin, Auth) {
        var vm = this;

        vm.user = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            UserAdmin.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }

        vm.preConfirm = preConfirm;
        vm.disable = disable;

        vm.preconfirm=false;
        function preConfirm (){
            vm.preconfirm=true;
        }

        function disable (user) {
            user.activated = false;
            User.update(user, function (result) {
                if(!result.activated){
                    $uibModalInstance.close(true);
                    Auth.logout();
                    $rootScope.USERNAME = "";
                    $state.go('home');
                }
            });
        };
    }
})();
