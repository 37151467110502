(function() {
    'use strict';
    angular
        .module('wamaApp')
        .factory('ShopifyProductSync', ShopifyProductSync);

    ShopifyProductSync.$inject = ['$resource'];

    function ShopifyProductSync ($resource) {
        var resourceUrl =  'api/shopify/sync/product';

        return $resource(resourceUrl, {}, {
            'update': { method:'PUT' }
        });
    }
})();
