(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('WamaStatsController', WamaStatsController);

    WamaStatsController.$inject = ['$scope','WamaStatsService'];

    function WamaStatsController ($scope, WamaStatsService) {
        var vm = this;
        vm.loadAll = loadAll;
        vm.todayDate = new Date();
        loadAll();

        function loadAll(){
            WamaStatsService.head(onSuccess, onError);
        };

        function onSuccess(data, headers) {
            vm.totalProducts = headers('X-Total-Products');
            vm.totalStockEntries = headers('X-Total-StockEntries');
            vm.totalCategories = headers('X-Total-Categories');
            vm.totalUsers = headers('X-Total-Users');
            vm.totalLocations = headers('X-Total-Locations');
            vm.totalSuppliers = headers('X-Total-Suppliers');
            vm.totalPrices = headers('X-Total-Prices');
            vm.totalCustomers = headers('X-Total-Customers');
            vm.totalPurchaseOrders = headers('X-Total-PurchaseOrders');
            vm.totalSalesOrders = headers('X-Total-SalesOrders');
            vm.totalStockTransfers = headers('X-Total-StockTransfers');
        }

        function onError() {
            alert("Error, cannot get the stats info.");
        }



    }
})();
