(function() {
    'use strict';
    angular
        .module('wamaApp')
        .factory('Export', Export);

    Export.$inject = ['$http', 'DateUtils', '$filter'];

    function Export ($http, DateUtils, $filter) {

        return  {
            'exportData': exportData,
            'exportAllData': exportAllData,
            'exportProductCatalog': exportProductCatalog
        };


        function exportData() {
            var blob = new Blob([document.getElementById('exportable').innerHTML], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
            });
            saveAs(blob, "Export-selected-data.xls");
        };


        function exportAllData(vm, startDate, endDate){
            vm.exportingData = true;
            vm.isSaving = true;
            startDate.setUTCHours(0,0,0,0);
            endDate.setUTCHours(23,59,59,59);
            $http.get('/api/products/export', {params: {startDate: startDate, endDate: endDate}, responseType: 'arraybuffer'})
                .success(function (data) {
                    vm.exportingData = false;
                    vm.isSaving = false;
                    var blob = new Blob([data], {
                        type: '"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"'
                    });
                    var currentDate = $filter('date')(new Date(), "yyyy-MM-dd_HH:mm:ss");
                    var filename = "Export_" + currentDate + ".xlsx";
                    saveAs(blob, filename);
                })
                .error(function () {
                    vm.exportingData = false;
                    vm.isSaving = false;
                });
        }

        function exportProductCatalog(){
            printPdf('productCatalogExportContainer');
        }


        function printPdf(id) {
            var printContents = document.getElementById(id);
            var popupWin = window.open();
            popupWin.document.open();
            popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" /></head><body onload="window.print()">'+printContents.innerHTML+'</html>');
            popupWin.document.close();
        };

    };
})();
