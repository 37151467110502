(function() {
    'use strict';
    angular
        .module('wamaApp')
        .factory('SetupIntent', SetupIntent);

    SetupIntent.$inject = ['$resource'];

    function SetupIntent ($resource) {
        var resourceUrl =  'api/stripe/setupIntent/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
