(function() {
    'use strict';
    angular
        .module('wamaApp')
        .factory('ProductBarcode', ProductBarcode);

    ProductBarcode.$inject = ['$resource', 'DateUtils'];

    function ProductBarcode ($resource, DateUtils) {
        var resourceUrl =  'api/products/infoLookup/:searchTerm';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                params:{images:'@images'},
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.timeCreated = DateUtils.convertDateTimeFromServer(data.timeCreated);
                    data.timeModified = DateUtils.convertDateTimeFromServer(data.timeModified);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'head': {method:'HEAD'}
        });
    };
})();

