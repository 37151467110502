(function() {
    'use strict';

    angular
        .module('wamaApp')
        .factory('StockTransferEntryStatusSearch', StockTransferEntryStatusSearch);

    StockTransferEntryStatusSearch.$inject = ['$resource'];

    function StockTransferEntryStatusSearch($resource) {
        var resourceUrl =  'api/_search/stock-transfer-entry-statuses/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
