(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('SaleOrderController', SaleOrderController);

    SaleOrderController.$inject = ['$scope', '$state', 'SaleOrder', 'SaleOrderSearch', 'ParseLinks', 'ToastAlertService', 'pagingParams', 'paginationConstants', '$localStorage'];

    function SaleOrderController ($scope, $state, SaleOrder, SaleOrderSearch, ParseLinks, ToastAlertService, pagingParams, paginationConstants, $localStorage) {
        var vm = this;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.clear = clear;
        vm.search = search;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.loadAll();


        vm.filter = {
            status: {
                pending: false,
                cancelled: false,
                completed: false,
                draft: false
            }
        };

        vm.initFilters = function () {
            vm.filter = $localStorage.filter;
        };
        vm.initFilters();

        vm.applyFilters = function () {
            $localStorage.filterLowStock = vm.filter;
            loadAll();
        };

        vm.refresh = function (){
            loadAll();
            ToastAlertService.show({type: ToastAlertService.success, msg: 'global.dataRefreshed'})
        }

        function getStatusesAsString(filter){
            var result = [];
            if(filter == null || filter.status == null){
                return result;
            }
            if(filter.status.pending){
                result.push("PENDING");
            }

            if(filter.status.completed){
                result.push("COMPLETED");
            }
            if(filter.status.cancelled){
                result.push("CANCELLED");
            }
            if(filter.status.draft){
                result.push("DRAFT");
            }
            return result.join(",");
        }


        function loadAll () {
            if (pagingParams.search) {
                SaleOrderSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: paginationConstants.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                SaleOrder.query({
                    page: pagingParams.page - 1,
                    size: paginationConstants.itemsPerPage,
                    sort: sort(),
                    statuses: $localStorage.filter != null ? getStatusesAsString($localStorage.filter) : null
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.saleOrders = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                ToastAlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        vm.currencyHelpers = currencyHelpers;
        vm.taxHelpers = taxHelpers;
        vm.salesOrderStatusColor = salesOrderStatusColor;
        vm.salesOrderPaymentStatusColor = salesOrderPaymentStatusColor;

    }
})();
