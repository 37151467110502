(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('SubscriptionInfoDeleteController',SubscriptionInfoDeleteController);

    SubscriptionInfoDeleteController.$inject = ['$uibModalInstance', 'StripeCard', 'id'];

    function SubscriptionInfoDeleteController($uibModalInstance, StripeCard, id) {
        var vm = this;
        vm.id = id;
        vm.confirmDeleteCard = confirmDeleteCard;

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        function confirmDeleteCard(id){
            StripeCard.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }

    }
})();
