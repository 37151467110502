(function() {
    'use strict';

    angular
        .module('wamaApp').directive('nospaces', function() {
        return {
            restrict: 'A',

                link: function($scope, $element) {
                $element.bind('input', function() {
                    $(this).val($(this).val().replace(/ /g, ''));
                });
            }
        };
    });
})();
