(function() {
    'use strict';

    angular
        .module('wamaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('shop', {
            parent: 'app',
            // abstract: true,
            url: '/shop/{pointOfSaleId}',
            data: {
                pageTitle: 'wamaApp.product.home.title',
                pageType: 'shop'
            },
            views: {
                'contentPublic@': {
                    templateUrl: 'app/custom/shop/shop.html',
                    controller: 'ShopController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'name,asc',
                    squash: true
                },
                search: null,
                category: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search,
                        category: $stateParams.category
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('product');
                    $translatePartialLoader.addPart('price');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('category');
                    return $translate.refresh();
                }]
            }
        })
        .state('shop-product-detail', {
            parent: 'app',
            url: '/shop/{pointOfSaleId}/product/{productId}',
            data: {
                pageTitle: 'wamaApp.product.detail.title',
                pageType: 'shop'
            },
            views: {
                'contentPublic@': {
                    templateUrl: 'app/custom/shop/shop-product-detail.html',
                    controller: 'ShopProductController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('product');
                    $translatePartialLoader.addPart('price');
                    $translatePartialLoader.addPart('category');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'ShopProduct', function($stateParams, ShopProduct) {
                    console.log("Product: ", $stateParams.product);
                    return ShopProduct.get({productId : $stateParams.productId, pointOfSaleId:  $stateParams.pointOfSaleId, formatPhoto: 'full', stockEntryInfo: false}).$promise;
                    // return $stateParams.product
                }],
                previousState: ["$state", function ($state) {
                    return {
                        name: $state.current.name || 'product',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                }]
            }
        });
    }
})();
