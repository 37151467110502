(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('PurchaseOrderEntryDialogController', PurchaseOrderEntryDialogController);

    PurchaseOrderEntryDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'PurchaseOrderEntry', 'Price', 'PurchaseOrder', 'Product'];

    function PurchaseOrderEntryDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, PurchaseOrderEntry, Price, PurchaseOrder, Product) {
        var vm = this;
        vm.purchaseOrderEntry = entity;
        vm.prices = Price.query();
        vm.purchaseorders = PurchaseOrder.query();
        vm.products = Product.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('wamaApp:purchaseOrderEntryUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.purchaseOrderEntry.id !== null) {
                PurchaseOrderEntry.update(vm.purchaseOrderEntry, onSaveSuccess, onSaveError);
            } else {
                PurchaseOrderEntry.save(vm.purchaseOrderEntry, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.timeExpected = false;
        vm.datePickerOpenStatus.timeCreated = false;
        vm.datePickerOpenStatus.timeModified = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
    }
})();
