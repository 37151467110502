(function() {
    'use strict';

    angular
        .module('wamaApp')
        .controller('SalesOrderController', SalesOrderController);

    SalesOrderController.$inject = ['$scope', '$state', 'ToastAlertService', 'PointOfSale', 'Auth', '$translate', 'Preferences'];

    function SalesOrderController ($scope, $state, ToastAlertService, PointOfSale, Auth, $translate, Preferences) {
        var vm = this;
        vm.loadAll = loadAll;
        vm.save = save;
        vm.loadAll();
        vm.pointofsales = PointOfSale.query({page: 0, size: 99999, sort: "name"});

        /******* Add here another property to save it automatically *******/
        vm.productCodePreference = {};
        vm.productDescriptionPreference = {};
        vm.customerInfoPreference = {};
        vm.invoiceTitlePreference = {};
        vm.companyInfoPreference = {};
        var properties = {
            'INVOICE_BOOL_PRODUCT_CODE': vm.productCodePreference,
            'INVOICE_BOOL_PRODUCT_DESCRIPTION': vm.productDescriptionPreference,
            'INVOICE_BOOL_CUSTOMER_INFORMATION': vm.customerInfoPreference,
            'INVOICE_STRING_TITLE': vm.invoiceTitlePreference,
            'INVOICE_STRING_COMPANY_INFORMATION': vm.companyInfoPreference
        };
        var defaultPropertiesValue = {
            'INVOICE_BOOL_PRODUCT_CODE': {'propertyType': 'BOOLEAN', 'section':'INVOICE_SALE_ORDER', 'nameOnPreferences': 'showCode' },
            'INVOICE_BOOL_PRODUCT_DESCRIPTION': {'propertyType': 'BOOLEAN', 'section':'INVOICE_SALE_ORDER', 'nameOnPreferences': 'showDescription'},
            'INVOICE_BOOL_CUSTOMER_INFORMATION': {'propertyType': 'BOOLEAN', 'section':'INVOICE_SALE_ORDER', 'nameOnPreferences': 'showCustomerInfo'},
            'INVOICE_STRING_TITLE': {'propertyType': 'STRING', 'section':'INVOICE_SALE_ORDER', 'nameOnPreferences': 'title'},
            'INVOICE_STRING_COMPANY_INFORMATION': {'propertyType': 'STRING', 'section':'INVOICE_SALE_ORDER', 'nameOnPreferences': 'companyInfo'}
        };
        angular.forEach(properties, function(value,key){
            value.property = key;
            value.propertyType = defaultPropertiesValue[key]['propertyType'];
            value.section = defaultPropertiesValue[key]['section'];
            value.nameOnPreferences = defaultPropertiesValue[key]['nameOnPreferences'];
        });

        /******************* END ******************/
        function loadAll () {

            Preferences.mapQuery({sections: 'INVOICE_SALE_ORDER'}, onSuccess, onError);

            function onSuccess(data, headers) {
                angular.forEach(data, function (value, key) {
                    if(properties[key]){
                        //found property
                        properties[key].id = value.id;
                        properties[key].property = (value.property) ? value.property : key;
                        properties[key].section = (value.section) ? value.section : defaultPropertiesValue[key]['section'];
                        properties[key].propertyType = (value.propertyType) ? value.propertyType : defaultPropertiesValue[key]['propertyType'];
                        if (value.value != null && (value.value.toUpperCase() === "TRUE" ||
                            value.value.toUpperCase() === "FALSE")) {
                            properties[key].value = value.value.toUpperCase() === "TRUE";
                        }else {
                            properties[key].value = value.value;
                        }
                    }
                });
            }
            function onError(error) {
                ToastAlertService.error(error.data.message);
            }
        }

        function save() {
            angular.forEach(Object.values(properties), function (prop) {
                Preferences.update(prop,
                    function(data, header) {
                        properties[data.property].id = data.id;
                    },
                    function(error) {
                        ToastAlertService.show({type: ToastAlertService.error, msg: 'wamaApp.preferences.invoice.' + prop.nameOnPreferences + '.errorOnSave'});
                    });
            });
        }

        vm.hasAccessRights = Auth.hasAccessRights;


    }
})();
